import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";

import { formatPriceIntoCents } from "utils";
import { TierType } from "utils/types";

interface PriceTableProps {
  tiers?: TierType[];
  isLoading?: boolean;
  showDescription: boolean;
}

const rateLabelFromTier = (tier: TierType): string => {
  // By default, no price
  let price = "";
  let priceDescription = "free";

  // If the flat price is present, then show it
  if (tier.flatPrice > 0) {
    price = formatPriceIntoCents(tier.flatPrice);
    priceDescription = "flat";
  }

  // If the unit price is present, show that instead
  if (tier.unitPrice > 0) {
    price = formatPriceIntoCents(tier.unitPrice);
    priceDescription = "per";
  }

  return `${price} ${priceDescription}`;
};

const PriceTableRemodelAI = ({
  tiers = [],
  isLoading,
  showDescription,
}: PriceTableProps) => {
  return (
    <Card borderRequired className="h-full">
      <div className="w-full overflow-x-auto ring-1 ring-black ring-opacity-5 sm:rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3 px-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
              >
                Monthly Active Users (MAU)
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
              >
                Rate
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {isLoading && (
              <tr>
                <td colSpan={100} className="text-center py-8">
                  <Spinner className="!w-[3rem]" />
                </td>
              </tr>
            )}
            {!isLoading &&
              tiers.length > 0 &&
              tiers?.map((tier, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                    {index > 0 ? tiers[index - 1].cap.toLocaleString() : 1} -{" "}
                    {tier.cap ? (
                      tier.cap.toLocaleString()
                    ) : (
                      <span>&infin;</span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                    {rateLabelFromTier(tier)}
                  </td>
                </tr>
              ))}
            {!isLoading && tiers?.length === 0 && (
              <tr>
                <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                  -
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                  -
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showDescription && tiers.length >= 2 && (
        <p className="m-2 max-w-2xl text-sm text-gray-500">
          MAUs are counted as the number of unique device IDs that used the SDK
          during a calendar month.
        </p>
      )}
    </Card>
  );
};

export default PriceTableRemodelAI;
