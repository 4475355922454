import { useAuth0 } from "@auth0/auth0-react";

import MessageBlock from "components/organisms/MessageBlock";

const marketingBase = process.env.REACT_APP_MARKETING_BASE as string;

const PageNotFound = () => {
  const { logout } = useAuth0();

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: marketingBase } });

  return (
    <MessageBlock
      errorCode="404 ERROR"
      headline="Page Not Found"
      content="Sorry, we couldn't find the page you're looking for."
      onClick={handleLogout}
    />
  );
};

export default PageNotFound;
