import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

interface AdditionalUsersControlProps {
  floor: number;
  ceiling: number;
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const decrement = (
  value: number,
  floor: number,
  setValue: React.Dispatch<React.SetStateAction<number>>
) => {
  if (value - 1 >= floor) {
    setValue(value - 1);
  }
};

const increment = (
  value: number,
  ceiling: number,
  setValue: React.Dispatch<React.SetStateAction<number>>
) => {
  if (value + 1 <= ceiling) {
    setValue(value + 1);
  }
};

const AdditionalUsersControl = ({
  floor,
  ceiling,
  value,
  setValue,
}: AdditionalUsersControlProps) => {
  return (
    <div className="h-full w-full ">
      <div className="flex flex-col place-items-center">
        <div className="font-bold m-4">Change Additional Users</div>
        <div className="">
          <div className="flex flex-row bg-transparent">
            <button
              className="inline-flex items-center text-center px-4 py-0 whitespace-nowrap border border-transparent shadow-sm font-medium rounded-l-md text-white bg-passio-indigo hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-passio-purple mx-1 disabled:opacity-65"
              disabled={value === floor}
              onClick={() => decrement(value, floor, setValue)}
            >
              <MinusIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </button>
            <span className="inline-flex items-center text-center px-6 py-1 whitespace-nowrap border border-transparent shadow-sm font-large">
              {value}
            </span>
            <button
              className="inline-flex items-center text-center px-4 py-0 whitespace-nowrap border border-transparent shadow-sm font-medium rounded-r-md text-white bg-passio-indigo hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-passio-purple mx-1 disabled:opacity-65"
              disabled={value === ceiling}
              onClick={() => increment(value, ceiling, setValue)}
            >
              <PlusIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalUsersControl;
