import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";

import { formatPriceIntoCents } from "utils";
import { TierType } from "utils/types";

interface PriceTableProps {
  tiers?: TierType[];
  isLoading?: boolean;
  showDescription: boolean;
}

export const rateLabelFromTier = (tier: TierType): string => {
  // By default, no price
  let price = "";
  let priceDescription = "free";

  // If the flat price is present, then show it
  if (tier.flatPrice > 0) {
    price = formatPriceIntoCents(tier.flatPrice);
    priceDescription = "flat";
  }

  // If the unit price is present, show that instead
  if (tier.unitPrice > 0) {
    price = formatPriceIntoCents(tier.unitPrice);
    priceDescription = "per";
  }

  return `${price} ${priceDescription}`;
};

export const batchedRateLabelFromTier = (
  tier: TierType,
  includePer?: boolean
): string => {
  // By default, no price
  let price = "";
  let priceDescription = "free";

  // If the flat price is present, then show it
  if (tier.flatPrice > 0) {
    price = formatPriceIntoCents(tier.flatPrice);
    priceDescription = "flat";
  }

  // If the unit price is present, show that instead
  if (tier.unitPrice > 0) {
    price = tier.unitPrice.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
    priceDescription = convertToPer(tier.batchSize || 1);
  }

  if (includePer) return `${price} ${priceDescription}`;
  return `$${price}`;
};

export function getPrettyDisplayPrice(price: number): string {
  let roundedPrice: number;

  if (price < 100) {
    roundedPrice = Math.round(price);
  } else if (price < 1000) {
    roundedPrice = Math.round(price / 10) * 10;
  } else {
    roundedPrice = Math.round(price / 100) * 100;
  }

  return `$${roundedPrice}`;
}

export function calculatePrice(quantity: number, tiers: TierType[]): number {
  let totalCost = 0;

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];
    const isLastTier = tier.cap === 0 || i === tiers.length - 1;
    const isInCurrentTier = quantity <= tier.cap || isLastTier;

    if (isInCurrentTier) {
      totalCost = quantity * tier.unitPrice;
      break;
    }
  }

  return Math.round(totalCost * 100) / 100; // Round to two decimal places
}
export function calculatePriceGraduated(
  quantity: number,
  tiers: TierType[]
): number {
  let remainingTokens = quantity;
  let totalCost = 0;

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];
    const prevTierCap = i > 0 ? tiers[i - 1].cap : 0;
    const tierTokens =
      tier.cap === 0
        ? remainingTokens
        : Math.min(remainingTokens, tier.cap - prevTierCap);

    totalCost += tierTokens * tier.unitPrice;
    remainingTokens -= tierTokens;

    if (remainingTokens <= 0) break;
  }

  return Math.round(totalCost * 100) / 100; // Round to two decimal places
}

export function convertToNumLetter(number: number) {
  if (number === 1) return "each";
  const units = [
    { value: 1e9, symbol: "b" },
    { value: 1e6, symbol: "m" },
    { value: 1e3, symbol: "k" },
  ];

  for (const unit of units) {
    if (Math.abs(number) >= unit.value) {
      const convertedNumber = number / unit.value;
      const roundedNumber = Math.round(convertedNumber * 10) / 10;
      const formattedNumber =
        roundedNumber % 1 === 0
          ? roundedNumber.toFixed(0)
          : roundedNumber.toFixed(1);
      return `${formattedNumber}${unit.symbol}`;
    }
  }
  return Math.round(number).toString();
}

function convertToPer(number: number) {
  if (number === 1) return "each";
  const units = [
    { value: 1e9, symbol: "b" },
    { value: 1e6, symbol: "m" },
    { value: 1e3, symbol: "k" },
  ];

  for (const unit of units) {
    if (Math.abs(number) >= unit.value) {
      return `per /${unit.symbol}`;
    }
  }

  return `per /${number}`;
}

const PriceTableNutritionAPI = ({
  tiers = [],
  isLoading,
  showDescription,
}: PriceTableProps) => {
  return (
    <Card borderRequired className="h-full">
      <div className="w-full overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3 px-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
              >
                Monthly Requests
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
              >
                Rate
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {isLoading && (
              <tr>
                <td colSpan={100} className="text-center py-8">
                  <Spinner className="!w-[3rem]" />
                </td>
              </tr>
            )}
            {!isLoading &&
              tiers.length > 0 &&
              tiers?.map((tier, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                    {index > 0 ? tiers[index - 1].cap.toLocaleString() : 1} -{" "}
                    {tier.cap ? (
                      tier.cap.toLocaleString()
                    ) : (
                      <span>&infin;</span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                    {rateLabelFromTier(tier)}
                  </td>
                </tr>
              ))}
            {!isLoading && tiers?.length === 0 && (
              <tr>
                <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                  -
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                  -
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showDescription && tiers.length >= 2 && (
        <div className="mt-2 max-w-2xl text-sm text-gray-500 flex flex-col justify-between">
          <div className="flex-1">
            <p className="pt-1 ml-1 mr-1 mb-1">
              {" "}
              *Beyond the initial {tiers[0].cap?.toLocaleString("en-US")} uses,
              requests are billed in blocks of
              <b>
                {tiers[tiers.length - 1].batchSize?.toLocaleString("en-US")}{" "}
                requests at{" "}
                {(
                  tiers[tiers.length - 1].unitPrice *
                  (tiers[tiers.length - 1].batchSize || 1)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                per block.
              </b>
            </p>
          </div>
        </div>
      )}
    </Card>
  );
};

export default PriceTableNutritionAPI;
