import React, { ReactNode, useState } from "react";

interface FAQItem {
  question: string;
  answer: ReactNode;
}
const faqData: FAQItem[] = [
  {
    question: "What is the cost per user? Can I limit the cost per user?",
    answer: (
      <>
        <p>
          Costs per user vary depending on usage. You can use the calculator
          provided on Passio&#39;s account portal to estimate token usage. For
          example, if a user sends one photo to the API for analysis, it will
          result in 5k tokens spent from the account. We estimate that an
          average user logging meals regularly for one month would utilize
          around 250k tokens.
        </p>
        <p className="pt-4">
          To manage costs, you can limit usage to a fixed amount by engineering
          your app with token limits. For instance, you could limit a free user
          to 80k - 100k tokens and then ask users to pay once they reach that
          limit. This approach allows you to demonstrate value to users while
          keeping your cost between 1 to 10 cents per user.
        </p>
      </>
    ),
  },
  {
    question: "What are tokens, and how are they used in the Passio Hub plans?",
    answer:
      "Tokens are used to quantify the usage of Passio services. Each action, such as an API request, Vision AI request, Text AI request, or Chat Message request, consumes a specific number of tokens. The monthly plans include a set number of tokens, and users can purchase additional tokens if they exceed their monthly limit.",
  },
  {
    question: "What is a token in the context of AI processing?",
    answer:
      "A token is a unit of measurement used in AI processing to represent pieces of input or output data. For example, when you send text to an AI, the words and punctuation are broken down into tokens, which the AI then processes.",
  },
  {
    question: "How does token pricing work for your AI services?",
    answer:
      "Our token pricing is designed to be simple and predictable. We roll the input and output token costs into a single average value. This consolidated price also covers additional costs, such as Passio API usage, ensuring a consistent billing platform. This approach allows us to update and improve our AI models without needing to change our pricing structure.",
  },
  {
    question: "How is the token cost per API call determined?",
    answer:
      "The token cost per API call is set to cover the cost of using the service and is priced to align with industry-standard API billing costs. This ensures that our pricing remains competitive and fair within the market.",
  },
  {
    question:
      "What happens if I exceed the monthly token limit included in my plan?",
    answer: (
      <>
        <p>
          If you exceed the monthly token limit included in your plan, our
          billing portal allows you to configure an automatic refill option. By
          default, this setting is enabled on your account. When enabled, if you
          make a request to the API and don&#39;t have enough tokens, you will
          automatically be billed at a rate of $2.50 per million tokens to top
          up the number of tokens you have set.
        </p>
        <p>
          For example, if your refill amount is set to 2 million tokens, you
          will be charged $5.00 and gain 2 million more tokens.
        </p>
        <p>
          There are no volume discounts on refills, but you can manually
          purchase additional tokens at any time through the dashboard. If the
          automatic refill option is turned off and you run out of tokens, the
          service will stop responding until more tokens are added.
        </p>
      </>
    ),
  },
  {
    question: "Can I upgrade or downgrade my subscription plan at any time?",
    answer:
      "Yes, you can upgrade or downgrade your subscription plan at any time to better suit your usage needs. This flexibility allows you to adjust your plan based on changes in your application's user base or feature set.",
  },
  {
    question:
      "What is included in the REST API access for the food database and Nutrition Advisor?",
    answer: (
      <>
        <p>
          The REST API is essential for the mobile SDK to gather all necessary
          food information and is required for the SDK to function. The API
          offers a wide range of capabilities, including:
        </p>
        <ul className="list-disc pl-6 mt-2">
          <li>Text-search foods: Easily find foods by searching text.</li>
          <li>
            Full food details and nutrition: Access comprehensive information
            about foods and their nutritional content.
          </li>
          <li>Meal plans: Retrieve and manage meal plans.</li>
          <li>Bar code scan: Scan barcodes to get food information.</li>
          <li>
            Computer vision routes for food recognition: Use image recognition
            to identify foods.
          </li>
          <li>
            AI assistant tools: Utilize features such as visually similar food
            suggestions based on user profiles and AI nutritionist
            conversations.
          </li>
        </ul>
        <p className="mt-2">
          This comprehensive access ensures that you can fully leverage the
          capabilities of our food database and Nutrition Advisor in your
          applications.
        </p>
      </>
    ),
  },
  {
    question:
      "What are the volume discounts, and how do they affect my monthly cost?",
    answer: (
      <>
        <p>
          Volume discounts are applied based on the number of tokens you use.
          The rate per million tokens decreases as your usage increases, as
          follows:
        </p>
        <ul className="list-disc pl-6 mt-2">
          <li>1-20M tokens: $2.50 per million tokens</li>
          <li>20-100M tokens: $2.45 per million tokens</li>
          <li>100-500M tokens: $2.40 per million tokens</li>
          <li>500M+ tokens: $2.35 per million tokens</li>
        </ul>
        <p className="mt-2">
          These discounts can significantly reduce your monthly cost if you have
          high token usage. Volume discounts are not applied on refills.
        </p>
      </>
    ),
  },
  {
    question:
      "Is there a free trial or a way to test the service before committing to a plan?",
    answer:
      "Currently, we do not offer free trials. Upon purchasing, you will be billed immediately for the token credits. This pricing model ensures we can cover the necessary costs required to serve your needs and maintain the high-quality service you expect.",
  },
  {
    question:
      "What is the difference between on-device and cloud-side recognition?",
    answer: (
      <>
        <p>
          On-device recognition is faster and processes photos on the device
          itself, meaning the photos are not sent to the cloud. It costs fewer
          tokens, but only allows you to log a single ingredient at a time, and
          requires multiple uses to log a meal.
        </p>
        <p className="pt-4">
          Cloud-side recognition, which uses the Nutrition AI Advisor API, has
          more tokens per call, but will recognize everything in the image and
          return all foods, requiring only one main scan to log the meal. It is
          more powerful but has some latency due to the images being processed
          in the cloud.
        </p>
      </>
    ),
  },
];
const FAQSection: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full px-20 py-16 bg-white rounded-lg shadow">
      <h2 className="w-full text-center text-gray-900 text-4xl font-extrabold font-inter leading-10 mb-6">
        Frequently Asked Questions
      </h2>
      <div className="flex flex-col">
        {faqData.map((item, index) => (
          <div key={index} className="flex flex-col justify-start items-center">
            <div className="w-full h-px bg-gray-200" />
            <div className="w-full pt-6 flex flex-col justify-start items-center gap-2">
              <div
                className="w-full flex justify-between items-center gap-6 cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <div className="grow text-black text-lg font-medium font-inter leading-7 mb-2">
                  {item.question}
                </div>
                <div className="w-6 h-6 flex justify-center items-center">
                  <div className="w-6 h-6 relative">
                    {openIndex === index ? "-" : "+"}
                  </div>
                </div>
              </div>
              {openIndex === index && (
                <div className="w-full pl-6 pr-20 flex justify-start items-start mt-2 mb-4">
                  <div className="grow text-gray-500 text-base font-normal font-inter leading-normal">
                    {item.answer}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
