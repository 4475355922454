import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate, Outlet } from "react-router-dom";

import UserContext from "context/UserContext";

import Loading from "components/pages/Loading";

import { error, userIsOrganizationOwner } from "utils";
import { UserContextType } from "utils/types";

const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;
const tokenNamespace = process.env.REACT_APP_TOKEN_NAMESPACE as string;

const AuthenticatedLayout = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userRefreshToggle, setUserRefreshToggle] = useState(false);
  const [userContext, setUserContext] = useState<UserContextType>(
    {} as UserContextType
  );

  useEffect(() => {
    async function getToken() {
      try {
        const cacheMode: GetTokenSilentlyOptions["cacheMode"] = "off";
        const token = await getAccessTokenSilently({
          cacheMode,
          authorizationParams: { audience },
        });
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const decoded = jwt_decode(token) as any;
        LogRocket.identify(decoded[tokenNamespace].email, {
          email: decoded[tokenNamespace].email,
          customerId: decoded[tokenNamespace].customerid,
        });
        const customerAccessData = await userIsOrganizationOwner();

        const uc: UserContextType = {
          email: decoded[tokenNamespace].email,
          roles: decoded[tokenNamespace].roles,
          customerid:
            decoded[tokenNamespace].customerid || customerAccessData.customerId,
          isOrgOwner: customerAccessData.isOwner,
          setUserRefreshToggle: setUserRefreshToggle,
          userRefreshToggle: userRefreshToggle,
        };
        setUserContext(uc);

        ReactGA.event("login");
      } catch (err: any) {
        error(err.message || "Could not retrieve access token.");
      }
    }

    if (isAuthenticated) {
      getToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated, userRefreshToggle]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !isAuthenticated) {
    <Navigate to="/unauthorized" />;
  }

  return (
    <UserContext.Provider value={userContext}>
      <Outlet />
    </UserContext.Provider>
  );
};

export default AuthenticatedLayout;
