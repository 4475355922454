import { XMarkIcon } from "@heroicons/react/24/outline";

interface CloseButtonProps {
  closeModal: () => void;
}

const CloseButton = ({ closeModal }: CloseButtonProps) => {
  return (
    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
      <button
        type="button"
        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={closeModal}
      >
        <span className="sr-only">Close</span>
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};

export default CloseButton;
