import { classNames } from "utils";

interface BadgeProps {
  isWarning?: boolean;
  children: React.ReactNode;
}

const Badge = ({ isWarning = false, children }: BadgeProps) => {
  const warningClass = isWarning
    ? "bg-passio-lightRed text-passio-darkRed"
    : "border !bg-white !border-passio-indigo !text-passio-indigo";
  return (
    <div
      className={classNames(
        "inline-flex items-center rounded-md px-4 py-2 text-sm font-medium",
        warningClass
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
