import {
  ArrowTopRightOnSquareIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowDownTrayIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import Plot from "react-plotly.js";
import { Navigate } from "react-router-dom";

import UsageDetailCard from "./HubUsageDetail";
import RefillSettingsCard, {
  ConvertToSubscriptionMetadata,
} from "./RefillSettingsForm";
import { SubscriptionMetadata } from "./RefillSettingsForm";

import UserContext from "context/UserContext";

import Badge from "components/atoms/Badge";
import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";
import { convertToNumLetter } from "components/molecules/PriceTables/PriceTableNutritionAPI";
import SlackSignUp from "components/molecules/SlackSignUp";

import { error, success } from "utils";
import {
  CREDIT_REFILL_PRICE_DISPLAY,
  ProductDataType,
  UsageHistoryReportForPeriod,
} from "utils/types";

const DetailsPassioHub = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  const [unauthorized, setUnauthorized] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const [hideKey, setHideKey] = useState(true);
  const [productState, setProductState] = useState<ProductDataType>({
    isSubscribed: false,
    trialEnd: null,
    billingDate: new Date(),
    licenseKey: null,
    usageInfo: null,
    tiers: [],
  });

  // TODO: Type incoming
  const [productSubDetail, setProductSubDetail] = useState<any>(null);

  const [predictedPercentage, setPredictedPercentage] = useState<number>(0);

  const calculatePredictedUsage = (
    startDate: number,
    endDate: number,
    currentUsage: number
  ): number => {
    const now = new Date();
    const start = new Date(startDate * 1000);
    const end = new Date(endDate * 1000);

    const totalDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
    let daysPassed = (now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

    // Ensure at least 1 day has passed for the calculation, to avoid division by zero or very small numbers
    daysPassed = Math.max(daysPassed, 1);

    if (daysPassed <= 0) return currentUsage;

    const dailyRate = currentUsage / daysPassed;
    const predictedTotal = dailyRate * totalDays;

    return predictedTotal;
  };

  useEffect(() => {
    if (productSubDetail?.stripeSubscription && productSubDetail.usageInfo) {
      const predicted = calculatePredictedUsage(
        productSubDetail.stripeSubscription.current_period_start,
        productSubDetail.stripeSubscription.current_period_end,
        productSubDetail.usageInfo.units
      );
      setPredictedPercentage(
        (predicted / productSubDetail.usageInfo.unitsQuota) * 100
      );
    }
  }, [productSubDetail]);

  const groupModes = [
    ["Request Type", "type"],
    ["Request Path", "path"],
    ["AI Mode", "aiMode"],
  ];
  const [usageDetailGroupMode, setUsageDetailGroupMode] = useState(
    groupModes[0]
  );
  const [usageView, setUsageView] = useState<"basic" | "graph" | "history">(
    "basic"
  );
  const [linksView, setLinksView] = useState<"API" | "SDK" | "Module">("API");
  const [usageHistoryForPeriod, setUsageHistoryForPeriod] =
    useState<UsageHistoryReportForPeriod | null>();

  const userContext = useContext(UserContext);
  const [usageDetail, setUsageDetail] = useState<any>(null);

  const getProduct = async () =>
    await axios.get(`/accounts/products/${productQuery}/detail`);
  const getProductUsageDetail = async () =>
    await axios.get(
      `/accounts/products/${productQuery}/usage/detail?groupMode=${usageDetailGroupMode[1]}`
    );

  const getUsageHistoryDetail = async () =>
    await axios.get(
      `/accounts/products/${productQuery}/usage/history?periodStart=${billingPeriods[selectedPeriodIndex].start}&periodEnd=${billingPeriods[selectedPeriodIndex].end}`
    );

  const [usedPercentage, setUsedPercentage] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [periodStart, setPeriodStart] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [periodEnd, setPeriodEnd] = useState<number | null>(null);
  const [billingPeriods, setBillingPeriods] = useState<
    { start: number; end: number }[]
  >([]);
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(0);

  const {
    isLoading: usageDetailLoading,
    isFetching: usageDetailFetching,
    // isPaused: usageDetailPaused,
    // refetch: refetchUsageDetail,
  } = useQuery(["usageDetail", usageDetailGroupMode], getProductUsageDetail, {
    enabled: !!productQuery && !!userContext,
    onError: (err: AxiosError) => {
      error(
        err?.response?.data?.cause ||
          "Could not fetch the products usage detail"
      );
      if (err?.response?.status === 403) {
        setUnauthorized(true);
      }
    },
    onSuccess: (res: AxiosResponse) => {
      setUsageDetail(res?.data);
    },
  });

  const { isLoading: hisoryDataLoading, isFetching: historyDataFetching } =
    useQuery(["usageHistory", selectedPeriodIndex], getUsageHistoryDetail, {
      enabled: usageView === "history",
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        // res.data.usageInfo.units = res?.data?.usageInfo?.units * 10 || 0;
        setUsageHistoryForPeriod(res?.data);
      },
    });

  const { isLoading, isFetching, refetch } = useQuery(["product"], getProduct, {
    enabled: !!productQuery && !!userContext,
    onError: (err: AxiosError) => {
      error(err?.response?.data?.cause || "Could not fetch the products");
      if (err?.response?.status === 403) {
        setUnauthorized(true);
      }
    },
    onSuccess: (res: AxiosResponse) => {
      // res.data.usageInfo.units = res?.data?.usageInfo?.units * 10 || 0;
      setProductSubDetail(res?.data);
      setUsedPercentage(
        res?.data?.usageInfo
          ? (res?.data?.usageInfo.units / res?.data?.usageInfo.unitsQuota) * 100
          : 0
      );
      setProductState((prev) => ({
        ...prev,
        isSubscribed: res.data?.isSubscribed,
        trialEnd: res.data?.trialEnd ? new Date(res.data?.trialEnd) : null,
        billingDate: new Date(res.data?.billingDate),
        licenseKey: res.data?.licenseKey,
        usageInfo: res.data?.usageInfo,
        tiers: res.data?.tiers || [],
      }));
      // Set period start and end
      const currentPeriodStart =
        res.data?.stripeSubscription?.current_period_start;
      const currentPeriodEnd = res.data?.stripeSubscription?.current_period_end;
      setPeriodStart(currentPeriodStart);
      setPeriodEnd(currentPeriodEnd);

      // Calculate and set billing periods
      if (currentPeriodStart && currentPeriodEnd) {
        const periods = [];
        const periodLength = currentPeriodEnd - currentPeriodStart;
        const subscriptionStart = res.data.stripeSubscription.start_date; // Assuming this is the subscription start date

        for (let i = 0; i < 6; i++) {
          const start = currentPeriodStart - i * periodLength;
          const end = currentPeriodEnd - i * periodLength;

          if (start >= subscriptionStart) {
            periods.push({ start, end });
          }
        }
        setBillingPeriods(periods);
      }
    },
  });

  async function copyTextToClipboard(text: string) {
    return await navigator.clipboard.writeText(text);
  }

  const handleCopyClick = () => {
    copyTextToClipboard(productState?.licenseKey ?? "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  // Google Adds Event Tracking
  useEffect(() => {
    // Function to get URL parameter
    const getQueryParam = (param: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };
    const encodedData = getQueryParam("event");
    if (encodedData) {
      const decodedString = atob(encodedData);
      try {
        const obj = JSON.parse(decodedString);
        ReactGA.event(obj);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, []);

  const handleDownloadCSV = () => {
    const blob = new Blob([usageHistoryForPeriod?.usageCSV || ""], {
      type: "text/csv",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "usage_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  // TODO: DVS: Fix
  // if (!isFetching && !isLoading && !isPaused && !productState.isSubscribed) {
  //   return <Navigate to="/" />;
  // }
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
          Passio Hub Product Details
        </h2>
      </div>
      <div className="grid lg:grid-cols-2 gap-8 auto-rows-auto">
        <Card className="sm:p-6">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-medium">Current Period</p>
              <span className="text-sm text-gray-500">
                {new Date(
                  productSubDetail?.stripeSubscription?.current_period_start *
                    1000
                )?.toLocaleDateString("default", {
                  month: "2-digit",
                  year: "2-digit",
                  day: "2-digit",
                })}
                {"  -  "}
                {new Date(
                  productSubDetail?.stripeSubscription?.current_period_end *
                    1000
                )?.toLocaleDateString("default", {
                  month: "2-digit",
                  year: "2-digit",
                  day: "2-digit",
                })}
              </span>
            </div>
            {!isFetching && !isLoading ? (
              productState.trialEnd ? (
                <Badge isWarning>
                  <ClockIcon
                    className="h-5 w-5 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Free Trial Ends on{" "}
                  {productState.trialEnd.toLocaleDateString()}
                </Badge>
              ) : (
                <Badge>
                  <ClockIcon
                    className="h-5 w-5 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Billed Next on {productState.billingDate.toLocaleDateString()}
                </Badge>
              )
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <div className="flex flex-col justify-center items-center w-full">
              <div className="flex flex-col items-center w-full mb-2">
                {/* <span className="text-sm text-gray-500 text-center mb-1 font-medium">
                  Current Usage
                </span> */}
                <div className="flex space-x-2 mb-8">
                  <button
                    onClick={() => setUsageView("basic")}
                    className={`px-3 py-1 text-sm font-medium rounded-full ${
                      usageView === "basic"
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                    }`}
                  >
                    Basic
                  </button>
                  <button
                    onClick={() => setUsageView("graph")}
                    className={`px-3 py-1 text-sm font-medium rounded-full ${
                      usageView === "graph"
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                    }`}
                  >
                    Graph
                  </button>
                  <button
                    onClick={() => setUsageView("history")}
                    className={`px-3 py-1 text-sm font-medium rounded-full ${
                      usageView === "history"
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                    }`}
                  >
                    History
                  </button>
                </div>

                {isLoading || isFetching ? (
                  <Spinner className="!w-[2rem]" />
                ) : productSubDetail?.usageInfo?.units !== undefined ? (
                  <>
                    {usageView === "basic" && (
                      <>
                        <div className="text-passio-indigo text-md font-bold">
                          {convertToNumLetter(productSubDetail.usageInfo.units)}{" "}
                          /{" "}
                          {convertToNumLetter(
                            productSubDetail.usageInfo.unitsQuota
                          )}
                        </div>
                        <div className="w-full bg-indigo-50 rounded-full h-6 relative overflow-hidden">
                          <div
                            className="absolute bg-indigo-100 h-6 rounded-full"
                            style={{
                              width: `${Math.min(predictedPercentage, 100)}%`,
                            }}
                          ></div>
                          <div
                            className="absolute bg-indigo-600 h-6 rounded-full"
                            style={{
                              width: `${Math.min(usedPercentage, 100)}%`,
                            }}
                          ></div>
                        </div>
                        <div className="flex justify-between w-full text-indigo-600">
                          <span className="text-sm font-medium">
                            {usedPercentage.toFixed(1)}% Used {" ("}
                            {predictedPercentage.toFixed(1)}% Projected for
                            period{")"}
                          </span>
                          {usedPercentage < 100 && (
                            <span className="text-sm font-medium">
                              {(100 - usedPercentage).toFixed(1)}% Available
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {usageView === "history" && (
                      <>
                        {!historyDataFetching && !hisoryDataLoading ? (
                          <div className="space-y-6">
                            {/* Top row with billing period select and CSV download */}
                            <div className="flex justify-between items-end w-full">
                              {/* Billing Period Select */}
                              <div className="w-1/2">
                                <label
                                  htmlFor="billing-period"
                                  className="block text-sm font-medium text-gray-700 mb-1"
                                >
                                  Select Billing Period
                                </label>
                                <select
                                  id="billing-period"
                                  className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                  value={selectedPeriodIndex}
                                  onChange={(e) => {
                                    const index = parseInt(e.target.value);
                                    setSelectedPeriodIndex(index);
                                  }}
                                >
                                  {billingPeriods.map((period, index) => (
                                    <option key={index} value={index}>
                                      {new Date(
                                        period.start * 1000
                                      ).toLocaleDateString()}{" "}
                                      -{" "}
                                      {new Date(
                                        period.end * 1000
                                      ).toLocaleDateString()}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* CSV Download Button */}
                              <div className="w-1/2 px-8">
                                <Button
                                  onClick={handleDownloadCSV}
                                  className="whitespace-nowrap mb-1"
                                >
                                  Download Usage CSV
                                </Button>
                              </div>
                            </div>

                            {/* Invoice Links */}
                            <div className="mt-3">
                              <h3 className="text-sm font-medium text-gray-900 mb-2">
                                Invoice Downloads
                              </h3>
                              <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                {usageHistoryForPeriod?.invoiceLinks.map(
                                  (link, index) => (
                                    <li
                                      key={`inv-${index}`}
                                      className="px-4 py-2 hover:bg-gray-50"
                                    >
                                      <a
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-600 hover:text-indigo-800 flex items-center text-sm"
                                      >
                                        <ArrowDownTrayIcon className=" pr-2 w-5 h-5" />
                                        Download Period Invoice {index + 1}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <Spinner className="!w-[2rem]"></Spinner>
                        )}
                      </>
                    )}
                    {usageView === "graph" && (
                      <div className="w-full mt-4 items-center align-middle content-center">
                        <Plot
                          data={[
                            {
                              type: "bar" as const,
                              x: [
                                "Used",
                                "Remaining",
                                "Avg/Day",
                                "Projected Usage",
                                "Projected Overage",
                              ],

                              y: [
                                productSubDetail.usageInfo.units,
                                productSubDetail.usageInfo.unitsQuota -
                                  productSubDetail.usageInfo.units,
                                productSubDetail.usageInfo.units /
                                  ((new Date().getTime() -
                                    new Date(
                                      productSubDetail.stripeSubscription
                                        .current_period_start * 1000
                                    ).getTime()) /
                                    (1000 * 60 * 60 * 24)),
                                (predictedPercentage *
                                  productSubDetail.usageInfo.unitsQuota) /
                                  100,
                                Math.max(
                                  0,
                                  (predictedPercentage *
                                    productSubDetail.usageInfo.unitsQuota) /
                                    100 -
                                    productSubDetail.usageInfo.unitsQuota
                                ),
                              ],
                              text: [
                                convertToNumLetter(
                                  productSubDetail.usageInfo.units
                                ),
                                convertToNumLetter(
                                  productSubDetail.usageInfo.unitsQuota -
                                    productSubDetail.usageInfo.units
                                ),
                                convertToNumLetter(
                                  productSubDetail.usageInfo.units /
                                    ((new Date().getTime() -
                                      new Date(
                                        productSubDetail.stripeSubscription
                                          .current_period_start * 1000
                                      ).getTime()) /
                                      (1000 * 60 * 60 * 24))
                                ),
                                convertToNumLetter(
                                  (predictedPercentage *
                                    productSubDetail.usageInfo.unitsQuota) /
                                    100
                                ),
                                convertToNumLetter(
                                  Math.max(
                                    0,
                                    (predictedPercentage *
                                      productSubDetail.usageInfo.unitsQuota) /
                                      100 -
                                      productSubDetail.usageInfo.unitsQuota
                                  )
                                ),
                              ],
                              textposition: "auto" as const,
                              hoverinfo: "text" as const,
                              marker: {
                                color: [
                                  "#4F46E5",
                                  "#818CF8",
                                  "#A5B4FC",
                                  "#C7D2FE",
                                  "#a42820",
                                ],
                              },
                              yaxis: "y" as const,
                            },
                            {
                              type: "bar" as const,
                              x: ["Days Left"],
                              y: [
                                Math.ceil(
                                  (new Date(
                                    productSubDetail.stripeSubscription
                                      .current_period_end * 1000
                                  ).getTime() -
                                    new Date().getTime()) /
                                    (1000 * 60 * 60 * 24)
                                ),
                              ],
                              text: [
                                Math.ceil(
                                  (new Date(
                                    productSubDetail.stripeSubscription
                                      .current_period_end * 1000
                                  ).getTime() -
                                    new Date().getTime()) /
                                    (1000 * 60 * 60 * 24)
                                ).toString() + " days",
                              ],
                              textposition: "auto" as const,
                              hoverinfo: "text" as const,
                              marker: {
                                color: "#10B981",
                              },
                              yaxis: "y2" as const,
                            },
                          ]}
                          layout={{
                            title: "Token Usage Overview",
                            font: { size: 10 },
                            autosize: true,
                            height: 300,
                            margin: { l: 40, r: 40, t: 40, b: 80 },
                            bargap: 0.15,
                            yaxis: {
                              title: "Tokens",
                              side: "left" as const,
                              range: [
                                0,
                                Math.max(
                                  productSubDetail.usageInfo.unitsQuota,
                                  (predictedPercentage *
                                    productSubDetail.usageInfo.unitsQuota) /
                                    100
                                ),
                              ],
                            },
                            yaxis2: {
                              title: "Billing Period Days",
                              side: "right" as const,
                              overlaying: "y" as const,
                              rangemode: "tozero" as const,
                              range: [
                                0,
                                Math.max(
                                  31,
                                  Math.ceil(
                                    (new Date(
                                      productSubDetail.stripeSubscription
                                        .current_period_end * 1000
                                    ).getTime() -
                                      new Date().getTime()) /
                                      (1000 * 60 * 60 * 24)
                                  )
                                ),
                              ],
                              dtick: 5,
                            },
                            showlegend: false,
                          }}
                          useResizeHandler={true}
                          // style={{ width: "450px", height: "220px" }}
                          style={{ width: "auto", height: "220px" }}
                          config={{
                            displayModeBar: false,
                            responsive: true,
                          }}
                        />
                      </div>
                    )}
                    <div className="text-sm text-gray-500 mt-2 mb-2 w-full">
                      {predictedPercentage > 100 && (
                        <div className="mt-2 p-4 bg-red-100 rounded-md text-red-600">
                          <p className="">
                            Warning: You are projected to exceed your token
                            quota.
                          </p>
                          <p>
                            {productSubDetail.stripeSubscription.metadata
                              .refillBehaviour == "auto"
                              ? `Auto-refill will purchase more tokens at ${CREDIT_REFILL_PRICE_DISPLAY} per million.`
                              : " Consider enabling auto-refill or manually purchasing more tokens to avoid service interruption."}
                          </p>
                          <p className="pt-2 font-bold">
                            {`Estimated Overage Cost: ${(
                              2.5 *
                              Math.ceil(
                                Math.max(
                                  0,
                                  (predictedPercentage *
                                    productSubDetail.usageInfo.unitsQuota) /
                                    100 -
                                    productSubDetail.usageInfo.unitsQuota
                                ) / 1000000
                              )
                            ).toLocaleString("en-US", {
                              currency: "USD",
                              style: "currency",
                            })}`}
                          </p>
                        </div>
                      )}
                    </div>
                    <span className="text-xs mt-2 text-gray-800">
                      *Usage and predictions in this dashboard may take a few
                      minutes to reflect the latest data. Your API client
                      responses will be up-to-date.
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        </Card>

        <Card className="sm:p-6 row-span-2">
          <div className="flex flex-col">
            <p className="font-medium">API Key & Info</p>
          </div>

          <div className="mt-6">
            <dt className="text-sm font-medium text-gray-500">API Key</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <KeyIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      {hideKey
                        ? Array.from({
                            length: productState?.licenseKey?.length || 0,
                          }).map((_, index) => (
                            <span key={index}>•</span> // Customize dot size with Tailwind classes
                          ))
                        : productState?.licenseKey ?? ""}
                    </span>
                  </div>
                  <div className="ml-4 flex flex-row flex-shrink items-center space-x-2">
                    {isCopied ? (
                      <ClipboardDocumentCheckIcon
                        className="h-5 w-5 flex-shrink-0 text-green-700"
                        aria-hidden="true"
                      />
                    ) : (
                      <DocumentDuplicateIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={handleCopyClick}
                      />
                    )}
                    {hideKey ? (
                      <EyeSlashIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={() => setHideKey(!hideKey)}
                      ></EyeSlashIcon>
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={() => setHideKey(!hideKey)}
                      ></EyeIcon>
                    )}
                  </div>
                </li>
              </ul>
              <p className="text-gray-500 text-sm ml-4 mt-1">
                * Keep your API Key safe - see the best practices in the
                documentation.
              </p>
            </dd>
          </div>

          <div className="pt-8">
            {/* <dt className="text-sm font-medium text-gray-500">Links</dt> */}
            <div className=" pb-2 flex flex-grow justify-center gap-3 items-center">
              <button
                onClick={() => setLinksView("API")}
                className={`px-3 py-1 text-sm font-medium rounded-full ${
                  linksView === "API"
                    ? "bg-indigo-600 text-white"
                    : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                }`}
              >
                API Links
              </button>
              <button
                onClick={() => setLinksView("SDK")}
                className={`px-3 py-1 text-sm font-medium rounded-full ${
                  linksView === "SDK"
                    ? "bg-indigo-600 text-white"
                    : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                }`}
              >
                SDK Links
              </button>
              <button
                onClick={() => setLinksView("Module")}
                className={`px-3 py-1 text-sm font-medium rounded-full ${
                  linksView === "Module"
                    ? "bg-indigo-600 text-white"
                    : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                }`}
              >
                UI Module Links
              </button>
            </div>
            <dd className="mt-1 text-sm text-gray-900">
              {linksView === "API" ? (
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={"https://passio.gitbook.io/nutrition-hub"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Documentation & Usage Guide (SDK & API)
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={`https://passio-nutrition-advisor-${
                            process.env.REACT_APP_ENV === "development"
                              ? "dev"
                              : "demo"
                          }.storage.googleapis.com/index.html?licenseKey=${btoa(
                            productState?.licenseKey || ""
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Try Your Nutrition Advisor!
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/nutrition-advisor-web-example"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          ReactJS Demo Advisor Chat
                        </a>
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://www.npmjs.com/package/passio-nutrition-advisor-client"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          npm Passio Hub Client Package (Typed)
                        </a>
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://api.passiolife.com/v2/products/nutrition-advisor/docs"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Advisor REST OpenAPI Documentation
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://api.passiolife.com/v2/products/napi/docs"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Food Database REST OpenAPI Documentation
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              ) : linksView == "SDK" ? (
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={"https://passio.gitbook.io/nutrition-ai"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Gitbook Documentation & Guide
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/Passio-Nutrition-AI-iOS-SDK-Distribution"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          iOS SDK Github Repository
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/Passio-Nutrition-AI-Android-SDK-Distribution"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Android SDK Github Repository
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/Passio-Nutrition-AI-RN-SDK-Distribution"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          React Native SDK Github Repository
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={"https://pub.dev/packages/nutrition_ai"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Flutter Plugin
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://www.figma.com/design/mHty2zcRXwwqR8z3CvS12G/Nutrition-AI-Demo-App-(External)?node-id=5311-28714&t=HoSylOcoLHaqkWhb-1"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Figma Designs
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              ) : (
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/Passio-Nutrition-AI-iOS-UI-Module-Distribution"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          iOS UI Module
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/Passio-Nutrition-AI-Android-UI-Module"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Android UI Module
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={
                            "https://github.com/Passiolife/Passio-Nutrition-AI-RN-UI-Module-Distribution"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          React Native UI Module
                        </a>
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <ArrowTopRightOnSquareIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 w-0 flex-1 truncate">
                        <a
                          href={"https://pub.dev/packages/nutrition_ai_module"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Flutter UI Module
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              )}
            </dd>
          </div>
        </Card>

        {/* <Card> */}
        <div className="flex-grow">
          {(!!isLoading && !!isFetching) || !!productSubDetail ? (
            <RefillSettingsCard
              subscriptionMetadata={ConvertToSubscriptionMetadata(
                productSubDetail?.stripeSubscription?.metadata ||
                  ({} as SubscriptionMetadata)
              )}
              onRefill={async () => {
                try {
                  await axios.post(
                    "/accounts/products/nutrition-hub/subscription/refill"
                  );
                  await new Promise((resolve) => setTimeout(resolve, 2000));
                  success("Refill successful");
                  refetch();
                } catch (err) {
                  error(
                    (err as AxiosError<{ cause: string }>)?.response?.data
                      ?.cause || "Failed to initiate refill"
                  );
                }
              }}
              onSave={async (newMetadata: SubscriptionMetadata) => {
                try {
                  await axios.put(
                    "/accounts/products/nutrition-hub/subscription",
                    newMetadata
                  );
                  success("Refill settings saved successfully");
                  refetch();
                } catch (err) {
                  error(
                    (err as AxiosError<{ cause: string }>)?.response?.data
                      ?.cause || "Failed to update refill settings"
                  );
                }
              }}
            />
          ) : (
            <Spinner></Spinner>
          )}
        </div>
        {/* </Card> */}
        <div className="w-full col-span-2 h-min">
          {!usageDetailFetching && !usageDetailLoading && usageDetail ? (
            <UsageDetailCard
              usageDetail={usageDetail}
              usageDetailGroupMode={usageDetailGroupMode}
              setUsageDetailGroupMode={setUsageDetailGroupMode}
              groupModes={groupModes}
            />
          ) : (
            <Card>
              <Spinner></Spinner>
            </Card>
          )}
        </div>

        {/* <div className="w-full col-span-2">
          <PriceTablePassioHub
            isLoading={isLoading || isFetching}
            tiers={productState.tiers}
            showDescription={true}
          />
        </div> */}
        <Card className="sm:p-6">
          <div>
            <p className="font-medium">Support & Documentation</p>
            <span className="text-sm text-gray-500">
              Links for additional support
            </span>
          </div>

          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Documentation</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio.gitbook.io/nutrition-hub"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio.gitbook.io/nutrition-hub
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Email Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="mailto:support@passiolife.com"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                support@passiolife.com
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Slack Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio-community.slack.com/channels/help"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio-community.slack.com/channels/help
              </a>
            </dd>
          </div>
        </Card>
        <SlackSignUp />
      </div>
    </>
  );
};

export default DetailsPassioHub;
