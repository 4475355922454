import { Dialog, Transition } from "@headlessui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Fragment } from "react";

import CloseButton from "components/atoms/CloseButton";
import PaymentMethodElement from "components/molecules/PaymentMethodElement";

import { classNames } from "utils";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? ""
);

export interface PaymentMethodModalType {
  isOpen: boolean;
}

interface PaymentMethodModalProps {
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
}

const PaymentMethodModal = ({
  isOpen,
  closeModal,
  className,
}: PaymentMethodModalProps) => {
  const elementsOptions = {
    mode: "setup" as const,
    currency: "usd",
    loader: "always" as const,
    paymentMethodCreation: "manual" as const,
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
              className ? className : ""
            )}
          />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-5/12 lg:w-3/6 xl:w-2/6 sm:p-6">
                <CloseButton closeModal={closeModal} />
                <div className="mb-3">
                  <h2 className="font-semibold text-lg lg:text-2xl">
                    Payment Information
                  </h2>
                </div>
                <div className="">
                  <Elements options={elementsOptions} stripe={stripePromise}>
                    <PaymentMethodElement
                      buttonText="Update"
                      paymentCompleteCB={closeModal}
                    />
                  </Elements>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PaymentMethodModal;
