import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import UserContext from "context/UserContext";

import Image from "components/atoms/Image";

import { classNames, userHasAdminRole } from "utils";

import passioLogoImg from "images/passio-logo-dark.png";

const marketingBase = process.env.REACT_APP_MARKETING_BASE as string;

interface NavHeaderProps {
  children: React.ReactNode;
}

const NavHeader = ({ children }: NavHeaderProps) => {
  const { logout, user } = useAuth0();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const userNavigation = [
    {
      name: "Sign out",
      redirect: () => {
        logout({ logoutParams: { returnTo: marketingBase } });
      },
    },
  ];

  if (userContext?.customerid && userContext.customerid.length > 0) {
    userNavigation.push({
      name: "Team",
      redirect: () => navigate("/team"),
    });
  }

  if (userHasAdminRole(userContext?.roles || [])) {
    userNavigation.push({
      name: "Billing",
      redirect: () => navigate("/billing"),
    });
  }

  return (
    <Fragment>
      <div
        className={`sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b`}
      >
        <div className="max-w-[1350px] mx-auto px-4 sm:px-8 flex-1 flex justify-between items-center">
          <Link to="/">
            <img
              className="my-auto max-w-[100px] sm:max-w-[150px]"
              src={passioLogoImg}
              alt="Passio logo"
            />
          </Link>
          <div className="ml-4 flex items-center md:ml-6">
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <Image
                    className={"h-10 w-10 rounded-full border bg-white"}
                    image={user?.picture}
                    alt={"Profile Picture"}
                  />
                  <span
                    className={`px-4 text-md text-ellipsis overflow-hidden max-w-[250px] normal-case`}
                    title="UserName"
                  >
                    {user?.name}
                  </span>
                  <ChevronDownIcon className={`w-4`} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <span
                          onClick={item.redirect}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                          )}
                        >
                          {item.name}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <main>{children}</main>
    </Fragment>
  );
};

export default NavHeader;
