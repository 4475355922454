import React from "react";

import { classNames } from "utils";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const FormInput = (props: FormInputProps) => {
  const { className = "", label, ...otherProps } = props;
  return (
    <div className="my-2">
      {label && (
        <label className="block font-medium leading-6 text-gray-900 text-[14px]">
          {label}
        </label>
      )}
      <input
        {...otherProps}
        className={classNames(
          "block h-[40px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-4 focus:ring-blue-200 sm:text-base sm:leading-6",
          className ?? ""
        )}
      />
    </div>
  );
};

export default FormInput;
