import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";

import Spinner from "../Spinner";

interface ModelProps {
  isOpen: boolean;
  setOpen: (setOpen: boolean) => void;
  title?: string;
  description?: string;
  isCancelButton?: boolean;
  submitButtonName: string;
  onSubmitted?: () => void;
  type?: "error" | "success";
  submitButtonType?: "submit" | "button";
  buttonType: "warning" | "primary";
  children?: any;
  keyProp?: number;
  disabled?: boolean;
  isLoading?: boolean;
}
const Modal = ({
  keyProp,
  isOpen,
  setOpen,
  title,
  description,
  submitButtonName,
  submitButtonType,
  isCancelButton = true,
  type,
  buttonType,
  onSubmitted,
  children = null,
  disabled = false,
  isLoading = false,
}: ModelProps) => {
  const product = localStorage.getItem("product") || "default";
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpen}
        key={keyProp}
        onSubmit={onSubmitted}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:w-[600px] lg:w-[800px] `}
              >
                <div>
                  {type && (
                    <div
                      className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${
                        type === "success" ? "bg-green-100" : "bg-red-100"
                      }`}
                    >
                      {type === "success" ? (
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  )}
                  {children}
                  <div className="mt-3 text-center sm:mt-5">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {description && (
                      <div className="mt-2">
                        <p className="text-base text-gray-500">{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`mt-5 ${
                    isCancelButton &&
                    "sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                  }`}
                >
                  <button
                    type={submitButtonType}
                    disabled={disabled}
                    id="modal-submit"
                    className={`w-full inline-flex justify-center rounded-md border-0 shadow-sm px-4 py-2 text-base font-medium text-white sm:col-start-2 sm:text-sm ${
                      buttonType === "primary"
                        ? `bg-gradient-to-r from-${product}-primary to-${product}-secondary`
                        : "bg-red-600 hover:bg-red-700 focus:ring-red-500"
                    } ${disabled ? "opacity-80" : ""}`}
                    onClick={() => {
                      if (onSubmitted) {
                        onSubmitted();
                      }
                    }}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <Spinner className="!w-4 !h-4 !text-white" />
                        {submitButtonName}
                      </div>
                    ) : (
                      <div>{submitButtonName}</div>
                    )}
                  </button>
                  {isCancelButton && (
                    <button
                      type="button"
                      id="modal-cancel"
                      className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm`}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;
