import React, { useState } from "react";
import Plot from "react-plotly.js";

import Card from "components/atoms/Card";

interface UsageDetailItem {
  keyName: string;
  keyValue: string;
  usage: number;
  count: number;
}

interface UsageDetailCardProps {
  usageDetail: Record<string, UsageDetailItem>;
  usageDetailGroupMode: string[];
  setUsageDetailGroupMode: (mode: string[]) => void;
  groupModes: string[][];
}

const UsageDetailCard: React.FC<UsageDetailCardProps> = ({
  usageDetail,
  usageDetailGroupMode,
  setUsageDetailGroupMode,
  groupModes,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleModeChange = (mode: string[]) => {
    setUsageDetailGroupMode(mode);
  };

  function trimPath(path: string, sublength: number, length: number): string {
    let parts: string[] = path.split("/");
    parts = parts.map((part) => {
      if (part.length > sublength) {
        return part.substring(0, sublength) + "...";
      }
      return part;
    });
    let trimmedPath: string = parts.join("/");
    if (trimmedPath.length > length) {
      trimmedPath = trimmedPath.substring(0, length) + "...";
    }
    return trimmedPath;
  }

  const totalTokensUsed = Object.values(usageDetail || {}).reduce(
    (sum, item) => sum + item.usage,
    0
  );

  return (
    <Card className="mt-2 w-full" borderRequired>
      <div className="flex flex-col">
        <div className="flex justify-between items-center mb-1 relative">
          <div className="w-full absolute">
            <span className="text-md strong text-gray-500 font-extrabold text-center block">
              Period Usage Detail
            </span>
          </div>
          <div className="w-full flex justify-end">
            <button
              onClick={toggleExpand}
              className="text-sm font-extrabold pr-6 text-indigo-600 hover:text-indigo-800 z-10"
            >
              {isExpanded ? "Hide" : "Show"}
            </button>
          </div>
        </div>
        {isExpanded && (
          <>
            <div className="flex justify-center space-x-2 mb-4 pt-4">
              {groupModes.map((mode, index) => (
                <button
                  key={index}
                  onClick={() => handleModeChange(mode)}
                  className={`px-3 py-1 text-sm font-medium rounded-full ${
                    usageDetailGroupMode[1] === mode[1]
                      ? "bg-indigo-600 text-white"
                      : "bg-indigo-100 text-indigo-700 hover:bg-gray-300"
                  }`}
                >
                  {mode[0]}
                </button>
              ))}
            </div>
            <div className="max-h-96 w-full overflow-y-auto grid grid-cols-12 auto-rows-auto gap-3">
              <div className="p-0 border border-gray-200 rounded-lg col-span-10">
                <table className="min-w-full  rounded-lg   col-span-10">
                  <thead className="bg-gray-50 rounded-lg ">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 px-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
                      >
                        {usageDetailGroupMode[0]}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
                      >
                        Count
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
                      >
                        Tokens Used
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Object.values(usageDetail || {}).map(
                      (item: UsageDetailItem, index: number) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                            {trimPath(item.keyValue, 16, 120)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                            {item.count.toLocaleString("en-US")}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                            {item.usage.toLocaleString("en-US")}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-span-2 ">
                <div className="w-full h-full p-6 bg-white rounded-lg border border-gray-200 flex-col justify-center items-center gap-1 inline-flex">
                  <div className="self-stretch text-center text-gray-500 text-sm font-medium font-inter leading-tight">
                    Total Tokens Used
                  </div>
                  <div className="self-stretch text-center text-indigo-600 text-3xl font-semibold font-inter leading-9">
                    {totalTokensUsed.toLocaleString()}
                  </div>
                </div>
              </div>
              <div className="w-full col-span-8 col-start-3 mt-2">
                <Plot
                  data={[
                    {
                      type: "pie" as const,
                      labels: Object.keys(usageDetail),
                      values: Object.values(usageDetail).map(
                        (detail: any) => detail.usage
                      ),
                      text: Object.values(usageDetail).map(
                        (detail: any) => `${detail.count} calls`
                      ),
                      // hoverinfo: "label+percent+text" as const,
                      textinfo: "value" as const,
                    },
                  ]}
                  layout={{
                    title: "Usage by Group",
                    font: { size: 10 },
                    autosize: true,
                    height: 300,
                    margin: { l: 30, r: 30, t: 40, b: 20 },
                  }}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "300px" }}
                  config={{
                    displayModeBar: false,
                    responsive: true,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default UsageDetailCard;
