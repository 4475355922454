import ReactPaginate from "react-paginate";

type TablePaginationProps = {
  handlePageChange: (selectedItem: { selected: number }) => void;
  lastPage: number;
  page: number;
  containerClassNames?: string;
};

const TablePagination = ({
  handlePageChange,
  lastPage,
  page,
  containerClassNames = "",
}: TablePaginationProps) => {
  return (
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      pageCount={lastPage}
      onPageChange={handlePageChange}
      forcePage={page - 1}
      containerClassName={`flex justify-center ${containerClassNames}`}
      pageClassName="mx-4 text-passio-font hover:text-passio-lightBlue text-sm"
      previousClassName={
        page === 1
          ? "mx-4 pointer-events-none cursor-auto text-passio-disabled text-sm"
          : "mx-4 text-passio-font text-sm hover:text-passio-lightBlue"
      }
      nextClassName={
        lastPage === page
          ? "mx-4 pointer-events-none cursor-auto text-passio-disabled text-sm"
          : "mx-4 text-passio-font text-sm hover:text-passio-lightBlue"
      }
      activeLinkClassName="border-passio-lightBlue text-passio-lightBlue text-sm"
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
    />
  );
};

export default TablePagination;
