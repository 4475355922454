import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router-dom";

import Loading from "../Loading";

import { eventFromProductPlanSlug, isValidProduct } from "utils/products";

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const productPlanSlug = params.get("product");

  useEffect(() => {
    loginWithRedirect({
      appState: { returnTo: "/" },
      authorizationParams: { screen_hint: "signup" },
    });

    ReactGA.event(eventFromProductPlanSlug("begin_signup", productPlanSlug));
  }, [loginWithRedirect, productPlanSlug]);

  // Product is not necessary for SignUp, but if passed, it must be correct
  if (productPlanSlug && !isValidProduct(productPlanSlug)) {
    return <Navigate to="/not-found" />;
  }

  return <Loading />;
};
export default SignUp;
