import { useAuth0 } from "@auth0/auth0-react";

import MessageBlock from "components/organisms/MessageBlock";

const marketingBase = process.env.REACT_APP_MARKETING_BASE as string;

const AccessRestricted = () => {
  const { logout } = useAuth0();

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: marketingBase } });

  return (
    <MessageBlock
      errorCode="403 ERROR"
      headline="Access Restricted"
      content="You must be the account owner."
      onClick={handleLogout}
    />
  );
};

export default AccessRestricted;
