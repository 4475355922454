export const NUTRITION_AI = "Nutrition AI";
export const NUTRITION_AI_SLUG = "nutrition-ai";
export const NUTRITION_AI_LOW_SLUG = "nutrition-ai-low";
export const NUTRITION_AI_MID_SLUG = "nutrition-ai-mid";
export const NUTRITION_AI_HIGH_SLUG = "nutrition-ai-high";

export const NUTRITION_API = "Nutrition API";
export const NUTRITION_API_SLUG = "nutrition-api";
export const NUTRITION_API_LOW_SLUG = "nutrition-api-low";
export const NUTRITION_API_MID_SLUG = "nutrition-api-mid";

export const NUTRITION_ADVISOR = "Nutrition Advisor";
export const NUTRITION_ADVISOR_SLUG = "nutrition-advisor";
export const NUTRITION_ADVISOR_LOW_SLUG = "nutrition-advisor-low";
export const NUTRITION_ADVISOR_MID_SLUG = "nutrition-advisor-mid";

export const REMODEL_AI = "Remodel AI";
export const REMODEL_AI_SLUG = "remodel-ai";
export const REMODEL_AI_LOW_SLUG = "remodel-ai-low";
export const REMODEL_AI_MID_SLUG = "remodel-ai-mid";

export const VR_SHOWROOM = "VR Showroom";
export const VR_SHOWROOM_SLUG = "vr-showroom";
export const VR_SHOWROOM_LOW_SLUG = "vr-showroom-low";

export const MOBILE_AI = "Mobile AI";
export const MOBILE_AI_SLUG = "mobile-ai";
export const MOBILE_AI_FREE_SLUG = "mobile-ai-free";
export const MOBILE_AI_PRO_SLUG = "mobile-ai-pro";

export const PAINTS_AI = "Paints AI";

export const PASSIO_HUB = "Unified";
export const PASSIO_HUB_SLUG = "unified";

const productNameSlugLookup: { [slug: string]: string } = {
  [NUTRITION_API_LOW_SLUG]: NUTRITION_API_SLUG,
  [NUTRITION_API_MID_SLUG]: NUTRITION_API_SLUG,
  [NUTRITION_ADVISOR_LOW_SLUG]: NUTRITION_ADVISOR_SLUG,
  [NUTRITION_ADVISOR_MID_SLUG]: NUTRITION_ADVISOR_SLUG,
  [REMODEL_AI_LOW_SLUG]: REMODEL_AI_SLUG,
  [REMODEL_AI_MID_SLUG]: REMODEL_AI_SLUG,
  [VR_SHOWROOM_LOW_SLUG]: VR_SHOWROOM_SLUG,
  [NUTRITION_AI_LOW_SLUG]: NUTRITION_AI_SLUG,
  [NUTRITION_AI_MID_SLUG]: NUTRITION_AI_SLUG,
  [NUTRITION_AI_HIGH_SLUG]: NUTRITION_AI_SLUG,
  [MOBILE_AI_FREE_SLUG]: MOBILE_AI_SLUG,
  [MOBILE_AI_PRO_SLUG]: MOBILE_AI_SLUG,
  [MOBILE_AI_SLUG]: MOBILE_AI_SLUG,
  [PASSIO_HUB_SLUG]: PASSIO_HUB,
};

const productSlugLookup: { [slug: string]: string } = {
  [NUTRITION_API_LOW_SLUG]: NUTRITION_API,
  [NUTRITION_API_MID_SLUG]: NUTRITION_API,
  [NUTRITION_ADVISOR_LOW_SLUG]: NUTRITION_ADVISOR,
  [NUTRITION_ADVISOR_MID_SLUG]: NUTRITION_ADVISOR,
  [REMODEL_AI_LOW_SLUG]: REMODEL_AI,
  [REMODEL_AI_MID_SLUG]: REMODEL_AI,
  [VR_SHOWROOM_LOW_SLUG]: VR_SHOWROOM,
  [NUTRITION_AI_LOW_SLUG]: NUTRITION_AI,
  [NUTRITION_AI_MID_SLUG]: NUTRITION_AI,
  [NUTRITION_AI_HIGH_SLUG]: NUTRITION_AI,
  [MOBILE_AI_FREE_SLUG]: MOBILE_AI,
  [MOBILE_AI_PRO_SLUG]: MOBILE_AI,
  [MOBILE_AI_SLUG]: MOBILE_AI,
  [PASSIO_HUB_SLUG]: PASSIO_HUB,
};

const isValidProduct = (productSlug: string | null): boolean => {
  switch (productSlug) {
    case NUTRITION_AI_SLUG:
      return true;
    case NUTRITION_AI_LOW_SLUG:
      return true;
    case NUTRITION_AI_MID_SLUG:
      return true;
    case NUTRITION_AI_HIGH_SLUG:
      return true;
    case NUTRITION_API_SLUG:
      return true;
    case NUTRITION_API_LOW_SLUG:
      return true;
    case NUTRITION_API_MID_SLUG:
      return true;
    case NUTRITION_ADVISOR_SLUG:
      return true;
    case NUTRITION_ADVISOR_LOW_SLUG:
      return true;
    case NUTRITION_ADVISOR_MID_SLUG:
      return true;
    case REMODEL_AI_SLUG:
      return true;
    case REMODEL_AI_LOW_SLUG:
      return true;
    case REMODEL_AI_MID_SLUG:
      return true;
    case VR_SHOWROOM_LOW_SLUG:
      return true;
    case VR_SHOWROOM_SLUG:
      return true;
    case MOBILE_AI_SLUG:
      return true;
    case MOBILE_AI_FREE_SLUG:
      return true;
    case MOBILE_AI_PRO_SLUG:
      return true;
    case PASSIO_HUB_SLUG:
      return true;
  }

  return false;
};

const productNameFromSlug = (slug: string | null): string => {
  if (!slug) {
    return "";
  }
  return productSlugLookup[slug];
};

const productNameSlugFromProductPlanSlug = (slug: string | null): string => {
  if (!slug) {
    return "";
  }
  const n = productNameSlugLookup[slug];
  if (n === PASSIO_HUB_SLUG) {
    return "nutrition-hub";
  }
  if (n === undefined) {
    return slug;
  }
  return n;
};

const uniqueEventId = (): string => {
  return `${Math.random().toString(36).substring(2, 15)}`;
};

const eventFromProductPlanSlug = (
  event: string,
  slug: string | null
): string => {
  if (!slug) {
    return event;
  }
  return `${event}_${slug.replaceAll("-", "_")}`;
};

export {
  isValidProduct,
  productNameFromSlug,
  productNameSlugFromProductPlanSlug,
  eventFromProductPlanSlug,
  uniqueEventId,
};
