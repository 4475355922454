import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <div className="flex justify-center items-center h-full bg-gradient-to-r from-passio-primary to-passio-blue">
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
