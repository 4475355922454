import { useAuth0 } from "@auth0/auth0-react";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import LogRocket from "logrocket";
import { Fragment, useEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";

import AuthenticationGuard from "components/atoms/AuthenticationGuard";
import ScrollToTop from "components/atoms/ScrollToTop";
import AuthenticatedLayout from "components/organisms/AuthenticatedLayout";
import PublicLayout from "components/organisms/PublicLayout";
import AccessRestricted from "components/pages/AccessRestricted";
import Billing from "components/pages/Billing";
import Callback from "components/pages/Callback";
import Dashboard from "components/pages/Dashboard";
import Details from "components/pages/Details";
import ManageSubscription from "components/pages/ManageSubscription";
import PageNotFound from "components/pages/PageNotFound";
import SignUp from "components/pages/SignUp";
import Team from "components/pages/Team";
import Unauthorized from "components/pages/Unauthorized";
import Welcome from "components/pages/Welcome";

LogRocket.init(process.env.REACT_APP_LOGROCKET_ID as string);

// Google Analytics event tracking
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);

const App = () => {
  const { user } = useAuth0();
  // Identify user to LaunchDarkly and set it as the active user.
  const ldClient = useLDClient();
  useEffect(() => {
    if (!user) {
      ldClient?.identify({ anonymous: true });
      return;
    }
    ldClient?.identify({ key: user.email, email: user.email });
  }, [ldClient, user]);

  return (
    <Fragment>
      <ScrollToTop>
        <Routes>
          <Route element={<AuthenticatedLayout />}>
            <Route
              path="/"
              element={<AuthenticationGuard component={Dashboard} />}
            />
            <Route
              path="/details"
              element={<AuthenticationGuard component={Details} />}
            />
            <Route
              path="/manage-subscription"
              element={<AuthenticationGuard component={ManageSubscription} />}
            />
            <Route
              path="/billing"
              element={<AuthenticationGuard component={Billing} />}
            />
            <Route
              path="/team"
              element={<AuthenticationGuard component={Team} />}
            />
          </Route>
          <Route element={<PublicLayout />}>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/access-restricted" element={<AccessRestricted />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/not-found" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="/callback" element={<Callback />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </ScrollToTop>
    </Fragment>
  );
};

// export default App;
export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID as string,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App);
