import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

import Loading from "components/pages/Loading";

interface AuthenticationGuardProps {
  component: React.ComponentType<any>;
}

const AuthenticationGuard = ({ component }: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};

export default AuthenticationGuard;
