import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useState } from "react";

import Button from "components/atoms/Button";
import Spinner from "components/atoms/Spinner";

import { error, success } from "utils";

interface PaymentMethodElementProps {
  buttonText: string;
  paymentCompleteCB: () => void;
}

const PaymentMethodElement = ({
  buttonText,
  paymentCompleteCB,
}: PaymentMethodElementProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const updatePaymentMethod = useMutation(
    async (paymentMethod: PaymentMethod) => {
      const { data } = await axios.put(`/accounts/billing/paymentMethod`, {
        paymentMethodID: paymentMethod.id,
      });
      return data;
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
        setIsLoading(false);
      },
      onSuccess: () => {
        success("Payment method successfully updated");
        // When the payment method has been updated, create the subscription
        paymentCompleteCB();
      },
    }
  );

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      error(submitError.message || "There was an error submitting the form");
      setIsLoading(false);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const { error: createPaymentError, paymentMethod } =
      await stripe.createPaymentMethod({ elements });

    if (createPaymentError) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      error(
        createPaymentError.message ||
          "There was an error updating the payment method"
      );
      setIsLoading(false);
      return;
    }

    // Now that you have a PaymentMethod, you can use it in the following steps to render a confirmation page or run additional validations on the server
    updatePaymentMethod.mutate(paymentMethod);
  };

  return (
    <form id="payment-method-form" onSubmit={handleSubmit}>
      {(!stripe || !elements) && (
        <div>
          <Spinner className="!h-auto" />
        </div>
      )}
      <PaymentElement id="payment-method-element" />

      <Button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
        formId="payment-method-form"
        className="mt-6"
        fullWidth
      >
        <span id="button-text">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Spinner className="!w-4 !h-4 !text-white" />
              {buttonText}
            </div>
          ) : (
            buttonText
          )}
        </span>
      </Button>
    </form>
  );
};

export default PaymentMethodElement;
