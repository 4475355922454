import React from "react";

import { classNames } from "utils";

interface CardProps {
  children?: React.ReactNode;
  className?: string;
  borderRequired?: boolean;
}

const Card = ({
  children,
  className = "",
  borderRequired = false,
}: CardProps) => {
  return (
    <div
      className={classNames(
        "border-b border-gray-200 bg-white p-4 rounded-passio-small shadow rounded-md flex flex-col",
        borderRequired ? "border border-gray-100" : "",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
