import {
  ArrowTopRightOnSquareIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import {
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router-dom";

import UserContext from "context/UserContext";

import Badge from "components/atoms/Badge";
import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";
import PriceTableNutritionAdvisor from "components/molecules/PriceTables/PriceTableNutritionAdvisor";
import SlackSignUp from "components/molecules/SlackSignUp";

import { error } from "utils";
import { ProductDataType } from "utils/types";

const DetailsNutritionAdvisor = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.toLocaleString("default", { month: "long" });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  const [unauthorized, setUnauthorized] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const [hideKey, setHideKey] = useState(true);
  const [productState, setProductState] = useState<ProductDataType>({
    isSubscribed: false,
    trialEnd: null,
    billingDate: new Date(),
    licenseKey: null,
    usageInfo: null,
    tiers: [],
  });

  const userContext = useContext(UserContext);

  const getProduct = async () =>
    await axios.get(`/accounts/products/${productQuery}`);

  const { isLoading, isFetching, isPaused } = useQuery(
    ["product"],
    getProduct,
    {
      enabled: !!productQuery && !!userContext,
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setProductState((prev) => ({
          ...prev,
          isSubscribed: res.data?.isSubscribed,
          trialEnd: res.data?.trialEnd ? new Date(res.data?.trialEnd) : null,
          billingDate: new Date(res.data?.billingDate),
          licenseKey: res.data?.licenseKey,
          usageInfo: res.data?.usageInfo,
          tiers: res.data?.tiers || [],
        }));
      },
    }
  );

  async function copyTextToClipboard(text: string) {
    return await navigator.clipboard.writeText(text);
  }

  const handleCopyClick = () => {
    copyTextToClipboard(productState?.licenseKey ?? "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  // Google Adds Event Tracking
  useEffect(() => {
    // Function to get URL parameter
    const getQueryParam = (param: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };
    const encodedData = getQueryParam("event");
    if (encodedData) {
      const decodedString = atob(encodedData);
      try {
        const obj = JSON.parse(decodedString);
        ReactGA.event(obj);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, []);

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  if (!isFetching && !isLoading && !isPaused && !productState.isSubscribed) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
          Nutrition Advisor Product Details
        </h2>
      </div>
      <div className="grid lg:grid-cols-2 lg:auto-rows-fr gap-8">
        <Card className="sm:p-6">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-medium">Current Usage</p>
              <span className="text-sm text-gray-500">
                in {currentMonth} {currentYear}
              </span>
            </div>
            {!isFetching && !isLoading ? (
              productState.trialEnd ? (
                <Badge isWarning>
                  <ClockIcon
                    className="h-5 w-5 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Free Trial Ends on{" "}
                  {productState.trialEnd.toLocaleDateString()}
                </Badge>
              ) : (
                <Badge>
                  <ClockIcon
                    className="h-5 w-5 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Billed Next on {productState.billingDate.toLocaleDateString()}
                </Badge>
              )
            ) : (
              <></>
            )}
            {/* TODO: Put historical usage here later  */}
            {/* <span className="text-passio-indigo text-base">
              See historical usage
            </span> */}
          </div>
          <div className="grid grid-cols-3 gap-4 mt-4 h-full">
            <Card
              className="flex justify-center items-center col-span-1"
              borderRequired
            >
              <div className="flex flex-col items-center">
                <span className="text-sm text-gray-500 text-center mb-1 font-medium">
                  Current Usage
                </span>
                <div className="text-passio-indigo text-xl font-bold">
                  {isLoading || isFetching ? (
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={100} className="text-center">
                            <Spinner className="!w-[2rem]" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : productState.usageInfo?.units ? (
                    productState.usageInfo?.units.toLocaleString()
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </Card>
            <div className="col-span-2">
              <PriceTableNutritionAdvisor
                isLoading={isLoading || isFetching}
                tiers={productState.tiers}
                showDescription={true}
              />
            </div>
          </div>
        </Card>
        <Card className="sm:p-6">
          <div className="flex flex-col">
            <p className="font-medium">API Key & Info</p>
          </div>

          <div className="mt-6">
            <dt className="text-sm font-medium text-gray-500">API Key</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <KeyIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      {hideKey
                        ? Array.from({
                            length: productState?.licenseKey?.length || 0,
                          }).map((_, index) => (
                            <span key={index}>•</span> // Customize dot size with Tailwind classes
                          ))
                        : productState?.licenseKey ?? ""}
                    </span>
                  </div>
                  <div className="ml-4 flex flex-row flex-shrink items-center space-x-2">
                    {isCopied ? (
                      <ClipboardDocumentCheckIcon
                        className="h-5 w-5 flex-shrink-0 text-green-700"
                        aria-hidden="true"
                      />
                    ) : (
                      <DocumentDuplicateIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={handleCopyClick}
                      />
                    )}
                    {hideKey ? (
                      <EyeSlashIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={() => setHideKey(!hideKey)}
                      ></EyeSlashIcon>
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={() => setHideKey(!hideKey)}
                      ></EyeIcon>
                    )}
                  </div>
                </li>
              </ul>
              <p className="text-gray-500 text-sm ml-4 mt-1">
                * Keep your API Key safe - see the best practices in the
                documentation.
              </p>
            </dd>
          </div>

          <div className="mt-auto pt-8">
            <dt className="text-sm font-medium text-gray-500">Links</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      <a
                        href={`https://passio-nutrition-advisor-${
                          process.env.REACT_APP_ENV === "development"
                            ? "dev"
                            : "demo"
                        }.storage.googleapis.com/index.html?licenseKey=${btoa(
                          productState?.licenseKey || ""
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Try Your Advisor!
                      </a>
                    </span>
                  </div>
                </li>
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      <a
                        href={
                          "https://github.com/Passiolife/nutrition-advisor-web-example"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        ReactJS Demo Project GitHub
                      </a>
                    </span>
                  </div>
                </li>

                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      <a
                        href={
                          "https://www.npmjs.com/package/passio-nutrition-advisor-client"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        npm Nutrition Advisor Client Package (Typed)
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
          <div className="mt-auto pt-8">
            <dt className="text-sm font-medium text-gray-500">
              Documentation and Guides
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      <a
                        href={"https://passio.gitbook.io/nutrition-advisor-api"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Guide & Documentation Overview
                      </a>
                    </span>
                  </div>
                </li>
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      <a
                        href={
                          "https://api.passiolife.com/v2/token-cache/nutrition-advisor/docs"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Authorization Route Documentation
                      </a>
                    </span>
                  </div>
                </li>
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      <a
                        href={
                          "https://api.passiolife.com/v2/products/nutrition-advisor/docs"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        REST OpenAPI Documentation
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </Card>
        <Card className="sm:p-6">
          <div>
            <p className="font-medium">Support & Documentation</p>
            <span className="text-sm text-gray-500">
              Links for additional support
            </span>
          </div>

          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Documentation</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio.gitbook.io/nutrition-advisor"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio.gitbook.io/nutrition-advisor
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Email Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="mailto:support@passiolife.com"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                support@passiolife.com
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Slack Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio-community.slack.com/channels/help"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio-community.slack.com/channels/help
              </a>
            </dd>
          </div>
        </Card>
        <SlackSignUp />
      </div>
    </>
  );
};

export default DetailsNutritionAdvisor;
