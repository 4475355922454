import { useAuth0 } from "@auth0/auth0-react";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  InvalidateQueryFilters,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";

import UserContext from "context/UserContext";

import Badge from "components/atoms/Badge";
import Breadcrumb from "components/atoms/Breadcrumb";
import Card from "components/atoms/Card";
import Container from "components/atoms/Container";
import SearchQuery from "components/atoms/SearchQuery";
import Spinner from "components/atoms/Spinner";
import ConfirmationModal from "components/molecules/ConfirmationModal";
import NavHeader from "components/organisms/NavHeader";
import InviteUserModal from "components/organisms/Team/InviteUserModal";
import UserTable from "components/organisms/Team/UserTable";

import { error, success, userHasAdminRole } from "utils";

const Team = () => {
  const queryClient = useQueryClient();
  const { user, logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const [isIdle, setIsIdle] = useState<boolean>(true);
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [userToResend, setUserToResend] = useState<any>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmResendOpen, setConfirmResendOpen] = useState(false);
  const [newRole, setNewRole] = useState({ name: "Add role" });
  const userContext = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [usageUserCount, setUsageUserCount] = useState(0);
  const [quotaUserCount, setQuotaUserCount] = useState(0);
  //Tour state data
  const teamOverviewRef = useRef(null);
  const teamInviteRef = useRef(null);

  const pageList = [
    {
      label: "All Products",
      path: "/",
    },
    {
      label: "Team",
      path: "/team",
    },
  ];

  const URL = `/projects_api/management/customers/${userContext?.customerid}/users`;

  const quotaReached = quotaUserCount !== 0 && usageUserCount >= quotaUserCount;

  const { data, isFetching } = useQuery(
    ["users", userContext, page, search],
    async () => {
      const userUrl =
        search !== ""
          ? `${URL}?pageSize=10&page=${page}&orderBy=name&descending=false&q=${encodeURIComponent(
              search
            )}`
          : `${URL}?pageSize=10&page=${page}&orderBy=name&descending=false`;
      const { data } = await axios.get(userUrl);
      setIsIdle(false);
      return data;
    },
    {
      enabled: !!userContext && !!userContext.customerid,
      onError: (err: any) => {
        error(err?.response?.data?.cause || "Could not get users.");
      },
    }
  );

  const { data: customer } = useQuery(
    ["customer"],
    async () => {
      const { data } = await axios.get("/projects_api/customer");
      return data;
    },
    {
      enabled: !!userContext && !!userContext.customerid,

      onError: (err: any) => {
        error(err?.response?.data?.cause || "Could not get users.");
      },
    }
  );

  const { data: userRoles } = useQuery(
    ["userRoles"],
    async () => {
      const { data } = await axios.get("/projects_api/userRoles");
      data.user_roles = data.user_roles.filter((el: any) => {
        return el.name === "Admin";
      });
      return data;
    },
    {
      enabled: !!userContext && !!userContext.customerid,

      onError: (err: any) => {
        error(err?.response?.data?.cause || "Could not get users.");
      },
    }
  );

  const deleteUserMutation = useMutation(
    ({ email }) => {
      return axios.delete(`/projects_api/customers/users/${email}`);
    },
    {
      onError: (err: any, variables: any) => {
        error(
          `Could not delete user with email ${variables.email}. ${
            err?.response?.data?.cause || ""
          }`
        );
      },
      onSettled: () =>
        queryClient.invalidateQueries(
          "users" as InvalidateQueryFilters<unknown>
        ),
      onSuccess: (_, variables) => {
        setConfirmDeleteOpen(false);
        success(`User ${variables.email} successfully deleted.`);
      },
    }
  );

  const deleteUserRoleMutation = useMutation(
    ({ email, roleId }) => {
      return axios.delete(
        `/projects_api/customers/users/${email}/roles/${roleId}`
      );
    },
    {
      onError: (err: any) => {
        error(
          `Could not delete user role. ${err?.response?.data?.cause || ""}`
        );
      },
      onSuccess: (_: any, variables: any) => {
        success(`Role successfully deleted.`);
        const user = data.entities.find(
          (item: any) => item.email === variables.email
        );
        user.user_roles = user.user_roles.filter(
          (item: any) => item.id !== variables.roleId
        );
        queryClient.setQueryData(["users"] as QueryKey, { ...data });
      },
    }
  );

  const inviteUserMutation = useMutation(
    ({ email, user_roles }) => {
      return axios.post(`/projects_api/customers/users/${email}/invite`, {
        user_roles,
      });
    },
    {
      onError: (err: any, variables: any) => {
        error(
          err?.response?.data?.cause ||
            `Could not invite user with email ${variables.email}.`
        );
      },
      onSettled: () =>
        queryClient.invalidateQueries(
          "users" as InvalidateQueryFilters<unknown>
        ),
      onSuccess: (data, variables) => {
        success(`Email invite sent to ${variables.email}.`);
      },
    }
  );

  const resendInviteMutation = useMutation(
    ({ email }) => {
      return axios.post(`/projects_api/customers/users/${email}/invite/resend`);
    },
    {
      onError: (err: any, variables: any) => {
        error(
          err?.response?.data?.cause ||
            `Could not resend invite to user with email ${variables.email}.`
        );
      },
      onSettled: () =>
        queryClient.invalidateQueries(
          "users" as InvalidateQueryFilters<unknown>
        ),
      onSuccess: (data, variables) => {
        setConfirmResendOpen(false);
        success(`Email invite resent to ${variables.email}.`);
      },
    }
  );

  const addUserRoleMutation = useMutation(
    ({ email, roleId }) => {
      return axios.post(
        `/projects_api/customers/users/${email}/roles/${roleId}`
      );
    },
    {
      onError: (err: any, variables: any) => {
        error(
          err?.response?.data?.cause ||
            `Could not add user role ${variables.roleId} to user ${variables.email}.
         `
        );
      },
      onSuccess: (_, variables) => {
        success(
          `User role ${variables.name} added to user ${variables.email}.`
        );
        const user = data.entities.find(
          (item: any) => item.email === variables.email
        );
        user.user_roles = [...user.user_roles, newRole];
        queryClient.setQueryData(["users"] as QueryKey, { ...data });
        setNewRole({ name: "Add role" });
      },
    }
  );

  const handleDelete = (e: any) => {
    e.preventDefault();
    if (!userToDelete) {
      return error("No user selected for deletion.");
    }
    deleteUserMutation.mutate({ email: userToDelete.email });
    if (userToDelete.email === user?.email) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  const handleDeleteRole = (email: string, roleId: string) => {
    if (!email) {
      return error("Could not find user.");
    }
    if (!roleId) {
      return error("No role selected for deletion.");
    }
    deleteUserRoleMutation.mutate({ email, roleId });
  };

  const handleResendInvite = (e: any) => {
    e.preventDefault();
    if (!userToResend) {
      return error("No user selected for invite resend.");
    }
    resendInviteMutation.mutate({ email: userToResend?.email });
  };

  const addNewUserRole = (
    e: Event,
    email: string,
    roleId: string,
    name: string
  ) => {
    e.preventDefault();
    addUserRoleMutation.mutate({ email, roleId, name });
  };

  const CustomerUsage = async () => {
    try {
      if (
        !userContext ||
        !userContext.customerid ||
        userContext.customerid.length === 0
      )
        return;
      const resp = await axios.get(`projects_api/customers/usage/users`);
      setQuotaUserCount(resp?.data?.userQuota);
      setUsageUserCount(resp?.data?.users);
    } catch (err: any) {
      error(err.message || "Something went wrong.");
    }
  };

  const createUserQuotaText = (current: number, quota: number) => {
    const qotaString = quota ? quota : "unlimited";
    return `Organization Team: ${current} / ${qotaString}`;
  };

  useEffect(() => {
    if (!userContext) return;
    CustomerUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, userContext]);

  if (isIdle || !userContext || !userRoles || !customer) return <Spinner />;

  return (
    <>
      <NavHeader>
        <Container>
          <Breadcrumb pageList={pageList} />
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-bold text-md md:text-lg xl:text-3xl">Team</h2>
          </div>
          <div className="mt-8 grid lg:grid-cols-1 lg:auto-rows-fr gap-8">
            <Card className="p-4 sm:p-6">
              <div className="p-8">
                <div className="flex justify-between items-end mb-4">
                  <div className="flex flex-row">
                    {userHasAdminRole(userContext?.roles || []) && (
                      <button
                        ref={teamInviteRef}
                        onClick={() => setOpen(true)}
                        type="button"
                        disabled={!userHasAdminRole(userContext?.roles || [])}
                        className="inline-flex items-center mr-4 px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-passio-purple hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-purple"
                      >
                        <PlusIcon
                          className="ml-1 mr-3 h-4 w-4"
                          aria-hidden="true"
                        />
                        Invite
                      </button>
                    )}
                    <Badge isWarning={quotaReached}>
                      {createUserQuotaText(usageUserCount, quotaUserCount)}
                    </Badge>
                  </div>
                  <SearchQuery
                    search={search}
                    setSearch={(text) => {
                      setPage(1);
                      setSearch(text);
                    }}
                    placeholder="Search Team"
                    width={undefined}
                  />
                </div>

                {deleteUserRoleMutation.isLoading ||
                deleteUserMutation.isLoading ? (
                  <div className="flex justify-center items-center">
                    <div
                      style={{ borderTopColor: "transparent" }}
                      className="w-8 h-8 p-4 m-4 border-4 border-passio-lightBlue border-solid rounded-full animate-spin"
                    />
                  </div>
                ) : null}
                {isFetching && !data ? (
                  <Spinner className="flex items-center justify-center mx-4 h-[400px]" />
                ) : (
                  <>
                    {data && userRoles && userContext ? (
                      <>
                        <UserTable
                          innerRef={teamOverviewRef}
                          userData={data}
                          setPage={setPage}
                          addUserRole={addNewUserRole}
                          addUserRoleMutation={addUserRoleMutation}
                          userRoles={userRoles}
                          setUserToDelete={setUserToDelete}
                          handleDeleteUserRole={handleDeleteRole}
                          setUserToResend={setUserToResend}
                          newRole={newRole}
                          setNewRole={setNewRole}
                          setConfirmResendOpen={setConfirmResendOpen}
                          setConfirmDeleteOpen={setConfirmDeleteOpen}
                          customerOwnerEmail={customer?.ownerEmail}
                          isFetching={isFetching}
                        />
                        <InviteUserModal
                          inviteUser={inviteUserMutation}
                          open={open}
                          handleClose={() => setOpen(false)}
                          userRoles={userRoles}
                        />
                        <ConfirmationModal
                          modalIsOpen={confirmDeleteOpen}
                          closeModal={() => setConfirmDeleteOpen(false)}
                          cancel={() => setConfirmDeleteOpen(false)}
                          confirmationText={`Are you sure you want to delete user with email ${userToDelete?.email}?`}
                          buttonText="Confirm Delete"
                          headerText="Confirm delete"
                          handleSubmit={handleDelete}
                          isSubmitting={deleteUserMutation.isLoading}
                        />
                        <ConfirmationModal
                          modalIsOpen={confirmResendOpen}
                          closeModal={() => setConfirmResendOpen(false)}
                          cancel={() => setConfirmResendOpen(false)}
                          confirmationText={`Confirm that you would like to resend the invite email to ${userToResend?.email}?`}
                          buttonText={"Send"}
                          headerText="Resend Invite"
                          handleSubmit={handleResendInvite}
                          isSubmitting={resendInviteMutation.isLoading}
                        />
                      </>
                    ) : (
                      <p className="text-center my-5 py-2 font-bold text-lg">
                        No users found
                      </p>
                    )}
                  </>
                )}
              </div>
            </Card>
          </div>
        </Container>
      </NavHeader>
    </>
  );
};

export default Team;
