import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../Loading";

// Callback is the destination for Auth0's redirect after authentication
// If there's an error, we'll redirect to the appropriate page, otherwise
// we'll redirect based on the login call.
const Callback = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [useFallback, setUseFallback] = useState(false);

  // searchParams.forEach((value, key) => console.log(key, value));

  useEffect(() => {
    // A denied auth from Auth0 will have error "access_denied"
    if (searchParams.get("error") === "access_denied") {
      const description = searchParams.get("error_description") || "";

      // Clear all search params by instantiating with empty URLSearchParams
      setSearchParams(new URLSearchParams());

      // If the error description includes "please verify", then the user has not verified their email
      // We send this denial in an Auth0 Login action.
      if (description.toLowerCase().includes("please verify")) {
        // Navigate to the welcome page to remind the user to verify
        navigate({ pathname: "/welcome" }, { replace: true });
        return;
      } else {
        // Navigate to the unauthorized page with the cause of the auth failure
        navigate(
          { pathname: "/unauthorized", search: `cause=${description}` },
          { replace: true }
        );
        return;
      }
    }

    // If we're returning from Auth0 with success, clear search params and redirect to home
    if (searchParams.get("success") === "true") {
      navigate({ pathname: "/" }, { replace: true });
      setSearchParams(new URLSearchParams());
      return;
    }

    // If we've hit the callback page without query params from Auth0, just navigate to home
    setUseFallback(true);
  }, [navigate, searchParams, setSearchParams]);

  if (useFallback) {
    return <Navigate to="/" replace />;
  }

  return <Loading />;
};
export default Callback;
