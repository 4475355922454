import React from "react";

import { classNames } from "utils";

interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
}

const Container = ({ children, className = "" }: ContainerProps) => {
  return (
    <div className={classNames("p-4 sm:p-8 max-w-[1350px] mx-auto", className)}>
      {children}
    </div>
  );
};

export default Container;
