import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

import MessageBlock from "components/organisms/MessageBlock";

const marketingBase = process.env.REACT_APP_MARKETING_BASE as string;

const Unauthorized = () => {
  const { logout } = useAuth0();
  const [searchParams] = useSearchParams();
  const cause =
    searchParams.get("cause") || "Sorry, we couldn't authorize your account.";

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: marketingBase } });

  return (
    <MessageBlock
      errorCode="401 ERROR"
      headline="Unauthorized"
      content={cause}
      onClick={handleLogout}
    />
  );
};

export default Unauthorized;
