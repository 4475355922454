import SlackImg from "images/slack-graphic.png";

const SLACK_SIGNUP_LINK =
  "https://join.slack.com/t/passio-community/shared_invite/zt-1ge2wpifb-FHhXT61e80gOPy~tzjX3Ng";

const SlackSignUp = () => {
  return (
    <div>
      <a href={SLACK_SIGNUP_LINK} target="_blank" rel="noopener noreferrer">
        <img
          src={SlackImg}
          alt="Slack Sign Up"
          className="object-fill h-full rounded-md w-full"
        />
      </a>
    </div>
  );
};

export default SlackSignUp;
