/* eslint-disable react/no-array-index-key */
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import React from "react";

type SearchQueryProps = {
  search: string;
  setSearch: (search: string) => void;
  width?: string;
  placeholder: string;
};

function SearchQuery({
  search,
  setSearch,
  placeholder,
  width,
}: SearchQueryProps) {
  const [text, setText] = React.useState(search || "");

  const onSubmit = (e: any) => {
    e.preventDefault();
    setSearch(text);
  };

  return (
    <form onSubmit={onSubmit}>
      <label className="relative text-passio-dataTableTextColor focus-within:text-passio-primary block">
        <div className={`mt-1 ${width ? width : ""}`}>
          <MagnifyingGlassIcon className="pointer-events-none w-4 h-4 absolute top-1/2 transform -translate-y-1/2 left-3" />
          <input
            value={text}
            placeholder={placeholder}
            onChange={(e) => {
              setText(e.target.value);
            }}
            style={{ width: width ? "100%" : "15rem" }}
            className="form-input text-sm pl-10 py-3 px-4 w-full block bg-white rounded-passio border border-passio-inputBorder placeholder-text-passio-lightGray text-passio-dataTableTextColor appearance-none focus:outline-none"
          />
        </div>
      </label>
    </form>
  );
}

SearchQuery.propTypes = {
  title: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
};

SearchQuery.defaultProps = {
  title: "",
};

export default SearchQuery;
