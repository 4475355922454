import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { error } from "logrocket";
import { useState } from "react";
import { Navigate } from "react-router-dom";

import Breadcrumb from "components/atoms/Breadcrumb";
import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import PaymentMethodCard from "components/atoms/CardPaymentMethod";
import Container from "components/atoms/Container";
import Spinner from "components/atoms/Spinner";
import NavHeader from "components/organisms/NavHeader";
import PaymentMethodModal, {
  PaymentMethodModalType,
} from "components/organisms/PaymentMethodModal";

const pageList = [
  {
    label: "All Products",
    path: "/",
  },
  {
    label: "Billing",
    path: "/billing",
  },
];

export interface PaymentMethodDataType {
  hasSubscribed: boolean;
  brand: string;
  expiration: string;
  lastFour: string;
}

const Billing = () => {
  const [unauthorized, setUnauthorized] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodDataType>({
    hasSubscribed: false,
    brand: "",
    expiration: "",
    lastFour: "",
  });
  const [paymentMethodModal, setPaymentMethodModal] =
    useState<PaymentMethodModalType>({ isOpen: false });

  const getProduct = async () =>
    await axios.get(`/accounts/billing/paymentMethod`);

  const { isLoading, isFetching, refetch } = useQuery(
    ["paymentMethods"],
    getProduct,
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setPaymentMethod((prev) => ({
          ...prev,
          hasSubscribed: res.data.hasSubscribed,
          brand: res.data.brand,
          expiration: res.data.expiration,
          lastFour: res.data.lastFour,
        }));
      },
    }
  );

  const closeModal = () => {
    setPaymentMethodModal({ isOpen: false });
    refetch();
  };

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  return (
    <NavHeader>
      <Container>
        <Breadcrumb pageList={pageList} />
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold text-md md:text-lg xl:text-3xl">Billing</h2>
        </div>
        <div className="mt-8 grid lg:grid-cols-1 lg:auto-rows-fr gap-8">
          <Card className="p-4 sm:p-6">
            <div className="pl-2 pt-2 pb-4">
              <p className="font-medium">Payment Information</p>
            </div>

            {isLoading || isFetching ? (
              <Spinner className="self-center" />
            ) : (
              <div className="flex items-center">
                <Card className="shrink-0" borderRequired>
                  {paymentMethod.lastFour ? (
                    <PaymentMethodCard paymentMethod={paymentMethod} />
                  ) : paymentMethod.hasSubscribed ? (
                    <p className="text-center ml-4 mt-3 mb-3 text-gray-900 text-sm font-medium">
                      Add a payment method
                    </p>
                  ) : (
                    <p className="text-center ml-4 mt-3 mb-3 text-gray-900 text-sm font-medium">
                      No subscriptions found
                    </p>
                  )}
                </Card>
              </div>
            )}

            <div className="mt-6">
              {!isLoading && !isFetching && paymentMethod.hasSubscribed && (
                <Button
                  text="Update"
                  type="submit"
                  className="h-10"
                  onClick={() => setPaymentMethodModal({ isOpen: true })}
                />
              )}
            </div>
          </Card>
          {paymentMethodModal.isOpen && (
            <PaymentMethodModal
              isOpen={paymentMethodModal.isOpen}
              closeModal={closeModal}
            />
          )}
        </div>
      </Container>
    </NavHeader>
  );
};

export default Billing;
