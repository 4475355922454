import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

interface BreacrumbProps {
  pageList: { label: string; path?: string }[];
  btnChildren?: React.ReactNode;
}

const Breadcrumb = ({ pageList, btnChildren }: BreacrumbProps) => {
  return (
    <nav
      aria-label="breadcrumb"
      className="flex flex-wrap justify-between items-center"
    >
      <ol className="flex flex-wrap items-center my-auto pb-4 mr-5">
        {pageList &&
          pageList?.length > 0 &&
          pageList?.map((page, index: number) => {
            return (
              <li key={index}>
                {page?.path ? (
                  <Link
                    className="text-passio-indigo hover:text-passio-indigo-800 font-bold text-sm"
                    to={page.path}
                  >
                    {page?.label}
                  </Link>
                ) : (
                  <span className="font-bold text-gray-500 text-sm">
                    {page?.label}
                  </span>
                )}
                {pageList.length > index + 1 && (
                  <span className="mx-2 text-sm text-gray-500"> / </span>
                )}
              </li>
            );
          })}
      </ol>
      <div className="pb-4 flex flex-wrap items-center">{btnChildren}</div>
    </nav>
  );
};
Breadcrumb.propTypes = {
  pageList: PropTypes.array.isRequired,
  btnChildren: PropTypes.node,
  innerRef: PropTypes.any,
};

export default Breadcrumb;
