import GenericCardIcon from "../../../images/generic-card-logo.svg";
import MasterCardIcon from "../../../images/master-card-logo.svg";
import VisaCardIcon from "../../../images/visa-card-logo.svg";

import { PaymentMethodDataType } from "components/pages/Billing";

interface CardPaymentMethodProps {
  paymentMethod: PaymentMethodDataType;
}

const PaymentMethodCard = ({ paymentMethod }: CardPaymentMethodProps) => {
  return (
    <div className="flex items-center text-base text-gray-700">
      <div className="flex-none px-4">
        <img
          src={
            paymentMethod.brand === "visa"
              ? VisaCardIcon
              : paymentMethod.brand === "master"
              ? MasterCardIcon
              : GenericCardIcon
          }
          alt="master-card-icon"
          className="h-10 w-10"
        />
      </div>
      <div className="flex-none px-4">
        <span className="block capitalize"> {paymentMethod.brand}</span>
      </div>
      <div className="flex-none px-4">
        <span className="block">XXXX XXXX XXXX {paymentMethod.lastFour}</span>
      </div>
      <div className="flex-1 px-4">
        <div className="flex justify-end">
          <span className="">EXP</span>
          <span className="ml-2">{paymentMethod.expiration}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodCard;
