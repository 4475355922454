import React from "react";

interface ImageProps {
  className: string;
  image?: string;
  alt: string;
}

const convertImage = (img = "") => {
  if (img.includes("googleusercontent")) {
    return img.replace(/=s.*/, "=s1080-c");
  } else if (img.includes("gravatar")) {
    return img.replace(/s=.*&/, "s=1080&");
  }
  return img;
};

const Image = ({ className, image, alt }: ImageProps) => {
  return (
    <img
      className={className}
      src={convertImage(image)}
      alt={alt}
      referrerPolicy="no-referrer"
    />
  );
};
export default Image;
