import React from "react";

import Spinner from "../Spinner";

import { classNames } from "utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  type?: "submit" | "reset" | "button" | undefined;
  formId?: string;
  outlined?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  isWarning?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    className = "",
    text = "",
    type = "button",
    formId,
    children,
    outlined,
    fullWidth,
    isLoading = false,
    isWarning = false,
    ...otherProps
  } = props;

  const content = isLoading ? (
    <Spinner className="!w-6 !h-6" />
  ) : (
    <>
      {children}
      {text}
    </>
  );

  let colorClassNames =
    "bg-passio-indigo text-white border-transparent focus:ring-passio-purple";
  if (isWarning) {
    colorClassNames =
      "bg-passio-lightRed text-passio-darkRed border-transparent focus:ring-passio-darkRed";
  } else if (outlined) {
    colorClassNames =
      "bg-white border-passio-indigo text-passio-indigo focus:ring-passio-purple";
  }

  return (
    <>
      <button
        {...otherProps}
        type={type}
        form={formId}
        className={classNames(
          "inline-flex items-center text-center px-4 py-2 whitespace-nowrap border shadow-sm text-base font-medium rounded-md hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-passio-purple mx-1 disabled:opacity-65",
          fullWidth ? "w-full flex justify-center mt-auto" : "",
          colorClassNames,
          className
        )}
      >
        {content}
      </button>
    </>
  );
};

export default Button;
