import { useAuth0 } from "@auth0/auth0-react";

import MessageBlock from "components/organisms/MessageBlock";

const marketingBase = process.env.REACT_APP_MARKETING_BASE as string;

const Welcome = () => {
  const { logout } = useAuth0();

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: marketingBase } });

  return (
    <MessageBlock
      headline="Welcome"
      content="You've successfully signed up. Please check your email."
      onClick={handleLogout}
    />
  );
};

export default Welcome;
