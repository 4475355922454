const TermsAndPrivacyLinks = () => {
  return (
    <span>
      Please read and review the{" "}
      <a
        href="https://www.passio.ai/passio-ai-tools-license-terms"
        className="text-blue-500"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms & Conditions
      </a>{" "}
      and{" "}
      <a
        href="https://www.passio.ai/privacy-policy"
        className="text-blue-500"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{" "}
      by Passio and Accept to continue.
    </span>
  );
};

export default TermsAndPrivacyLinks;
