import {
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import Spinner from "components/atoms/Spinner";

import { error, success } from "utils";
import { CREDIT_REFILL_PRICE_DISPLAY } from "utils/types";

export interface SubscriptionMetadata {
  quantity: number;
  refillBatchQuantity: number;
  refillBehaviour: "auto" | "off";
}

export function ConvertToSubscriptionMetadata(data: any): SubscriptionMetadata {
  return {
    quantity: Number(data.quantity),
    refillBatchQuantity: Number(data.refillBatchQuantity),
    refillBehaviour: data.refillBehaviour as "auto" | "off",
  };
}

interface RefillSettingsCardProps {
  subscriptionMetadata: SubscriptionMetadata;
  onSave: (newMetadata: SubscriptionMetadata) => Promise<void>;
  onRefill: () => Promise<void>;
}

const tooltipStyles = {
  backgroundColor: "#4b5563",
  color: "#ffffff",
  padding: "8px 12px",
  borderRadius: "4px",
  fontSize: "12px",
  maxWidth: "200px",
  textAlign: "left" as const,
};

const RefillSettingsCard: React.FC<RefillSettingsCardProps> = ({
  subscriptionMetadata,
  onSave,
  onRefill,
}) => {
  const [refillBatchQuantity, setRefillBatchQuantity] = useState<number>(
    subscriptionMetadata?.refillBatchQuantity || 2
  );
  const [refillBehaviour, setRefillBehaviour] = useState<"auto" | "off">(
    subscriptionMetadata?.refillBehaviour || "auto"
  );
  const [loading, setLoading] = useState(false);
  const initialSettings = { ...subscriptionMetadata };

  const needsSave = () => {
    return (
      refillBatchQuantity !== initialSettings.refillBatchQuantity ||
      refillBehaviour !== initialSettings.refillBehaviour
    );
  };

  useEffect(() => {
    setRefillBatchQuantity(subscriptionMetadata?.refillBatchQuantity || 2);
    setRefillBehaviour(subscriptionMetadata.refillBehaviour || "auto");
  }, [subscriptionMetadata]);

  if (!subscriptionMetadata) {
    return null;
  }
  const handleSave = async () => {
    setLoading(true);
    try {
      await onSave({
        ...subscriptionMetadata,
        refillBatchQuantity: refillBatchQuantity,
        refillBehaviour,
      });
      success("Refill settings saved successfully");
      setLoading(false);
    } catch (err) {
      error(
        (err as AxiosError<{ cause: string }>)?.response?.data?.cause ||
          "Failed to save refill settings"
      );
      setLoading(false);
    }
  };
  const handleRefill = async () => {
    setLoading(true);
    try {
      await onRefill();

      success("Refilled successfully");
      setLoading(false);
    } catch (err) {
      error(
        (err as AxiosError<{ cause: string }>)?.response?.data?.cause ||
          "Failed to refill"
      );
      setLoading(false);
    }
  };

  return (
    <div className="text-sm p-4 bg-white shadow rounded-lg w-full h-full">
      <h3 className="text-base font-semibold mb-3">Refill Settings</h3>
      <div className="space-y-3">
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <div className="flex-1 mb-2 sm:mb-0">
            <label
              htmlFor="refillBatchQuantity"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Refill Quantity
              <span className="pl-4 text-xs">
                <span className="text-indigo-600">
                  *{CREDIT_REFILL_PRICE_DISPLAY}
                </span>{" "}
                per million
              </span>{" "}
            </label>
            <div className="grid grid-cols-12 items-center">
              <div className="flex col-span-12 items-end pr-8">
                <input
                  type="number"
                  id="refillBatchQuantity"
                  value={refillBatchQuantity}
                  onChange={(e) =>
                    setRefillBatchQuantity(
                      Math.max(1, parseInt(e.target.value) || 1)
                    )
                  }
                  className="w-8/12 px-3 py-2 text-sm rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <span className="text-sm pl-2 no-wrap">/m tokens</span>
              </div>
            </div>
          </div>
          <div className="flex-1 w-full">
            <div className="flex justify-between w-full">
              <label
                htmlFor="refillBehaviour"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Refill Behavior
                <QuestionMarkCircleIcon
                  className="h-5 w-5 text-indigo-500 cursor-help inline ml-2"
                  data-tooltip-id="refill-behavior-tooltip"
                  data-tooltip-content={`With Auto enabled, when your account lacks the tokens to make a request, or system will automatically charge you for this amount, refill your credits, and continue service. If refill is off, your service will stop working after your token limit. Refill tokens are billed at ${CREDIT_REFILL_PRICE_DISPLAY} each (volume discounts do not apply on refills)`}
                />
                <Tooltip id="refill-behavior-tooltip" style={tooltipStyles} />
              </label>
            </div>
            <select
              id="refillBehaviour"
              value={refillBehaviour}
              onChange={(e) =>
                setRefillBehaviour(e.target.value as "auto" | "off")
              }
              className="w-full px-3 py-2 text-sm rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="off">Off</option>
              <option value="auto">Auto</option>
            </select>
          </div>
        </div>
        <div className="text-sm text-gray-600">
          Refill cost:{" "}
          <span className="text-indigo-600 font-extrabold">
            ${(2.5 * refillBatchQuantity).toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between pt-2">
          {!loading ? (
            <>
              <button
                disabled={needsSave()}
                onClick={handleRefill}
                className={`px-4 py-2 text-sm text-white rounded-md ${
                  needsSave()
                    ? "bg-gray-200"
                    : "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                }`}
              >
                <div className="flex">
                  <ShoppingCartIcon className="w-5 h-5 mr-2" />
                  <span className="text-center w-16 justify-center pt-0.5">
                    {needsSave() ? "Unsaved" : "Refill Now"}
                  </span>
                </div>
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save Settings
              </button>
            </>
          ) : (
            <div className="flex w-full justify-center">
              <Spinner className="px-auto w-8 h-8"></Spinner>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RefillSettingsCard;
