import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Fragment, useContext, useState } from "react";
import { Navigate } from "react-router-dom";

import CheckoutForm from "../CheckoutForm";

import UserContext from "context/UserContext";

import CloseButton from "components/atoms/CloseButton";
import Spinner from "components/atoms/Spinner";
import PriceTableMobileFree from "components/molecules/PriceTables/PriceTableMobileFree";
import PriceTableMobilePro from "components/molecules/PriceTables/PriceTableMobilePro";
import PriceTableNutritionAI from "components/molecules/PriceTables/PriceTableNutrition";
import PriceTableNutritionAPI from "components/molecules/PriceTables/PriceTableNutritionAPI";
import PriceTableNutritionAdvisor from "components/molecules/PriceTables/PriceTableNutritionAdvisor";
import PriceTablePassioHub from "components/molecules/PriceTables/PriceTablePassioHub";
import PriceTableRemodelAI from "components/molecules/PriceTables/PriceTableRemodelAI";
import PriceTableVRShowroom from "components/molecules/PriceTables/PriceTableVRShowroom";

import { classNames, error } from "utils";
import {
  MOBILE_AI_FREE_SLUG,
  MOBILE_AI_PRO_SLUG,
  NUTRITION_ADVISOR_LOW_SLUG,
  NUTRITION_ADVISOR_MID_SLUG,
  NUTRITION_AI,
  NUTRITION_AI_HIGH_SLUG,
  NUTRITION_AI_LOW_SLUG,
  NUTRITION_AI_MID_SLUG,
  NUTRITION_API,
  NUTRITION_API_LOW_SLUG,
  NUTRITION_API_MID_SLUG,
  REMODEL_AI,
  REMODEL_AI_LOW_SLUG,
  REMODEL_AI_MID_SLUG,
  VR_SHOWROOM_LOW_SLUG,
  productNameFromSlug,
  productNameSlugFromProductPlanSlug,
} from "utils/products";
import { ProductDataType, TierType, UserContextType } from "utils/types";

export interface SubscriptionModalType {
  isOpen: boolean;
  planSlug: string | null;
  shouldUpdate: boolean;
}

interface SubscriptionModalProps {
  isOpen: boolean;
  closeModal: () => void;
  className?: string;
  closeBtn?: boolean;
  shouldCreateOrg: boolean;
  shouldCapturePayment: boolean;
  shouldUpdate: boolean;
  planSlug: string;
  useTiers?: TierType[] | null;
  subscriptionData?: any | null;
}

const priceTableForPlan = (
  planSlug: string | null,
  useTiers: TierType[] | null = null
) => {
  if (useTiers) {
    return <PriceTablePassioHub tiers={useTiers} showDescription={true} />;
  }
  switch (planSlug) {
    case REMODEL_AI_LOW_SLUG: {
      const tiers = [
        { cap: 500, unitPrice: 0, flatPrice: 50000 },
        { cap: 0, unitPrice: 0.5, flatPrice: 0 },
      ];
      return <PriceTableRemodelAI tiers={tiers} showDescription={true} />;
    }
    case REMODEL_AI_MID_SLUG: {
      const tiers = [
        { cap: 10000, unitPrice: 0, flatPrice: 500000 },
        { cap: 0, unitPrice: 0.25, flatPrice: 0 },
      ];
      return <PriceTableRemodelAI tiers={tiers} showDescription={true} />;
    }
    case VR_SHOWROOM_LOW_SLUG: {
      return <PriceTableVRShowroom />;
    }
    case NUTRITION_API_LOW_SLUG: {
      const tiers = [
        { cap: 5000, unitPrice: 0, flatPrice: 4900 },
        { cap: 0, unitPrice: 0.02, flatPrice: 0, batchSize: 2500 },
      ];
      return <PriceTableNutritionAPI tiers={tiers} showDescription={true} />;
    }
    case NUTRITION_API_MID_SLUG: {
      const tiers = [
        { cap: 30000, unitPrice: 0, flatPrice: 24900 },
        { cap: 0, unitPrice: 0.01, flatPrice: 0, batchSize: 5000 },
      ];
      return <PriceTableNutritionAPI tiers={tiers} showDescription={true} />;
    }
    case NUTRITION_ADVISOR_LOW_SLUG: {
      const tiers = [
        { cap: 5000000, unitPrice: 0, flatPrice: 1499 },
        { cap: 0, unitPrice: 0.0000025, flatPrice: 0, batchSize: 1000000 },
      ];
      return (
        <PriceTableNutritionAdvisor tiers={tiers} showDescription={true} />
      );
    }
    case NUTRITION_ADVISOR_MID_SLUG: {
      const tiers = [
        { cap: 5000000, unitPrice: 0, flatPrice: 4900 },
        { cap: 0, unitPrice: 0.000002, flatPrice: 0, batchSize: 1000000 },
      ];
      return (
        <PriceTableNutritionAdvisor tiers={tiers} showDescription={true} />
      );
    }
    case NUTRITION_AI_LOW_SLUG: {
      const tiers = [
        { cap: 500, unitPrice: 0, flatPrice: 30000 },
        { cap: 0, unitPrice: 50, flatPrice: 0 },
      ];
      return <PriceTableNutritionAI tiers={tiers} showDescription={true} />;
    }
    case NUTRITION_AI_MID_SLUG: {
      const tiers = [
        { cap: 10000, unitPrice: 0, flatPrice: 300000 },
        { cap: 0, unitPrice: 25, flatPrice: 0 },
      ];
      return <PriceTableNutritionAI tiers={tiers} showDescription={true} />;
    }
    case NUTRITION_AI_HIGH_SLUG: {
      // No price table for Enterprise
      return <></>;
    }
    case MOBILE_AI_FREE_SLUG: {
      return <PriceTableMobileFree />;
    }
    case MOBILE_AI_PRO_SLUG: {
      return <PriceTableMobilePro />;
    }
  }
};

const SubscriptionModal = ({
  isOpen,
  closeModal,
  className,
  closeBtn = false,
  shouldCreateOrg,
  shouldCapturePayment,
  shouldUpdate,
  planSlug,
  useTiers,
  subscriptionData,
}: SubscriptionModalProps) => {
  const [unauthorized, setUnauthorized] = useState(false);
  const [productState, setProductState] = useState<ProductDataType>({
    isSubscribed: false,
    trialEnd: null,
    billingDate: new Date(),
    licenseKey: null,
    usageInfo: null,
    tiers: [],
  });

  const userContext = useContext<UserContextType | null>(UserContext);

  const productNameSlug = productNameSlugFromProductPlanSlug(planSlug);
  const getProduct = async () =>
    await axios.get(`/accounts/products/${productNameSlug}`);

  const { isLoading, isFetching } = useQuery(["product"], getProduct, {
    enabled:
      !!productNameSlug &&
      (productNameSlug === NUTRITION_AI ||
        productNameSlug == NUTRITION_API ||
        productNameSlug == REMODEL_AI), // Only fetch this data for Tiered Pricing. We only need the tiers
    onError: (err: AxiosError) => {
      error(err?.response?.data?.cause || "Could not fetch the products");
      if (err?.response?.status === 403) {
        setUnauthorized(true);
      }
    },
    onSuccess: (res: AxiosResponse) => {
      setProductState((prev) => ({
        ...prev,
        isSubscribed: res.data?.isSubscribed,
        trialEnd: res.data?.trialEnd ? new Date(res.data?.trialEnd) : null,
        licenseKey: res.data?.licenseKey,
        usageInfo: res.data?.usageInfo,
      }));
    },
  });

  // If the user is already subscribed, redirect back to the main gallery
  if (productState.isSubscribed) {
    error("already subscribed");
    return <Navigate to="/details" />;
  }

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
              className ? className : ""
            )}
          />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-11/12 lg:w-5/6 xl:w-5/6 2xl:w-2/3 sm:p-6">
                {closeBtn && <CloseButton closeModal={closeModal} />}
                {isLoading || isFetching ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="mb-3">
                      <h2 className="font-semibold inter-bold text-lg lg:text-2xl">
                        Subscribe to {productNameFromSlug(planSlug)}
                      </h2>
                      <span className="text-gray-500 col-start-2 col-span-4 flex items-center text-sm">
                        {userContext?.email}
                      </span>
                    </div>
                    <div className="grid lg:grid-cols-5 gap-4">
                      <div className="lg:col-span-2">
                        {priceTableForPlan(planSlug, useTiers)}
                      </div>
                      <CheckoutForm
                        productPlanSlug={planSlug}
                        shouldCreateOrg={shouldCreateOrg}
                        shouldCapturePayment={shouldCapturePayment}
                        shouldUpdateSubscription={shouldUpdate}
                        subscriptionData={subscriptionData}
                      />
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubscriptionModal;
