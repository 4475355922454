import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate } from "react-router-dom";

import Breadcrumb from "components/atoms/Breadcrumb";
import Container from "components/atoms/Container";
import ManageSubscriptionMobileAI from "components/organisms/ManageSubscription/ManageSubscriptionMobileAI";
import ManageSubscriptionNutritionAI from "components/organisms/ManageSubscription/ManageSubscriptionNutritionAI";
import ManageSubscriptionNutritionAPI from "components/organisms/ManageSubscription/ManageSubscriptionNutritionAPI";
import ManageSubscriptionNutritionAdvisor from "components/organisms/ManageSubscription/ManageSubscriptionNutritionAdvisor";
import ManageSubscriptionPassioHub from "components/organisms/ManageSubscription/ManageSubscriptionPassioHub";
import ManageSubscriptionRemodelAI from "components/organisms/ManageSubscription/ManageSubscriptionRemodelAI";
import ManageSubscriptionVRShowroom from "components/organisms/ManageSubscription/ManageSubscriptionVRShowroom";
import NavHeader from "components/organisms/NavHeader";

import {
  MOBILE_AI_SLUG,
  NUTRITION_ADVISOR_SLUG,
  NUTRITION_AI_SLUG,
  NUTRITION_API_SLUG,
  PASSIO_HUB_SLUG,
  REMODEL_AI_SLUG,
  VR_SHOWROOM_SLUG,
  isValidProduct,
} from "utils/products";

const ManageSubscription = () => {
  const flags = useFlags();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  if (!isValidProduct(productQuery)) {
    return <Navigate to="/not-found" />;
  }

  const pageList = [
    {
      label: "All Products",
      path: "/",
    },
    {
      label: "Manage Subscription",
    },
  ];

  if (!productQuery) {
    return <Navigate to="/" />;
  }
  if (productQuery == NUTRITION_API_SLUG && !flags["nutrition-api-purchase"]) {
    return <Navigate to="/" />;
  }
  if (
    productQuery == NUTRITION_ADVISOR_SLUG &&
    !flags["nutrition-advisor-purchase"]
  ) {
    return <Navigate to="/" />;
  }

  return (
    <NavHeader>
      <Container>
        <Breadcrumb pageList={pageList} />
        {productQuery === NUTRITION_AI_SLUG && (
          <ManageSubscriptionNutritionAI />
        )}
        {productQuery === NUTRITION_API_SLUG && (
          <ManageSubscriptionNutritionAPI />
        )}
        {productQuery === NUTRITION_ADVISOR_SLUG && (
          <ManageSubscriptionNutritionAdvisor />
        )}
        {productQuery === REMODEL_AI_SLUG && <ManageSubscriptionRemodelAI />}
        {productQuery === VR_SHOWROOM_SLUG && <ManageSubscriptionVRShowroom />}
        {productQuery === MOBILE_AI_SLUG && <ManageSubscriptionMobileAI />}
        {productQuery === PASSIO_HUB_SLUG && <ManageSubscriptionPassioHub />}
      </Container>
    </NavHeader>
  );
};

export default ManageSubscription;
