/* eslint-disable react/jsx-key */
import { CheckIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useMemo } from "react";
import { useContext } from "react";
import { useTable } from "react-table";
import { Tooltip } from "react-tooltip";

import UserContext from "context/UserContext";

import TablePagination from "components/atoms/TablePagination";

import { userHasAdminRole } from "utils";

type UserTableProps = {
  setPage: (page: number) => void;
  isFetching: boolean;
  userData: any;
  newRole: any;
  setUserToDelete: (user: any) => void;
  setConfirmDeleteOpen: (open: boolean) => void;
  setConfirmResendOpen: (open: boolean) => void;
  setNewRole: (role: any) => void;
  addUserRoleMutation: any;
  handleDeleteUserRole: (email: string, roleId: string) => string | undefined;
  addUserRole: (
    e: any,
    email: string,
    roleId: string,
    roleName: string
  ) => void;
  userRoles: any;
  setUserToResend: (user: any) => void;
  customerOwnerEmail: string;
  innerRef: any;
};

const UserTable = ({
  setPage,
  isFetching,
  userData,
  newRole,
  setUserToDelete,
  setConfirmDeleteOpen,
  setConfirmResendOpen,
  setNewRole,
  addUserRoleMutation,
  handleDeleteUserRole,
  addUserRole,
  userRoles,
  setUserToResend,
  customerOwnerEmail,
  innerRef,
}: UserTableProps) => {
  const { user_roles: userRoleData } = userRoles;
  const { entities, page = 1, lastPage = 1 } = userData;
  const userContext = useContext(UserContext);

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Name",
        id: "name",
        accessor: (originalRow: any) =>
          `${originalRow.givenName} ${originalRow.familyName}`,
      },
      {
        Header: "Verified",
        accessor: "emailVerified",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
      {
        Header: "Roles",
        id: "roles",
        accessor: (originalRow: any) => {
          const { user_roles, email } = originalRow;
          return { roles: user_roles, email };
        },
        maxWidth: 100,
        Cell: ({ value: value }: any) => {
          return (
            <>
              {value.roles?.map((role: any) => (
                <button
                  key={role.id}
                  type="button"
                  disabled={
                    isFetching ||
                    value?.email === customerOwnerEmail ||
                    (!userContext?.isOrgOwner &&
                      !userHasAdminRole(userContext?.roles || []))
                  }
                  onClick={() => {
                    handleDeleteUserRole(value.email, role.id);
                  }}
                  className={`my-1 whitespace-pre-wrapinline-flex justify-center items-center px-4 py-1 mr-2 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-purple hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-purple`}
                >
                  {role.name}
                  {role.name.startsWith("Passio") ? (
                    <>
                      <StarIcon className="w-4 h-4 inline ml-2" />
                    </>
                  ) : null}
                  {value?.email !== customerOwnerEmail &&
                    (userContext?.isOrgOwner ||
                      userHasAdminRole(userContext?.roles || [])) && (
                      <XMarkIcon className="inline w-4 h-4 ml-2" />
                    )}
                </button>
              ))}
              {addUserRoleMutation.isLoading &&
              addUserRoleMutation.variables.email === value.email ? (
                <button
                  type="button"
                  className={`my-1 whitespace-pre-wrapinline-flex justify-center items-center px-4 py-1 mr-2 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-passio-font bg-passio-background pointer-events-none`}
                >
                  {
                    userRoleData.find(
                      (item: any) =>
                        item.id === addUserRoleMutation.variables.roleId
                    ).name
                  }
                </button>
              ) : null}
              {(userHasAdminRole(userContext?.roles || []) ||
                userContext?.isOrgOwner) && (
                <select
                  value={newRole.name || ""}
                  onChange={async (e) => {
                    const newRole = userRoleData.find(
                      (item: any) => item.name === e.target.value
                    );
                    if (newRole) {
                      await setNewRole(newRole);
                      addUserRole(e, value.email, newRole.id, newRole.name);
                    }
                  }}
                  disabled={addUserRoleMutation.isFetching}
                  name="serving"
                  placeholder="Add role"
                  id="serving"
                  className="w-15 text-sm text-passio-dataTableTextColor cursor-pointer bg-white border-passio-inputBorder rounded-passio"
                >
                  <option
                    disabled
                    className="text-sm py-3 px-4"
                    value="Add role"
                  >
                    Add role
                  </option>
                  {userRoleData.map((item: any) => {
                    if (value.roles?.find((i: any) => i.id === item.id)) {
                      return null;
                    }
                    return (
                      <option
                        className="text-sm py-3 px-4"
                        key={item.name}
                        disabled={addUserRoleMutation.isFetching}
                        value={item.name}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </>
          );
        },
      },
    ],
    [
      handleDeleteUserRole,
      newRole,
      userRoleData,
      isFetching,
      addUserRole,
      setNewRole,
      addUserRoleMutation,
      customerOwnerEmail,
      userContext,
    ]
  );
  const filteredColumns = useMemo(() => {
    if (userHasAdminRole(userContext?.roles || []) || userContext?.isOrgOwner) {
      return columns; // Show all columns
    } else {
      return columns.filter((col) => col.Header !== "Action");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const handlePageChange = (ev: any) => {
    // react paginate is zero-indexed
    setPage(ev.selected + 1);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: filteredColumns,
      data:
        entities?.map((item: any) => {
          return {
            ...item,
            emailVerified: item.emailVerified ? (
              <CheckIcon className="w-4 h-4 text-green-700" />
            ) : (
              <XMarkIcon className="w-4 h-4 text-red-700 inline" />
            ),
            actions:
              userContext?.isOrgOwner ||
              userHasAdminRole(userContext?.roles || []) ? (
                !item.emailVerified ? (
                  <div className="flex justify-between gap-2">
                    <button
                      onClick={() => {
                        setUserToResend(item);
                        setConfirmResendOpen(true);
                      }}
                      disabled={item.emailVerified}
                      className="inline-flex items-center px-4 py-1 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-purple hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-purple"
                      type="submit"
                    >
                      Resend invite
                    </button>
                    {item?.email !== customerOwnerEmail && (
                      <button
                        onClick={() => {
                          setUserToDelete(item);
                          setConfirmDeleteOpen(true);
                        }}
                        className="inline-flex items-center px-4 py-1 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-buttonColor hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-buttonColor"
                        type="submit"
                      >
                        Delete user
                      </button>
                    )}
                  </div>
                ) : (
                  <Fragment>
                    {item?.email !== customerOwnerEmail ? (
                      <button
                        onClick={() => {
                          setUserToDelete(item);
                          setConfirmDeleteOpen(true);
                        }}
                        disabled={!item.emailVerified}
                        className="inline-flex items-center px-4 py-1 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-buttonColor hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-buttonColor"
                        type="submit"
                      >
                        Delete user
                      </button>
                    ) : (
                      " "
                    )}
                  </Fragment>
                )
              ) : (
                " "
              ),
          };
        }) ?? [],
    });

  if (!userRoleData.length) return null;

  return (
    <>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <table
            data-testid="user-table"
            className="min-w-full rounded-passio"
            {...getTableProps()}
            ref={innerRef}
          >
            <thead className="bg-passio-dataTableBackground">
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => {
                  return (
                    // Apply the header row props
                    <tr
                      className="rounded-passio border-b-passio-dataTableBackground"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th
                            className="px-4 py-2 text-left text-sm font-bold text-font text-passio-dataTableHeaderTextColor tracking-wider"
                            {...column.getHeaderProps()}
                          >
                            {
                              // Render the header
                              column.render("Header")
                            }
                          </th>
                        ))
                      }
                    </tr>
                  );
                })
              }
            </thead>
            {/* Apply the table body props */}
            <tbody
              className="bg-passio-inputBackground"
              {...getTableBodyProps()}
            >
              {entities?.length > 0 ? (
                // Loop over the table rows
                rows?.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  const picture = row.original.picture;
                  return (
                    // Apply the row props
                    <tr
                      className="rounded-passio divide-y divide-double divide-white bg-white border-b-passio-dataTableBackground text-passio-dataTableHeaderTextColor"
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td
                              className={
                                cell.column.Header === "Roles"
                                  ? "whitespace-pre-wrap px-4 py-2 text-sm font-medium"
                                  : "whitespace-nowrap px-4 py-2 text-sm font-medium"
                              }
                              {...cell.getCellProps()}
                            >
                              {cell.column.Header === "Email" ? (
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-8 w-8">
                                    <img
                                      className="h-8 w-8 rounded-full"
                                      src={picture}
                                      alt=""
                                    />
                                  </div>
                                  <div className="ml-4">
                                    {cell.render("Cell")}
                                  </div>
                                  {cell.value === customerOwnerEmail && (
                                    <>
                                      <StarIcon
                                        data-tooltip-id="admin-tip"
                                        data-tooltip-content="Organization owner"
                                        className="w-5 h-5 inline ml-2 text-passio-indigo"
                                      />
                                      <Tooltip id="admin-tip" />
                                    </>
                                  )}
                                </div>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              ) : (
                <tr className="bg-white border-b-passio-dataTableBackground text-passio-dataTableHeaderTextColor">
                  <td colSpan={100} className="text-center py-5">
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        containerClassNames="mt-4"
        handlePageChange={handlePageChange}
        lastPage={lastPage}
        page={page}
      />
    </>
  );
};

export default UserTable;
