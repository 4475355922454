import { batchedRateLabelFromTier } from "./PriceTableNutritionAPI";

import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";

import { TierType } from "utils/types";

interface PriceTableProps {
  tiers?: TierType[];
  isLoading?: boolean;
  showDescription: boolean;
  renderCard?: boolean;
  showTitle?: boolean;
}

const PriceTablePassioHub = ({
  tiers = [],
  isLoading,
  showDescription,
  renderCard,
  showTitle = true,
}: PriceTableProps) => {
  const content = () => {
    return (
      <>
        {showTitle && (
          <div className=" text-gray-900 text-lg font-medium font-inter leading-normal">
            Volume Discounts
          </div>
        )}

        <div className="w-full overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3 px-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
                >
                  Monthly Tokens
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
                >
                  Rate Per Million
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {isLoading && (
                <tr>
                  <td colSpan={100} className="text-center py-8">
                    <Spinner className="!w-[3rem]" />
                  </td>
                </tr>
              )}
              {!isLoading &&
                tiers.length > 0 &&
                tiers?.map((tier, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                      {index > 0 ? tiers[index - 1].cap.toLocaleString() : 1} -{" "}
                      {tier.cap ? (
                        tier.cap.toLocaleString() + "m"
                      ) : (
                        <span>&infin;</span>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                      {batchedRateLabelFromTier(tier, false)}
                    </td>
                  </tr>
                ))}
              {!isLoading && tiers?.length === 0 && (
                <tr>
                  <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                    -
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                    -
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {showDescription && tiers.length >= 2 && (
          <div className="mt-2 max-w-2xl text-sm text-gray-500 flex flex-col justify-between">
            <div className="flex-1">
              <p className="pt-1 ml-1 mr-1 mb-1">
                {" "}
                *If you exceed your credits, and auto-refill is disabled, your
                service will stop working.
                <b className="pl-1 inline-block">
                  Otherwise, token additions will be billed at{" "}
                  {batchedRateLabelFromTier(tiers[0])}
                </b>
              </p>
            </div>
          </div>
        )}
      </>
    );
  };
  return renderCard ? (
    <Card borderRequired className="h-full">
      {content()}
    </Card>
  ) : (
    <div className="h-full">{content()}</div>
  );
};

export default PriceTablePassioHub;
