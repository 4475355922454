import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useRef, useState } from "react";

import { error } from "utils";

type InviteUserModalProps = {
  open: boolean;
  handleClose: () => void;
  inviteUser: any;
  userRoles: any;
};

const InviteUserModal = ({
  open,
  handleClose,
  inviteUser,
  userRoles,
}: InviteUserModalProps) => {
  const { user_roles } = userRoles;
  const [RolesCategory, setRolesCategory] = useState(user_roles);
  const inputRef = useRef(null);
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState<any[]>([]);
  const [key, setKey] = useState(0);

  const validateEmail = (str: string) => {
    const regexp = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
    return str.match(regexp);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const passes = validateEmail(email);
    if (!passes) {
      return error("Email not valid.");
    } else {
      inviteUser.mutate({ email, user_roles: userRole });
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setEmail("");
    // TODO: Do we want people to manage adding all roles here or just limit it to accounts viewer
    setRolesCategory(user_roles);
    setUserRole([]);
    handleClose();
    setKey(key + 1);
  };
  const deleteRole = (item: any) => {
    const afterDelete = userRole.filter((el) => {
      return el.name !== item.name;
    });
    setUserRole(afterDelete);
    const sortedRoles = [...RolesCategory, item]?.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    setRolesCategory(sortedRoles);
  };

  const setRole = (val: any) => {
    const data = RolesCategory.find((el: any) => {
      return el.name === val;
    });

    setUserRole([...userRole, data]);
  };

  useEffect(() => {
    const RemoveRoleList = () => {
      const results = RolesCategory.filter(
        ({ name: id1 }: any) => !userRole.some(({ name: id2 }) => id2 === id1)
      );
      setRolesCategory(results);
    };
    RemoveRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  return (
    <Transition.Root show={open} as={Fragment} key={key}>
      <Dialog
        as="div"
        static
        initialFocus={inputRef}
        className="fixed modal inset-0 overflow-y-auto"
        open={open}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-passio-font focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleCloseModal}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <Dialog.Title
                    as="h3"
                    className="mb-2 text-lg leading-6 font-medium text-passio-font"
                  >
                    Invite New User
                  </Dialog.Title>
                </div>
              </div>
              <div className="sm:mt-2 sm:flex sm:flex-row-reverse">
                <form className="w-full px-4">
                  <label className="block mx-auto mt-4 mb-4" htmlFor="name">
                    Email
                    <input
                      id="email"
                      name="email"
                      type="email"
                      ref={inputRef}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block h-8 rounded-sm px-4 text-sm w-full"
                      value={email}
                    />
                  </label>

                  {RolesCategory?.length > 0 && (
                    <select
                      value={""}
                      onChange={(e) => {
                        e.preventDefault();
                        setRole(e.target.value);
                      }}
                      placeholder="Add role"
                      name="add-roles"
                      id="add-roles"
                      className="mr-2 w-15 text-sm cursor-pointer bg-white rounded-passio"
                    >
                      <option className="text-sm py-3 px-4" value="Add role">
                        Add role
                      </option>
                      {RolesCategory.map((item: any) => {
                        return (
                          <option
                            className="text-sm py-3 px-4"
                            key={item.name}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}

                  {userRole?.length > 0 &&
                    userRole.map((item, index) => {
                      return (
                        <button
                          key={index}
                          type="button"
                          onClick={() => {}}
                          className={`my-1 mr-1 whitespace-pre-wrapinline-flex justify-center items-center px-4 py-1 mr-2 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-purple hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-purple`}
                        >
                          {item.name}
                          <XMarkIcon
                            className="inline w-4 h-4 ml-2"
                            onClick={() => {
                              deleteRole(item);
                            }}
                          />
                        </button>
                      );
                    })}

                  <div className="flex justify-end py-2 gap-4">
                    <button
                      onClick={(e) => handleSubmit(e)}
                      className="inline-flex items-center px-4 py-1 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-purple hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-purple"
                      type="submit"
                    >
                      Send
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-1 rounded-passio border border-transparent text-sm font-medium rounded shadow-sm text-white bg-passio-buttonColor hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-passio-buttonColor"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InviteUserModal;
