import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import FormInput from "../../atoms/FormInput";

import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";
import PaymentMethodElement from "components/molecules/PaymentMethodElement";
import TermsAndPrivacyLinks from "components/molecules/TermsAndPrivacyLinks";

import { error, success } from "utils";
import {
  PASSIO_HUB_SLUG,
  eventFromProductPlanSlug,
  productNameFromSlug,
  productNameSlugFromProductPlanSlug,
  uniqueEventId,
} from "utils/products";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? ""
);

const subscriptionBodyForProductPlanSlug = (productPlanSlug: string) => {
  switch (productPlanSlug.toLowerCase()) {
    case "nutrition-api-low":
      return { planTitle: "low" };
    case "nutrition-api-mid":
      return { planTitle: "mid" };
    case "nutrition-advisor-low":
      return { planTitle: "low" };
    case "nutrition-advisor-mid":
      return { planTitle: "mid" };
    case "remodel-ai-low":
      return { planTitle: "low" };
    case "remodel-ai-mid":
      return { planTitle: "mid" };
    case "vr-showroom-low":
      return { planTitle: "low" };
    case "nutrition-ai-low":
      return { planTitle: "low" };
    case "nutrition-ai-mid":
      return { planTitle: "mid" };
    case "nutrition-ai-high":
      return { planTitle: "high" };
    case "mobile-ai-free":
      return { planTitle: "free" };
    case "mobile-ai-pro":
      return { planTitle: "pro" };
    default:
      return {};
  }
};

interface CheckoutFormProps {
  productPlanSlug: string;
  shouldCreateOrg: boolean;
  shouldCapturePayment: boolean;
  shouldUpdateSubscription: boolean;
  subscriptionData?: any | null;
}

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    gtag_report_conversion: (
      url: string,
      conversionLabel: string,
      transactionId?: string
    ) => boolean;
  }
}

const CheckoutForm = ({
  productPlanSlug,
  shouldCreateOrg,
  shouldCapturePayment,
  shouldUpdateSubscription,
  subscriptionData,
}: CheckoutFormProps) => {
  const conversionTrackingIds = {
    remodel_ai_low: "AW-11104637349/h5nYCPDtqJUZEKWjja8p",
    remodel_ai_mid: "AW-11104637349/WjhdCP_2qJUZEKWjja8p",
    vr_showroom_low: "AW-11104637349/bBJSCMn6oZUZEKWjja8p",
    nutrition_ai_low: "AW-11104637349/iUk8CMK2vpQZEKWjja8p",
    nutrition_ai_mid: "AW-11104637349/-ygTCJ_axZQZEKWjja8p",
    mobile_ai_pro: "AW-11104637349/O4KgCMyfvpQZEKWjja8p",
    nutrition_api_low: "AW-11104637349/b585CIy8vpQZEKWjja8p",
    nutrition_api_mid: "AW-11104637349/Ru6ACKTFvpQZEKWjja8p",
    DEV_nutrition_ai_low: "AW-11104637349/2zb-CNvVnZQZEKWjja8p",
  };

  useEffect(() => {
    // Define the conversion tracking function
    window.gtag_report_conversion = (url, conversionLabel, transactionId) => {
      const callback = function () {
        if (typeof url !== "undefined") {
          window.location.href = url;
        }
      };
      window.gtag("event", "conversion", {
        send_to: conversionLabel,
        transaction_id: transactionId,
        event_callback: callback,
      });
      return false;
    };
  }, []);

  const [orgName, setOrgName] = useState("");
  const [isTermsChecked, setTermsChecked] = useState(false);

  const [showPaymentElement, setShowPaymentElement] = useState(false);

  const productNameSlug = productNameSlugFromProductPlanSlug(productPlanSlug);
  // TODO: DVS: Clean
  let useSlug = productNameSlug;
  if (productNameSlug === PASSIO_HUB_SLUG) {
    useSlug = "nutrition-hub";
  }

  // This is a redirect from stripe, so it must be fully qualified
  let redirectOnSucessURL = `${process.env.REACT_APP_ACCOUNTS_URL}/details?product=${useSlug}`;
  // TODO: DVS: FIX
  if (productNameSlug === "nutrition-hub") {
    redirectOnSucessURL = `${process.env.REACT_APP_ACCOUNTS_URL}/details?product=unified`;
  }
  const elementsOptions = {
    mode: "setup" as const,
    currency: "usd",
    loader: "always" as const,
    paymentMethodCreation: "manual" as const,
  };

  ReactGA.event(eventFromProductPlanSlug("begin_checkout", productPlanSlug));

  const createOrg = useMutation(
    async () => {
      await axios.post(`/accounts/customer/create`, {
        name: orgName,
      });
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: async () => {
        success("Organization successfully created");

        ReactGA.event(eventFromProductPlanSlug("org_created", productPlanSlug));

        // If we shouldn't capture payment, but we should update the subscription, then update
        if (!shouldCapturePayment && shouldUpdateSubscription) {
          updateSubscription.mutate();
          return;
        }

        // If we shouldn't capture payment and also shouldn't update, then create the subscription
        if (!shouldCapturePayment && !shouldUpdateSubscription) {
          createSubscription.mutate();
          return;
        }

        // If we should capture payment, then show the payment element
        setShowPaymentElement(true);
      },
    }
  );

  const createSubscription = useMutation(
    async () => {
      let body = subscriptionData;
      if (!body) {
        body = subscriptionBodyForProductPlanSlug(productPlanSlug);
      }
      await axios.post(
        `/accounts/products/${productNameSlug}/subscription`,
        body
      );
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: () => {
        success("Subscription successfully created");
        ReactGA.event(eventFromProductPlanSlug("subscribed", productPlanSlug));

        const conversionLabel =
          conversionTrackingIds[
            productPlanSlug.replaceAll(
              "-",
              "_"
            ) as keyof typeof conversionTrackingIds
          ];

        if (conversionLabel) {
          const txId = uniqueEventId();
          // eslint-disable-next-line no-console
          console.log(`Successful conversion: ${txId}`);
          window.gtag_report_conversion(
            `${redirectOnSucessURL}`,
            conversionLabel,
            txId
          );
        } else {
          // Redirect to the product details page of the subscribed product
          window.location.href = `${redirectOnSucessURL}`;
        }
      },
    }
  );

  const updateSubscription = useMutation(
    async () => {
      const body = subscriptionBodyForProductPlanSlug(productPlanSlug);
      return await axios.put(`/accounts/products/mobile-ai/subscription`, body);
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: () => {
        success("Subscription updated successfully");

        ReactGA.event(eventFromProductPlanSlug("subscribed", productPlanSlug));

        // build the event here (we have more info on this page) and pass it as a param to the redirect
        const eventObj = {
          action: "subscribed",
          category: productPlanSlug.toLowerCase(),
          label: uniqueEventId(),
        };
        const jsonString = JSON.stringify(eventObj);
        const base64Encoded = btoa(jsonString);

        // Redirect to the product details page of the subscribed product
        window.location.href = `${redirectOnSucessURL}&event=${base64Encoded}`;

        // Redirect to the product details page of the subscribed product
        window.location.href = redirectOnSucessURL;
      },
    }
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // If we don't need to create an org or capture payment and also shouldn't update, then create the subscription
    if (
      !shouldCreateOrg &&
      !shouldCapturePayment &&
      !shouldUpdateSubscription
    ) {
      createSubscription.mutate();
      return;
    }

    // If we don't need to create an org or capture payment, but we should update the subscription, then update
    if (!shouldCreateOrg && !shouldCapturePayment && shouldUpdateSubscription) {
      updateSubscription.mutate();
      return;
    }

    // If we don't need to create the org, but do need to capture payment, show the payment element
    if (!shouldCreateOrg && shouldCapturePayment) {
      setShowPaymentElement(true);
      return;
    }

    // We must need to create the org
    createOrg.mutate();
  };

  return (
    <Card className="lg:col-span-3" borderRequired>
      {!showPaymentElement && (
        <>
          <div className="mb-1">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Subscribe to {productNameFromSlug(productPlanSlug)}
            </h3>
            <span className="text-passio-indigo font-bold text-lg lg:text-xl xl:text-3xl">
              Start building today.
            </span>
          </div>
          <form id="create-subscription-form" onSubmit={handleSubmit}>
            <div className="mb-2">
              {shouldCreateOrg && (
                <FormInput
                  type="text"
                  label="Name your organization"
                  name="organizationName"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  id="organizationName"
                  placeholder="Organization Name"
                  required
                />
              )}
            </div>
            <div className="grid grid-cols-6 my-4">
              <p className="text-gray-500 col-start-2 col-span-4 flex items-center text-sm">
                <input
                  required
                  name="terms"
                  type="checkbox"
                  className="h-4 w-4 mr-3 rounded-[0.2rem] border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                  checked={isTermsChecked}
                  onChange={(e) => setTermsChecked(e.target.checked)}
                />
                <TermsAndPrivacyLinks />
              </p>
            </div>
            <Button
              disabled={
                (!orgName && shouldCreateOrg) ||
                !isTermsChecked ||
                createOrg.isLoading
              }
              id="submit"
              type="submit"
              formId="create-subscription-form"
              fullWidth
            >
              <span id="button-text">
                {createOrg.isLoading ? (
                  <div className="flex items-center justify-center">
                    <Spinner className="!w-4 !h-4 !text-white" />
                    Accept
                  </div>
                ) : (
                  "Accept"
                )}
              </span>
            </Button>
          </form>
        </>
      )}
      {shouldCapturePayment && showPaymentElement && stripePromise && (
        <Elements options={elementsOptions} stripe={stripePromise}>
          <PaymentMethodElement
            buttonText="Subscribe"
            paymentCompleteCB={() =>
              shouldUpdateSubscription
                ? updateSubscription.mutate()
                : createSubscription.mutate()
            }
          />
        </Elements>
      )}
    </Card>
  );
};

export default CheckoutForm;
