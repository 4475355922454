import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

import SubscriptionModal, { SubscriptionModalType } from "../SubscriptionModal";

import UserContext from "context/UserContext";

import Modal from "components/atoms/Modal";
import Spinner from "components/atoms/Spinner";
import AdditionalUsersControl from "components/molecules/AdditionalUsersControl";
import SubscriptionCard from "components/molecules/SubscriptionCard";

import { error, success } from "utils";
import { MOBILE_AI_SLUG, uniqueEventId } from "utils/products";
import { CustomerStatusType, UsageInfoType } from "utils/types";

interface MobileAITierType {
  isSubscribed: boolean | null;
  planTitle: string | null;
  usageInfo: UsageInfoType | null;
  existingAdditionalUsers: number | null;
}

const additionalUsersFromProQuota = (quota: number) => {
  return quota - 3;
};

const ManageSubscriptionMobileAI = () => {
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  const [unauthorized, setUnauthorized] = useState(false);

  const [customerStatus, setCustomerStatus] = useState<CustomerStatusType>({
    hasOrg: false,
    hasPaymentMethod: false,
  });
  const [productState, setProductState] = useState<MobileAITierType>({
    isSubscribed: null,
    planTitle: null,
    usageInfo: null,
    existingAdditionalUsers: null,
  });

  const [additionalUsers, setAdditionalUsers] = useState(0);

  const [subscriptionModal, setSubscriptionModal] =
    useState<SubscriptionModalType>({
      isOpen: false,
      planSlug: null,
      shouldUpdate: false,
    });

  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState(false);

  // Users without any subscription, or with a Pro subscription should not see the free tier.
  // Customers who already have the free tier should continue to see it
  const legacyFreeTierCustomer =
    productState.isSubscribed && productState.planTitle === "free";

  // We don't need to capture payment if we already have a payment method, or if we're on the free plan.
  const paymentCaptureNotNeeded =
    customerStatus.hasPaymentMethod ||
    subscriptionModal.planSlug === "mobile-ai-free";

  const setAllData = (data: any) => {
    let existingAdditionalUsers: number;
    // If we're on the pro plan, we need to calculate the additional users.
    // The additional users are the quota minus the 3 users included in the plan.
    if (data.planTitle === "pro") {
      const additionalUsers = additionalUsersFromProQuota(
        data.usageInfo.userQuota
      );
      existingAdditionalUsers = additionalUsers;
      setAdditionalUsers(additionalUsers);
    } else {
      existingAdditionalUsers = 0;
      setAdditionalUsers(0);
    }
    setCustomerStatus((prev) => ({
      ...prev,
      hasOrg: data.hasOrg,
      hasPaymentMethod: data.hasPaymentMethod,
    }));
    setProductState((prev) => ({
      ...prev,
      isSubscribed: data.isSubscribed,
      planTitle: data.planTitle,
      usageInfo: data.usageInfo,
      existingAdditionalUsers,
    }));
  };

  const userContext = useContext(UserContext);

  const getProduct = async () =>
    await axios.get(`/accounts/products/${productQuery}`);

  const { isLoading, isFetching, isPaused, refetch } = useQuery(
    ["product"],
    getProduct,
    {
      enabled: !!productQuery && !!userContext,
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setAllData(res?.data);
      },
    }
  );

  const updateSubscription = useMutation(
    async (planTitle: string) => {
      const data = { planTitle, additionalUsers };
      return await axios.put(`/accounts/products/mobile-ai/subscription`, data);
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: (res: AxiosResponse) => {
        success("Subscription updated successfully");
        setAllData(res?.data);

        // google event
        ReactGA.event({
          action: "subscriptionupdated",
          category: `${MOBILE_AI_SLUG}_${productState.planTitle?.toLowerCase()}}`,
          label: uniqueEventId(),
        });
      },
    }
  );

  const unsubscribe = useMutation(
    async () => await axios.delete(`/accounts/products/mobile-ai/subscription`),
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: () => {
        success("Unsubscribed successfully");
        setUnsubscribeModalIsOpen(false);
        // google event
        ReactGA.event({
          action: "subscriptioncancelled",
          category: `${MOBILE_AI_SLUG}_${productState.planTitle?.toLowerCase()}`,
          label: uniqueEventId(),
        });
        // Reset the product state
        setProductState((prev) => ({
          ...prev,
          isSubscribed: false,
          planTitle: null,
          usageInfo: null,
          existingAdditionalUsers: 0,
        }));
      },
    }
  );

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  function closeSubscriptionModal() {
    refetch();
    setSubscriptionModal({
      isOpen: false,
      planSlug: null,
      shouldUpdate: false,
    });
  }

  const buttonOnClickForPlan = (plan: string, isSubscribed: boolean) => {
    // If we're not subscribed, we need to open the subscription modal.
    if (!isSubscribed) {
      return () =>
        setSubscriptionModal({
          isOpen: true,
          planSlug: `${MOBILE_AI_SLUG}-${plan}`,
          shouldUpdate: false,
        });
    }

    // If we're subscribed, but to free, we need to open the subscription modal.
    if (isSubscribed && productState.planTitle === "free") {
      return () =>
        setSubscriptionModal({
          isOpen: true,
          planSlug: `${MOBILE_AI_SLUG}-${plan}`,
          shouldUpdate: true,
        });
    }

    // If we're already subscribed to pro, just update the subscription.
    return () => updateSubscription.mutate(plan);
  };

  const calculatePrices = (additionalUsers: number) => {
    const basePrice = 199;
    const perAdditionalUserPrice = 99;
    const additionalUserPrice = perAdditionalUserPrice * additionalUsers;
    const totalPrice = basePrice + additionalUserPrice;
    return {
      basePrice,
      perAdditionalUserPrice,
      additionalUserPrice,
      totalPrice,
    };
  };

  const renderPrice = (additionalUsers: number) => {
    const { totalPrice } = calculatePrices(additionalUsers);
    return (
      <>
        <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
          ${totalPrice.toLocaleString()}
        </div>
        <div className="text-gray-500 font-inter text-2xl font-medium leading-8">
          /mo
        </div>
      </>
    );
  };

  const renderCounterInput = (
    existingUsers: number | null | undefined,
    additionalUsers: number
  ) => {
    const floor = existingUsers || 0;
    const ceiling = 99; // Arbitrary ceiling on the number of additional users
    const userWord = additionalUsers === 1 ? "user" : "users";
    const { basePrice, perAdditionalUserPrice, additionalUserPrice } =
      calculatePrices(additionalUsers);
    return (
      <div>
        <div className="grid grid-cols-5 grid-rows-2 items-baseline">
          <div className="font-bold text-lg text-right justify-self-end pr-2">
            ${basePrice.toLocaleString()}
          </div>
          <div className="col-span-4 font-medium text-md justify-self-start">
            Base price
          </div>
          <div className="font-bold text-lg text-right justify-self-end pr-2">
            ${additionalUserPrice.toLocaleString()}
          </div>
          <div className="col-span-4 font-medium text-md justify-self-start">
            For <span className="font-semibold">{additionalUsers}</span>{" "}
            additional {userWord} (${perAdditionalUserPrice.toLocaleString()}{" "}
            /user)
          </div>
        </div>
        <AdditionalUsersControl
          floor={floor}
          ceiling={ceiling}
          value={additionalUsers}
          setValue={setAdditionalUsers}
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mb-6">
          <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
            Mobile AI Subscription
          </h2>
        </div>
        {isFetching || isLoading || isPaused || updateSubscription.isLoading ? (
          <Spinner />
        ) : (
          <div className="flex flex-wrap place-content-evenly h-full">
            {legacyFreeTierCustomer && (
              <SubscriptionCard
                name="Mobile AI Basic"
                price={
                  <>
                    <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
                      Free
                    </div>
                  </>
                }
                counterInput={<></>}
                description="Get started building your first mobile AI project for free with no credit card required."
                bullets={[
                  "Unlimited projects",
                  "Unlimited training runs",
                  "3 users total",
                  "1k training images total",
                ]}
                isCurrent={productState.planTitle === "free"}
                buttonText="Not Shown" // This text is never shown, because we only show free tier to people who are subscribed
                buttonOnClick={buttonOnClickForPlan(
                  "free",
                  productState?.isSubscribed || false
                )}
                isCurrentButtonText="Product Details"
                isCurrentButtonOnClick={() =>
                  navigate(`/details?product=${productQuery}`)
                }
                disabled={
                  isLoading ||
                  isFetching ||
                  isPaused ||
                  updateSubscription.isLoading ||
                  !userContext?.isOrgOwner
                }
                wide={false}
                showCancelButton={productState.planTitle === "free"}
                cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
              />
            )}
            <SubscriptionCard
              name="Mobile AI Pro"
              description="Scale up your team and data with the full Mobile AI development experience."
              price={renderPrice(additionalUsers)}
              counterInput={renderCounterInput(
                productState?.existingAdditionalUsers,
                additionalUsers
              )}
              bullets={[
                "Unlimited projects",
                "Unlimited training runs",
                "3 users free, $99 per user additional",
                "1k images free, $0.10 per 100 additional",
              ]}
              isCurrent={productState.planTitle === "pro"}
              buttonText="Subscribe"
              // Plan titles are lowcase
              buttonOnClick={buttonOnClickForPlan(
                "pro",
                productState?.isSubscribed || false
              )}
              isCurrentButtonText="Product Details"
              isCurrentButtonOnClick={() =>
                navigate(`/details?product=${productQuery}`)
              }
              disabled={
                isLoading ||
                isFetching ||
                isPaused ||
                updateSubscription.isLoading ||
                !userContext?.isOrgOwner
              }
              wide={!legacyFreeTierCustomer}
              showCancelButton={productState.planTitle === "pro"}
              cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
            />
            <SubscriptionCard
              name="Mobile AI Enterprise"
              description="When you have a big team, big projects, and want all the expertise Passio has to offer."
              price={
                <>
                  <div className="text-gray-900 font-inter text-2xl font-bold leading-[60px]">
                    Talk with sales for pricing
                  </div>
                </>
              }
              counterInput={<></>}
              bullets={[
                "Unlimited projects",
                "Unlimited training runs",
                "Unlimited users",
              ]}
              isCurrent={productState.planTitle === "enterprise"}
              buttonText="Contact Us"
              buttonOnClick={() =>
                window.open(
                  `${process.env.REACT_APP_MARKETING_BASE}/contact`,
                  "_blank"
                )
              }
              isCurrentButtonText="Product Details"
              isCurrentButtonOnClick={() =>
                navigate(`/details?product=${productQuery}`)
              }
              disabled={
                isLoading ||
                isFetching ||
                isPaused ||
                updateSubscription.isLoading ||
                !userContext?.isOrgOwner
              }
              wide={!legacyFreeTierCustomer}
              showCancelButton={false}
              cancelButtonOnClick={() => {}}
            />
          </div>
        )}
      </div>
      {subscriptionModal.isOpen && subscriptionModal.planSlug && (
        <SubscriptionModal
          isOpen={subscriptionModal.isOpen}
          closeModal={closeSubscriptionModal}
          closeBtn
          planSlug={subscriptionModal.planSlug}
          shouldCreateOrg={!customerStatus.hasOrg}
          shouldCapturePayment={!paymentCaptureNotNeeded}
          shouldUpdate={subscriptionModal.shouldUpdate}
        />
      )}
      <Modal
        isOpen={unsubscribeModalIsOpen}
        setOpen={(e) => setUnsubscribeModalIsOpen(e)}
        title={"Unsubscribe from Mobile AI"}
        disabled={unsubscribe.isLoading}
        isLoading={unsubscribe.isLoading}
        description={`Are you sure you want to unsubscribe? Your subscription will be cancelled immediately. This action cannot be undone.`}
        submitButtonName={"Unsubscribe"}
        buttonType={"warning"}
        type={"error"}
        onSubmitted={() => unsubscribe.mutate()}
      />
    </>
  );
};

export default ManageSubscriptionMobileAI;
