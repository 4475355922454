import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router";

import Card from "components/atoms/Card";
import SlackSignUp from "components/molecules/SlackSignUp";

const DetailsMobileAI = () => {
  const [unauthorized] = useState(false);

  // Google Adds Event Tracking
  useEffect(() => {
    // Function to get URL parameter
    const getQueryParam = (param: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };
    const encodedData = getQueryParam("event");
    if (encodedData) {
      const decodedString = atob(encodedData);
      try {
        const obj = JSON.parse(decodedString);
        ReactGA.event(obj);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, []);

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
          Mobile AI Product Details
        </h2>
      </div>
      <div className="grid lg:grid-cols-2 lg:auto-rows-fr gap-8">
        <Card className="sm:p-6">
          <div className="text-passio-indigo hover:text-passio-indigo-800 font-bold text-3xl">
            <a
              href={process.env.REACT_APP_MOBILE_AI_URL}
              target="_blank" // Open in new tab
              rel="noopener noreferrer"
            >
              Go to the Mobile AI site →
            </a>
          </div>
          <div className="mt-4">
            <p className="font-medium">Support & Documentation</p>
            <span className="text-sm text-gray-500">
              Links for additional support
            </span>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Documentation</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio.gitbook.io/mobile-ai-platform"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio.gitbook.io/mobile-ai-platform
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Email Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="mailto:support@passiolife.com"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                support@passiolife.com
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Slack Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio-community.slack.com/channels/help"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio-community.slack.com/channels/help
              </a>
            </dd>
          </div>
        </Card>
        <SlackSignUp />
      </div>
    </>
  );
};

export default DetailsMobileAI;
