import React from "react";

import Button from "components/atoms/Button";
import Card from "components/atoms/Card";

import { classNames } from "utils";

interface SubscriptionCardProps {
  name: string;
  description: string;
  descriptionAlt?: string;
  price: React.ReactNode;
  counterInput: React.ReactNode;
  bullets: string[];
  bulletsHtml?: React.ReactNode[];
  isCurrent: boolean;
  buttonText: string;
  buttonOnClick: () => void;
  isCurrentButtonText: string;
  isCurrentButtonOnClick: () => void;
  hideButton?: boolean;
  showCancelButton: boolean;
  cancelButtonOnClick: () => void;
  disabled: boolean;
  wide?: boolean;
}

const SubscriptionCard = ({
  name,
  price,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  counterInput,
  description,
  bullets,
  isCurrent,
  buttonText,
  buttonOnClick,
  isCurrentButtonText,
  isCurrentButtonOnClick,
  hideButton = false,
  showCancelButton,
  cancelButtonOnClick,
  disabled,
  wide = true,
  bulletsHtml = [],
  descriptionAlt = "",
}: SubscriptionCardProps) => {
  const cardBorder = isCurrent ? "border-passio-indigo border-2" : "";
  const maxWidth = wide ? "max-w-[620px]" : "max-w-[420px]";
  return (
    <div
      className={classNames(
        "relative flex flex-row py-2 px-2 min-w-[420px]",
        maxWidth
      )}
    >
      <Card borderRequired className={`${cardBorder} flex`}>
        <div className="flex flex-col items-start gap-5 px-8 pt-2 self-stretch">
          <div className="flex justify-center items-center py-1 px-4 rounded-[0.875rem] bg-indigo-100 text text-indigo-600 text-center font-inter text-sm font-medium leading-5 uppercase">
            {name}
          </div>
          <div className="flex items-end gap-1 self-stretch">{price}</div>
          <div className="self-stretch text-gray-500 font-inter text-lg leading-7">
            <span className="text-md">{description}</span>
            <span className="text-sm block text-indigo-600">
              {descriptionAlt}
            </span>
          </div>
        </div>
        <div className="flex flex-col min-h-[24rem] max-h-[24rem] items-stretch">
          <div className="basis-4 border-b-2 border-passio-lightPrimary"></div>
          <div className="basis-6/12 min-w-full min-h-[12rem] my-4 px-2">
            <ul
              role="list"
              className="flex flex-col items-start list-outside  h-full"
            >
              {bulletsHtml.map((bullet, i) => (
                <li
                  key={i}
                  className="inline-flex items-start justify-center my-1"
                >
                  <svg
                    width={25}
                    height={24}
                    viewBox="0 0 25 24"
                    fill="none"
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.66663 13L9.66663 17L19.6666 7"
                      stroke="#10B981"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-md text-gray-500 ml-2">{bullet}</p>
                </li>
              ))}
              {bullets.map((bullet, i) => (
                <li
                  key={`rb${i}`}
                  className="inline-flex items-center justify-center my-1"
                >
                  <svg
                    width={25}
                    height={24}
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.66663 13L9.66663 17L19.6666 7"
                      stroke="#10B981"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-md text-gray-500 ml-2">{bullet}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex basis-20 min-h-[6rem] place-items-stretch ">
          <div className="flex flex-col items-end min-w-full">
            {!disabled && !hideButton ? (
              <Button
                text={isCurrent ? isCurrentButtonText : buttonText}
                onClick={isCurrent ? isCurrentButtonOnClick : buttonOnClick}
                disabled={disabled}
                fullWidth
                outlined={isCurrent}
              />
            ) : (
              <></>
            )}
            {!disabled && showCancelButton ? (
              <Button
                className="mt-2"
                text="Cancel Subscription"
                onClick={cancelButtonOnClick}
                disabled={disabled}
                fullWidth
                isWarning
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Card>
      {isCurrent && (
        <div className="absolute top-0 right-8 bg-passio-indigo border-passio-indigo rounded-full border-2">
          <div className="text-sm font-medium text-white text-center justify-center mx-4 y-1">
            Current Plan
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionCard;
