import axios from "axios";
import toast, { Renderable, Toast, ValueFunction } from "react-hot-toast";

import { CustomerAccessLevelType } from "utils/types";

export const adminRole = process.env.REACT_APP_ADMIN_ROLE || "Admin";

export const error = (
  msg: Renderable | ValueFunction<Renderable, Toast>,
  toastId = ""
) =>
  toast.error(msg, {
    duration: 5000,
    id: toastId,
  });

export const success = (
  msg: Renderable | ValueFunction<Renderable, Toast>,
  duration = 5000
) =>
  toast.success(msg, {
    duration,
  });

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const userHasRole = (roles: string[], targetRole: string) => {
  // Passio Admins have all roles
  if (roles.find((item: string) => item === adminRole)) {
    return true;
  }

  return Boolean(roles.find((item: string) => item === targetRole));
};

export const userHasAdminRole = (roles: string[]): boolean => {
  return adminRole ? userHasRole(roles, adminRole) : false;
};

export const userIsOrganizationOwner =
  async (): Promise<CustomerAccessLevelType> => {
    try {
      const response = await axios.get(`accounts/customer/access`);
      const data = response.data as CustomerAccessLevelType;
      return data;
    } catch (_) {
      return {} as CustomerAccessLevelType;
    }
  };

export const formatPriceIntoCents = (price: number) => {
  if (price >= 1) {
    price = price / 100;
  }

  if (price < 0.01) {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 8,
    });
  }
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
