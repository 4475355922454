import { rateLabelFromTier } from "./PriceTableNutritionAPI";

import Card from "components/atoms/Card";
import Spinner from "components/atoms/Spinner";

import { TierType } from "utils/types";

interface PriceTableProps {
  tiers?: TierType[];
  isLoading?: boolean;
  showDescription: boolean;
}

const PriceTableNutritionAdvisor = ({
  tiers = [],
  isLoading,
  showDescription,
}: PriceTableProps) => {
  return (
    <Card borderRequired className="h-full">
      <div className="w-full overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3 px-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
              >
                Monthly Tokens
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-sm font-medium text-gray-500 tracking-wide uppercase"
              >
                Rate
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {isLoading && (
              <tr>
                <td colSpan={100} className="text-center py-8">
                  <Spinner className="!w-[3rem]" />
                </td>
              </tr>
            )}
            {!isLoading &&
              tiers.length > 0 &&
              tiers?.map((tier, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                    {index > 0 ? tiers[index - 1].cap.toLocaleString() : 1} -{" "}
                    {tier.cap ? (
                      tier.cap.toLocaleString()
                    ) : (
                      <span>&infin;</span>
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                    {rateLabelFromTier(tier)}
                  </td>
                </tr>
              ))}
            {!isLoading && tiers?.length === 0 && (
              <tr>
                <td className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 uppercase">
                  -
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 font-medium uppercase">
                  -
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showDescription && tiers.length >= 2 && (
        <div className="mt-2 max-w-2xl text-sm text-gray-500 flex flex-col justify-between">
          <div className="flex-1">
            <p className="pt-1 ml-1 mr-1 mb-1">
              {" "}
              *Beyond the included {tiers[0].cap?.toLocaleString("en-US")},
              tokens are billed in blocks of
              <b className="pl-1">
                {tiers[tiers.length - 1].batchSize?.toLocaleString("en-US")}{" "}
                tokens at{" "}
                {(
                  tiers[tiers.length - 1].unitPrice *
                  (tiers[tiers.length - 1].batchSize || 1)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                per block.
              </b>
            </p>
          </div>
        </div>
      )}
    </Card>
  );
};

export default PriceTableNutritionAdvisor;
