import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import "./UsageCalculator.css";

import Card from "components/atoms/Card";
import {
  calculatePrice,
  convertToNumLetter,
} from "components/molecules/PriceTables/PriceTableNutritionAPI";

import { CREDIT_REFILL_PRICE_DISPLAY, TierType } from "utils/types";

interface UsageCalculatorData {
  tokensPerAPIRequest: number;
  tokensPerVisionAIRequest: number;
  tokensPerTextAIRequest: number;
  tokensPerChatMessageRequest: number;
  numUsers: number;
  avgUserMonthlyAPIRequests: number;
  avgUserMonthlyVisionAIRequests: number;
  avgUserMonthlyTextAIRequests: number;
  avgUserMonthlyChatMessageRequests: number;
}

export type DynamicVolumeSubscriptionSettings = {
  quantity: number;
  refillBehaviour: string;
  refillBatchQuantity: number;
  planTitle: string;
};

interface UsageCalculatorProps {
  pricingTiers: TierType[];
  subscriptionSettings: DynamicVolumeSubscriptionSettings[];
  priceTable: React.ReactNode;
}

const UsageCalculator: React.FC<UsageCalculatorProps> = ({
  pricingTiers,
  subscriptionSettings,
  priceTable,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [simpleIsExpanded, setSimpleIsExpanded] = useState<boolean>(true);
  const [usageData, setUsageData] = useState<UsageCalculatorData>({
    tokensPerAPIRequest: 100,
    tokensPerVisionAIRequest: 5000,
    tokensPerTextAIRequest: 500,
    tokensPerChatMessageRequest: 1000,
    numUsers: 50,
    avgUserMonthlyAPIRequests: 300,
    avgUserMonthlyVisionAIRequests: 20,
    avgUserMonthlyTextAIRequests: 100,
    avgUserMonthlyChatMessageRequests: 20,
  });
  const [overageTokens, setOverageTokens] = useState<number>(0);
  const [overageCost, setOverageCost] = useState<number>(0);
  const [predictedTokens, setPredictedTokens] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [predictedCost, setPredictedCost] = useState<number>(0);
  const [selectedPlan, setSelectedPlan] =
    useState<DynamicVolumeSubscriptionSettings>(subscriptionSettings[0]);

  useEffect(() => {
    const totalTokens =
      usageData.numUsers *
      (usageData.tokensPerAPIRequest * usageData.avgUserMonthlyAPIRequests +
        usageData.tokensPerVisionAIRequest *
          usageData.avgUserMonthlyVisionAIRequests +
        usageData.tokensPerTextAIRequest *
          usageData.avgUserMonthlyTextAIRequests +
        usageData.tokensPerChatMessageRequest *
          usageData.avgUserMonthlyChatMessageRequests);
    setPredictedTokens(totalTokens);
  }, [usageData]);

  const findNearestPlan = (tokens: number) => {
    let nearestPlan = subscriptionSettings[0];
    for (const plan of subscriptionSettings) {
      const mod = plan.quantity * 0.4;
      if (tokens > plan.quantity - mod) {
        nearestPlan = plan;
      } else {
        break;
      }
    }
    return nearestPlan;
  };

  useEffect(() => {
    const totalTokens =
      usageData.numUsers *
      (usageData.tokensPerAPIRequest * usageData.avgUserMonthlyAPIRequests +
        usageData.tokensPerVisionAIRequest *
          usageData.avgUserMonthlyVisionAIRequests +
        usageData.tokensPerTextAIRequest *
          usageData.avgUserMonthlyTextAIRequests +
        usageData.tokensPerChatMessageRequest *
          usageData.avgUserMonthlyChatMessageRequests);

    const millionTokens = Math.ceil(totalTokens / 1000000);
    setPredictedTokens(millionTokens * 1000000);

    const nearestPlan = findNearestPlan(millionTokens);
    setSelectedPlan(nearestPlan);
    const overageTokens = Math.max(0, millionTokens - nearestPlan.quantity);
    const overageCost = overageTokens * pricingTiers[0].unitPrice; // CREDIT_REFILL_PRICE_DISPLAY

    setPredictedCost(calculatePrice(nearestPlan.quantity, pricingTiers));
    setOverageTokens(overageTokens);
    setOverageCost(overageCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageData, pricingTiers, subscriptionSettings]);

  const toggleExpand = () => setIsExpanded(!isExpanded);
  const toggleSimpleExpand = () => setSimpleIsExpanded(!simpleIsExpanded);

  const handleSliderChange =
    (field: keyof UsageCalculatorData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsageData((prev) => ({
        ...prev,
        [field]: Number(event.target.value),
      }));
    };

  const handleInputChange =
    (field: keyof UsageCalculatorData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setUsageData((prev) => ({
        ...prev,
        [field]: value,
      }));
    };
  const tokensPerActiveUserPerMonth = 250000;
  const tokensPerSUI = 5000;
  const tokensPerAPI = 100;
  const tooltipStyles = {
    backgroundColor: "#4b5563",
    color: "#ffffff",
    padding: "8px 12px",
    borderRadius: "4px",
    fontSize: "12px",
    maxWidth: "200px",
    textAlign: "left" as const,
  };
  const renderSlider = (
    field: keyof UsageCalculatorData,
    label: string,
    min: number,
    max: number,
    step: number,
    tooltipContent: string
  ) => (
    <div className="mb-2">
      <div className="flex items-center justify-between">
        <label className="block text-sm strong font-medium mr-2">{label}</label>
        <QuestionMarkCircleIcon
          className="h-5 w-5 text-indigo-500 cursor-help mr-28"
          data-tooltip-id={`tooltip-${field}`}
          data-tooltip-content={tooltipContent}
        />
        <Tooltip
          id={`tooltip-${field}`}
          style={tooltipStyles}
          className="custom-tooltip"
          place="right"
          delayShow={300}
        />
      </div>
      <div className="flex items-center">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={Math.min(Math.max(usageData[field], min), max)}
          onChange={handleSliderChange(field)}
          className="w-full mr-4 accent-indigo-600"
        />
        <input
          type="number"
          value={usageData[field]}
          onChange={handleInputChange(field)}
          className="text-sm font-medium text-gray-700 w-24 text-center border border-gray-300 rounded-md p-1"
        />
      </div>
    </div>
  );
  const renderTokenGrid = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-6">
      {[
        {
          name: "API Request",
          tokens: usageData.tokensPerAPIRequest,
          hint: "Typical requests to our REST API that do not diriectly utlize AI. This includes things like food search, bar code scan, retreiving food nutrient details, etc.",
        },
        {
          name: "Text/Chat AI Request",
          tokens: usageData.tokensPerTextAIRequest,
          hint: "AI requests that are based on text input and provide contextual suggestions, or conversation with the AI Nutrition Advisor. This includes invisible ingredients, visually similar alternative options, ingredient builder, etc.",
        },
        {
          name: "Vision AI Request",
          tokens: usageData.tokensPerVisionAIRequest,
          hint: "AI requests that are based on image input and provide contextual suggestions. This includes image processing, and other advanced AI models.",
        },
      ].map((action, ind) => (
        <div
          key={action.name}
          className=" h-28 p-4 bg-white shadow-sm border border-gray-200"
        >
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="flex justify-between w-full">
              <div className="self-stretch text-gray-900 text-base font-normal leading-normal">
                {action.name}
              </div>
              <QuestionMarkCircleIcon
                className="h-5 w-5 text-indigo-500 cursor-help ml-2 "
                data-tooltip-id={`tooltip-action-${action.name}`}
                data-tooltip-content={`${action.hint}`}
              />
              <Tooltip
                id={`tooltip-action-${action.name}`}
                style={tooltipStyles}
                className="custom-tooltip"
                place="right"
                delayShow={300}
              />
            </div>
            <div className="w-56 flex justify-start items-baseline gap-2">
              <div className="text-indigo-600 text-lg font-semibold leading-tight">
                {ind === 0 ? "" : "~"}
                {convertToNumLetter(action.tokens)}
              </div>
              <div className="text-gray-500 text-sm font-medium leading-tight">
                tokens each
              </div>
            </div>
            <div className="w-56 flex justify-start items-baseline gap-2">
              <div className="text-indigo-600 text-lg font-semibold leading-tight">
                {ind === 0 ? "" : "~"}
                {convertToNumLetter(1000000 / action.tokens)}
              </div>
              <div className="text-gray-500 text-sm font-medium leading-tight">
                uses per million
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <Card
        className="bg-white shadow-md rounded-lg p-6 mb-8 overflow-auto overflow-y-visible"
        borderRequired
      >
        <div className="flex justify-between items-center">
          <div className="w-24"></div> {/* Spacer for balance */}
          <div className="flex flex-col items-center flex-grow">
            <div className="text-center text-gray-900 text-2xl font-semibold font-inter leading-snug">
              Cost Breakdown
            </div>

            <div className="w-full text-center text-gray-500 text-lg font-normal font-inter ">
              A simpler way to visualize your usage needs and estimated cost
            </div>
          </div>
          <button
            onClick={toggleSimpleExpand}
            className="text-sm font-extrabold text-indigo-600 hover:text-indigo-800 w-24 text-right"
          >
            {simpleIsExpanded ? "Hide" : "Show"}
          </button>
        </div>

        {simpleIsExpanded && (
          <div className="mt-6">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border p-2 text-left">Tier</th>
                  <th className="border p-2 text-center">Tokens Included</th>
                  <th className="border p-2 text-center">Price</th>
                  <th className="border p-2 text-center">
                    <div className="flex flex-grow justify-center items-center">
                      <span>API Calls</span>
                      <span>
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-indigo-500 cursor-help ml-4"
                          data-tooltip-id={`tooltip-basic-api`}
                          data-tooltip-content={`An API call is for instance, retreiving food information from the database, or gathering metadata for the mobile sdk, etc. 100 tokens each.`}
                        />
                        <Tooltip
                          id={`tooltip-basic-api`}
                          style={tooltipStyles}
                          className="custom-tooltip"
                          place="right"
                          delayShow={300}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="border p-2 text-center">
                    <div className="flex flex-grow justify-center items-center">
                      <span>Standard User Interactions (SUI)</span>
                      <span>
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-indigo-500 cursor-help ml-4"
                          data-tooltip-id={`tooltip-basic-sui`}
                          data-tooltip-content={`This is the average number of tokens for a completed user interaction. An SUI can be: Logging a full meal via search API, logging a full meal with AI Vision tools, a chat session with the Nutrition Advisor (~4 messages long) etc. Average ~5,000 tokens.`}
                        />
                        <Tooltip
                          id={`tooltip-basic-sui`}
                          style={tooltipStyles}
                          className="custom-tooltip"
                          place="right"
                          delayShow={300}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="border p-2 text-center">
                    <div className="flex flex-grow justify-center items-center">
                      <span>Estimated Active Users</span>
                      <span>
                        <QuestionMarkCircleIcon
                          className="h-5 w-5 text-indigo-500 cursor-help ml-4"
                          data-tooltip-id={`tooltip-basic-mau`}
                          data-tooltip-content={`This is the estimated number of users your tier can support with no extra charge. If auto-refill is enabled, you can support any number of users, and will be billed for additional tokens as required. Estimates are done assuming as an average, a user will complete about 1-3 SUIs every day per month. The estimated cost per user is done at 50 SUIs (or ~250,000 tokens) per user per month.`}
                        />
                        <Tooltip
                          id={`tooltip-basic-mau`}
                          style={tooltipStyles}
                          className="custom-tooltip"
                          place="right"
                          delayShow={300}
                        />
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    name: "Starter",
                    tokens: 10,
                    price: 25,
                    costPerMillion: 2.5,
                  },
                  {
                    name: "Growth",
                    tokens: 150,
                    price: 300,
                    costPerMillion: 2.0,
                  },
                  {
                    name: "Pro",
                    tokens: 2000,
                    price: 3000,
                    costPerMillion: 1.5,
                  },
                ].map((tier) => {
                  const apiCallsPerMillion = 1000000 / tokensPerAPI;
                  const suiPerMillion = 1000000 / tokensPerSUI;
                  const estimatedUsers = Math.floor(
                    (tier.tokens * 1000000) / tokensPerActiveUserPerMonth
                  );

                  return (
                    <tr key={tier.name} className="border-b">
                      <td className="border p-2 text-lg font-bold">
                        {tier.name}
                      </td>
                      <td className="border p-2 text-center">
                        {tier.tokens}M
                        <br />
                        <span className="text-sm text-gray-600">
                          {`( + $2.50 per million when exceeded)`}
                        </span>
                      </td>
                      <td className="border p-2 text-center">
                        ${tier.price}
                        <br />
                        <span className="text-sm text-gray-600">
                          {`$${tier.costPerMillion.toFixed(2)} per million`}
                        </span>
                      </td>
                      <td className="border p-2 text-center">
                        {(tier.tokens * apiCallsPerMillion).toLocaleString()}{" "}
                        calls
                        <br />
                        <span className="text-sm text-gray-600">
                          ($
                          {(tier.costPerMillion / apiCallsPerMillion).toFixed(
                            4
                          )}
                          {" / "}
                          {tokensPerAPI.toLocaleString()}
                          {" tokens "}
                          per call)
                        </span>
                      </td>
                      <td className="border p-2 text-center">
                        {(tier.tokens * suiPerMillion).toLocaleString()} SUIs
                        <br />
                        <span className="text-sm text-gray-600">
                          (${(tier.costPerMillion / suiPerMillion).toFixed(4)}{" "}
                          {" / "}
                          {tokensPerSUI.toLocaleString()}
                          {" tokens "}
                          per SUI)
                        </span>
                      </td>
                      <td className="border p-2 text-center">
                        {`${(estimatedUsers * 0.75).toLocaleString()} - ${(
                          estimatedUsers * 1.25
                        ).toLocaleString()}`}{" "}
                        users
                        <br />
                        <span className="text-sm text-gray-600">
                          (~ ${(tier.price / estimatedUsers).toFixed(2)}
                          {" / "}
                          {tokensPerActiveUserPerMonth.toLocaleString()}
                          {" tokens "}
                          per user)
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Card>
      <Card
        className="bg-white shadow-md rounded-lg p-6 mb-8 overflow-auto overflow-y-visible"
        borderRequired
      >
        <div className="flex justify-between items-center">
          <div className="w-24"></div> {/* Spacer for balance */}
          <div className="flex flex-col items-center flex-grow">
            <div className="text-center text-gray-900 text-2xl font-semibold font-inter leading-snug">
              Calculate Usage & Pricing
            </div>

            <div className="w-full text-center text-gray-500 text-lg font-normal font-inter ">
              Adjust the sliders to fit your use case to estimate your pricing
              and suggested product tier.
            </div>
          </div>
          <button
            onClick={toggleExpand}
            className="text-sm font-extrabold text-indigo-600 hover:text-indigo-800 w-24 text-right"
          >
            {isExpanded ? "Hide" : "Show"}
          </button>
        </div>

        {isExpanded && (
          <div className="grid grid-cols-12">
            <div className="col-span-8 row-span-2 pr-2 flex flex-col">
              <div className="pb-2 text-gray-900 text-lg font-medium font-inter leading-normal">
                Calculator Controls
              </div>
              <Card borderRequired className="flex-grow">
                {renderSlider(
                  "numUsers",
                  "Number of Users",
                  0,
                  10000,
                  50,
                  "Roughly how many monthly users your application has, or is anticipated to have"
                )}
                {renderSlider(
                  "avgUserMonthlyAPIRequests",
                  "Avg Monthly API Requests per User",
                  0,
                  10000,
                  50,
                  "The average number of API requests for one user in a month. This depends heavily on what features you enable, but as an estimate we can say 60 API calls to log one day of meals"
                )}
                {renderSlider(
                  "avgUserMonthlyVisionAIRequests",
                  "Avg Monthly Vision AI Requests per User",
                  0,
                  100,
                  1,
                  "The average number of Vision AI requests for one user in a month. One image to log a meal. Given the many ways to log a meal, the average per user tends to be lower than other types. Image processing uses a more advanced AI model, and therefore costs more tokens.\raa"
                )}
                {renderSlider(
                  "avgUserMonthlyTextAIRequests",
                  "Avg Monthly Text AI Requests per User",
                  0,
                  1000,
                  10,
                  "The average number of Text AI requests for one user in a month. Any requests that run off text-input and provide contextual suggestions. Invisible ingredients, visually similar alternative options, ingredient builder, etc. These tend to support existing features and customize suggestions for users."
                )}
                {renderSlider(
                  "avgUserMonthlyChatMessageRequests",
                  "Avg Monthly Chat Message Requests per User",
                  0,
                  100,
                  1,
                  "Chat requests are conversations with the nutrition advisor under the conversation threads. The average expects some users to use this more than others."
                )}
              </Card>
            </div>
            <div className="col-span-4 p-2 h-full flex flex-col">
              <div className="text-gray-900 text-lg font-medium font-inter leading-normal">
                Volume Discounts
              </div>
              {priceTable}
            </div>
            <div className="col-span-4 p-2 h-full flex flex-col">
              <div className="self-stretch h-full flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch text-gray-900 text-lg font-medium leading-normal">
                  Estimated Monthly Cost
                </div>
                <div className="self-stretch h-28 bg-white rounded-lg border border-gray-200 flex-col justify-start items-start flex">
                  <div className="self-stretch justify-start items-center inline-flex">
                    <div className="grow shrink basis-0 h-9 px-4 py-2 justify-end items-center flex">
                      <div className="grow shrink basis-0 text-gray-900 text-sm font-medium leading-tight">
                        Recommended Plan:
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-9 px-4 py-2 justify-start items-center flex">
                      <div className="grow shrink basis-0 text-gray-600 leading-tight">
                        {selectedPlan.planTitle}
                      </div>
                      <div className="text-center justify-center text-indigo-600 text-lg font-semibold">{`  ${(
                        predictedCost || 0
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}</div>
                    </div>
                  </div>
                  <div className="self-stretch h-px bg-gray-200" />
                  <div className="self-stretch justify-start items-center inline-flex">
                    <div className="grow shrink basis-0 h-9 px-4 py-2 justify-end items-center flex">
                      <div className="grow shrink basis-0 text-gray-900 text-sm font-medium leading-tight">
                        <div className="flex align-middle content-center">
                          <p className="pt-0.5">Overage:</p>
                          <QuestionMarkCircleIcon
                            className="h-5 w-5 text-indigo-500 cursor-help ml-2 "
                            data-tooltip-id={`tooltip-overage-desc`}
                            data-tooltip-content={`Based on your estimates, this is a projection of how many millions of tokens over your included # of tokens. When auto-refill is enabled, this is the amount of tokens you will be charged for, at a rate of ${CREDIT_REFILL_PRICE_DISPLAY} each million.`}
                          />
                          <Tooltip
                            id={`tooltip-overage-desc`}
                            style={tooltipStyles}
                            className="custom-tooltip"
                            place="right"
                            delayShow={300}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-9 px-4 py-2 justify-start items-center flex">
                      <div className="grow shrink basis-0 text-gray-600 leading-tight">
                        {convertToNumLetter(overageTokens * 1000000)}
                      </div>
                      <div className="text-center justify-center text-indigo-600 text-lg font-semibold">{`+ ${(
                        overageCost || 0
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}</div>
                    </div>
                  </div>
                  <div className="self-stretch h-px bg-gray-200" />
                  <div className="self-stretch justify-start items-center inline-flex">
                    <div className="grow shrink basis-0 h-9 px-4 py-2 justify-end items-center flex">
                      <div className="grow shrink basis-0 text-gray-900 text-sm font-medium leading-tight">
                        Monthly Token Usage:
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-9 px-4 py-2 justify-start items-center flex">
                      <div className="grow shrink basis-0 text-gray-600 leading-tight">
                        <div className="flex">
                          {convertToNumLetter(predictedTokens)}
                        </div>
                      </div>

                      <div className="text-center justify-center text-indigo-600 text-lg font-semibold">{`${(
                        (overageCost || 0) + (predictedCost || 0)
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 row-span-1">
              <div className="text-gray-900 text-lg font-inter font-medium pt-4 leading-normal mt-4">
                Tokens Per Action
              </div>
              {renderTokenGrid()}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default UsageCalculator;
