import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import UserContext from "context/UserContext";

import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import Container from "components/atoms/Container";
import Modal from "components/atoms/Modal";
import NavHeader from "components/organisms/NavHeader";

import { error } from "utils";
import {
  MOBILE_AI_SLUG,
  NUTRITION_ADVISOR_SLUG,
  NUTRITION_AI_SLUG,
  NUTRITION_API_SLUG,
  PASSIO_HUB_SLUG,
  REMODEL_AI_SLUG,
  VR_SHOWROOM_SLUG,
} from "utils/products";
import { CustomerStatusType } from "utils/types";

import MobileAICardImage from "images/mobile-ai.png";
import NutritionAdvisorCardImage from "images/nutrition-advisor.png";
import NutritionAICardImage from "images/nutrition-ai.png";
import NutritionAPICardImage from "images/nutrition-api.png";
import RemodelAICardImage from "images/remodel-ai.png";
import VRShowroomCardImage from "images/vr-showroom.png";

interface MobileDataTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

interface NutritionDataTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

interface NutritionAPIDataTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

interface NutritionAdvisorDataTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

interface RemodelAIDataTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

interface VRShowroomDataTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

interface UnifiedTypes {
  isSubscribed: boolean;
  isValidSubscription: boolean;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const flags = useFlags();

  const [displayIndividualLegacySubs, setDisplayIndividualLegacySubs] =
    useState(false);

  const [unauthorized, setUnauthorized] = useState(false);
  const [, setCustomerStatus] = useState<CustomerStatusType>({
    hasOrg: false,
    hasPaymentMethod: false,
  });
  const [mobileData, setMobileData] = useState<MobileDataTypes>({
    isSubscribed: false,
    isValidSubscription: false,
  });
  const [nutritionData, setNutriritionData] = useState<NutritionDataTypes>({
    isSubscribed: false,
    isValidSubscription: false,
  });
  const [nutritionAPIData, setNutriritionAPIData] =
    useState<NutritionAPIDataTypes>({
      isSubscribed: false,
      isValidSubscription: false,
    });
  const [nutritionAdvisorData, setNutritionAdvisorData] =
    useState<NutritionAdvisorDataTypes>({
      isSubscribed: false,
      isValidSubscription: false,
    });
  const [remodelAIData, setRemodelAIData] = useState<RemodelAIDataTypes>({
    isSubscribed: false,
    isValidSubscription: false,
  });
  const [vrShowroomData, setVRShowroomData] = useState<VRShowroomDataTypes>({
    isSubscribed: false,
    isValidSubscription: false,
  });
  const [unifiedData, setUnifiedData] = useState<UnifiedTypes>({
    isSubscribed: false,
    isValidSubscription: false,
  });

  const invalidMobileSubscription =
    mobileData.isSubscribed && !mobileData.isValidSubscription;
  const invalidNutritionSubscription =
    nutritionData.isSubscribed && !nutritionData.isValidSubscription;
  const invalidNutritionAPISubscription =
    nutritionAPIData.isSubscribed && !nutritionAPIData.isValidSubscription;
  const invalidNutritionAdvisorSubscription =
    nutritionAdvisorData.isSubscribed &&
    !nutritionAdvisorData.isValidSubscription;
  const invalidRemodelAISubscription =
    remodelAIData.isSubscribed && !remodelAIData.isValidSubscription;
  const invalidVRShowroomSubscription =
    vrShowroomData.isSubscribed && !vrShowroomData.isValidSubscription;
  const invalidUnifiedSubscription =
    unifiedData.isSubscribed && !unifiedData.isValidSubscription;

  const [invalidSubscriptionModalIsOpen, setInvalidSubscriptionModalIsOpen] =
    useState(false);

  const userContext = useContext(UserContext);

  const getProducts = async () => await axios.get(`/accounts/products`);

  const { isLoading, isFetching, isPaused } = useQuery(
    ["products"],
    getProducts,
    {
      enabled: !!userContext,
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setCustomerStatus((prev) => ({
          ...prev,
          hasOrg: res?.data.hasOrg,
          hasPaymentMethod: res?.data.hasPaymentMethod,
        }));
        setNutriritionData((prev) => ({
          ...prev,
          isSubscribed: res?.data.nutritionAI.isSubscribed,
          isValidSubscription: res?.data.nutritionAI.isValidSubscription,
        }));
        setNutriritionAPIData((prev) => ({
          ...prev,
          isSubscribed: res?.data.nutritionAPI.isSubscribed,
          isValidSubscription: res?.data.nutritionAPI.isValidSubscription,
        }));
        setNutritionAdvisorData((prev) => ({
          ...prev,
          isSubscribed: res?.data.nutritionAdvisor.isSubscribed,
          isValidSubscription: res?.data.nutritionAdvisor.isValidSubscription,
        }));
        setRemodelAIData((prev) => ({
          ...prev,
          isSubscribed: res?.data.remodelAI.isSubscribed,
          isValidSubscription: res?.data.remodelAI.isValidSubscription,
        }));
        setVRShowroomData((prev) => ({
          ...prev,
          isSubscribed: res?.data.vrShowroom.isSubscribed,
          isValidSubscription: res?.data.vrShowroom.isValidSubscription,
        }));
        setMobileData((prev) => ({
          ...prev,
          isSubscribed: res?.data.mobileAI.isSubscribed,
          isValidSubscription: res?.data.mobileAI.isValidSubscription,
        }));
        const dynamics = res?.data.dynamicVolumeSubscriptions || [];
        // TODO: DVS: Cleanup
        const selectedItem = dynamics.find(
          (item: any) => item?.info?.PackageName === "Nutrition Hub"
        );
        setUnifiedData((prev) => ({
          ...prev,
          isSubscribed: selectedItem?.isSubscribed,
          isValidSubscription: selectedItem?.isValidSubscription,
        }));

        if (
          res?.data?.nutritionAI?.isSubscribed ||
          res?.data?.nutritionAPI?.isSubscribed ||
          res?.data?.nutritionAdvisor?.isSubscribed
        ) {
          setDisplayIndividualLegacySubs(true);
        }
      },
    }
  );
  const isWaiting = isFetching || isLoading || isPaused;

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  return (
    <NavHeader>
      <Container>
        <h2 className="font-bold inter-bold text-4xl mb-1">Our Products</h2>
        <hr className="bg-passio-gray1 mb-8"></hr>
        <div className="grid lg:grid-cols-6 gap-4">
          {flags["nutrition-hub-purchase"] && (
            <Card className="col-span-2">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p
                    className="font-extrabold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                  >
                    Nutrition AI Hub
                  </p>
                  <span className="text-sm text-gray-500 pt-1 pb-2">
                    Credit-based subscription to all nutrition products
                  </span>
                </div>
                <span className="text-passio-indigo text-base text-right">
                  <a
                    href={
                      process.env.REACT_APP_MARKETING_BASE + "/nutrition-ai"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Information
                  </a>
                </span>
              </div>
              <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
                Gain simple, usage based access to our food API, mobile SDK,
                advisor APIs, and more!
              </p>
              <div className="flex place-content-center">
                <img
                  src={NutritionAICardImage}
                  alt="Screenshot of Passio Hub"
                  className="object-cover rounded-md"
                />
              </div>
              <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
              <Button
                text={
                  invalidUnifiedSubscription
                    ? "Susbcription Issue"
                    : unifiedData.isSubscribed
                    ? "Product Details"
                    : "Subscribe"
                }
                outlined
                fullWidth
                isWarning={invalidNutritionSubscription}
                isLoading={isWaiting}
                disabled={isWaiting}
                onClick={() =>
                  invalidUnifiedSubscription
                    ? setInvalidSubscriptionModalIsOpen(true)
                    : unifiedData.isSubscribed
                    ? navigate(`/details?product=${PASSIO_HUB_SLUG}`)
                    : navigate(
                        `/manage-subscription?product=${PASSIO_HUB_SLUG}`
                      )
                }
              />
            </Card>
          )}
          {(displayIndividualLegacySubs ||
            !flags["nutrition-hub-purchase"]) && (
            <Card className="col-span-2">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p
                    className="font-extrabold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                  >
                    Nutrition-AI Mobile SDK
                  </p>
                  <span className="text-sm text-gray-500 pt-1 pb-2">
                    Mobile Application Development SDK
                  </span>
                </div>
                <span className="text-passio-indigo text-base text-right">
                  <a
                    href={
                      process.env.REACT_APP_MARKETING_BASE + "/nutrition-ai"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Information
                  </a>
                </span>
              </div>
              <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
                Quickly create; or supercharge existing, mobile applications
                with computer vision, searchable foods, logging, and more!
              </p>
              <div className="flex place-content-center">
                <img
                  src={NutritionAICardImage}
                  alt="Screenshot of Nutrition AI"
                  className="object-cover rounded-md"
                />
              </div>
              <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
              <Button
                text={
                  invalidNutritionSubscription
                    ? "Susbcription Issue"
                    : nutritionData.isSubscribed
                    ? "Product Details"
                    : "Subscribe"
                }
                outlined
                fullWidth
                isWarning={invalidNutritionSubscription}
                isLoading={isWaiting}
                disabled={isWaiting}
                onClick={() =>
                  invalidNutritionSubscription
                    ? setInvalidSubscriptionModalIsOpen(true)
                    : nutritionData.isSubscribed
                    ? navigate(`/details?product=${NUTRITION_AI_SLUG}`)
                    : navigate(
                        `/manage-subscription?product=${NUTRITION_AI_SLUG}`
                      )
                }
              />
            </Card>
          )}
          {(displayIndividualLegacySubs ||
            !flags["nutrition-hub-purchase"]) && (
            <Card className="col-span-2">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p
                    className="font-extrabold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                  >
                    Food Database API
                  </p>
                  <span className="text-sm text-gray-500 pt-1 pb-2">
                    (RESTful) API with over 2.3 million possibilities
                  </span>
                </div>
                <span className="text-passio-indigo text-base text-right">
                  <a
                    href={
                      process.env.REACT_APP_MARKETING_BASE +
                      "/nutrition-ai#nutrition-api-pricing"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Information
                  </a>
                </span>
              </div>
              <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
                Search and retrieve detailed nutritional information across a
                vast selection of foods, recipes, brands, supplements, and more!
              </p>
              <div className="flex place-content-center">
                <img
                  src={NutritionAPICardImage}
                  alt="Screenshot of Nutrition API"
                  className="object-cover rounded-md"
                />
              </div>
              <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
              <Button
                text={
                  invalidNutritionAPISubscription
                    ? "Susbcription Issue"
                    : nutritionAPIData.isSubscribed
                    ? "Product Details"
                    : "Subscribe"
                }
                outlined
                fullWidth
                isWarning={invalidNutritionAPISubscription}
                isLoading={isWaiting}
                disabled={isWaiting}
                onClick={() => {
                  invalidNutritionAPISubscription
                    ? setInvalidSubscriptionModalIsOpen(true)
                    : nutritionAPIData.isSubscribed
                    ? navigate(`/details?product=${NUTRITION_API_SLUG}`)
                    : navigate(
                        `/manage-subscription?product=${NUTRITION_API_SLUG}`
                      );
                }}
              />
            </Card>
          )}
          {(displayIndividualLegacySubs ||
            !flags["nutrition-hub-purchase"]) && (
            <Card className="col-span-2">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p
                    className="font-extrabold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                  >
                    Nutrition Advisor AI
                  </p>
                  <span className="text-sm text-gray-500 pt-1 pb-2">
                    (RESTful) Conversational Nutrition Advisor
                  </span>
                </div>
                <span className="text-passio-indigo text-base text-right">
                  <a
                    href={
                      process.env.REACT_APP_MARKETING_BASE +
                      "/nutrition-ai#nutrition-advisor-pricing"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Information
                  </a>
                </span>
              </div>
              <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
                Get personalized eating advice, explore tailored recipes, and
                effortlessly connect to our extensive food database.
              </p>
              <div className="flex place-content-center">
                <div
                  style={{
                    position: "relative",
                    width: "100%", // Makes the container responsive
                    height: 0, // Collapse the div and let padding-top control the height
                    paddingTop: `${(652 / 1158) * 100}%`, // Height / Width * 100
                  }}
                  className="rounded-md overflow-hidden"
                >
                  <img
                    src={NutritionAdvisorCardImage}
                    alt="Screenshot of Nutrition Advisor"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
              <Button
                text={
                  invalidNutritionAdvisorSubscription
                    ? "Susbcription Issue"
                    : nutritionAdvisorData.isSubscribed
                    ? "Product Details"
                    : "Subscribe"
                }
                outlined
                fullWidth
                isWarning={invalidNutritionAdvisorSubscription}
                isLoading={isWaiting}
                disabled={isWaiting}
                onClick={() => {
                  invalidNutritionAdvisorSubscription
                    ? setInvalidSubscriptionModalIsOpen(true)
                    : nutritionAdvisorData.isSubscribed
                    ? navigate(`/details?product=${NUTRITION_ADVISOR_SLUG}`)
                    : navigate(
                        `/manage-subscription?product=${NUTRITION_ADVISOR_SLUG}`
                      );
                }}
              />
            </Card>
          )}
          <Card className="col-span-2">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <p
                  className="font-extrabold"
                  style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                >
                  Mobile AI Platform
                </p>
                <span className="text-sm text-gray-500 pt-1 pb-2">
                  Browser-based collaborative AI platform
                </span>
              </div>

              <span className="text-passio-indigo text-base text-right">
                <a
                  href={process.env.REACT_APP_MARKETING_BASE + "/mobile-ai"}
                  target="_blank"
                  rel="noreferrer"
                >
                  More Information
                </a>
              </span>
            </div>

            <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
              Create custom computer-vision SDKs in your browser— from quick,
              no-training solutions to advanced, collaborative projects!
            </p>
            <div className="flex place-content-center">
              <img
                src={MobileAICardImage}
                alt="Screenshot of Mobile AI"
                className="object-cover rounded-md"
              />
            </div>
            <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
            <Button
              text={
                invalidMobileSubscription
                  ? "Susbcription Issue"
                  : mobileData.isSubscribed
                  ? "Product Details"
                  : "Subscribe"
              }
              outlined
              fullWidth
              isWarning={invalidMobileSubscription}
              isLoading={isWaiting}
              disabled={isWaiting}
              onClick={() => {
                invalidMobileSubscription
                  ? setInvalidSubscriptionModalIsOpen(true)
                  : mobileData.isSubscribed
                  ? navigate(`/details?product=${MOBILE_AI_SLUG}`)
                  : navigate(`/manage-subscription?product=${MOBILE_AI_SLUG}`);
              }}
            />
          </Card>
          {flags["remodel-ai-purchase"] && (
            <Card className="col-span-2">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p
                    className="font-extrabold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                  >
                    Remodel AI
                  </p>
                  <span className="text-sm text-gray-500 pt-1 pb-2">
                    3D home scanning and AR paint preview SDK
                  </span>
                </div>
                <span className="text-passio-indigo text-base text-right">
                  <a
                    href={process.env.REACT_APP_MARKETING_BASE + "/remodel-ai"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Information
                  </a>
                </span>
              </div>
              <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
                Unlock the power of augmented reality—scan, design, and enhance
                your space with our comprehensive AR toolkit!
              </p>
              <div className="flex place-content-center">
                <img
                  src={RemodelAICardImage}
                  alt="Screenshot of Remodel AI"
                  className="object-cover rounded-md"
                />
              </div>
              <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
              <Button
                text={
                  invalidRemodelAISubscription
                    ? "Susbcription Issue"
                    : remodelAIData.isSubscribed
                    ? "Product Details"
                    : "Subscribe"
                }
                outlined
                fullWidth
                isWarning={invalidRemodelAISubscription}
                isLoading={isWaiting}
                disabled={isWaiting}
                onClick={() => {
                  invalidRemodelAISubscription
                    ? setInvalidSubscriptionModalIsOpen(true)
                    : remodelAIData.isSubscribed
                    ? navigate(`/details?product=${REMODEL_AI_SLUG}`)
                    : navigate(
                        `/manage-subscription?product=${REMODEL_AI_SLUG}`
                      );
                }}
              />
            </Card>
          )}
          {flags["vr-showroom-purchase"] && (
            <Card className="col-span-2">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p
                    className="font-extrabold"
                    style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.025)" }}
                  >
                    VR Showroom
                  </p>
                  <span className="text-sm text-gray-500 pt-1 pb-2">
                    VR SDK for product showcase
                  </span>
                </div>
                <span className="text-passio-indigo text-base text-right">
                  <a
                    href={process.env.REACT_APP_MARKETING_BASE + "/vr-showroom"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    More Information
                  </a>
                </span>
              </div>
              <p className="mt-1 mb-6 text-sm leading-6 text-gray-900 sm:mt-2">
                Transform your product showcase with our SDK—create immersive VR
                showrooms in a virtual demo space!
              </p>
              <div className="flex place-content-center">
                <img
                  src={VRShowroomCardImage}
                  alt="Screenshot of VR Showroom"
                  className="object-cover rounded-md"
                />
              </div>
              <div className="grid sm:grid-cols-3 lg:grid-cols-1 xl:grid-cols-3 gap-4 my-4 sm:flex-1 lg:flex-initial xl:flex-1"></div>
              <Button
                text={
                  invalidVRShowroomSubscription
                    ? "Susbcription Issue"
                    : vrShowroomData.isSubscribed
                    ? "Product Details"
                    : "Subscribe"
                }
                outlined
                fullWidth
                isWarning={invalidVRShowroomSubscription}
                isLoading={isWaiting}
                disabled={isWaiting}
                onClick={() => {
                  invalidVRShowroomSubscription
                    ? setInvalidSubscriptionModalIsOpen(true)
                    : vrShowroomData.isSubscribed
                    ? navigate(`/details?product=${VR_SHOWROOM_SLUG}`)
                    : navigate(
                        `/manage-subscription?product=${VR_SHOWROOM_SLUG}`
                      );
                }}
              />
            </Card>
          )}
        </div>
        <Modal
          isOpen={invalidSubscriptionModalIsOpen}
          setOpen={(e) => setInvalidSubscriptionModalIsOpen(e)}
          title={"Invalid Subscription Status"}
          disabled={isWaiting}
          isLoading={isWaiting}
          isCancelButton={false}
          description={`Please contact support@passiolife.com to resolve this issue.`}
          submitButtonName={"Contact Passio Support"}
          buttonType={"warning"}
          type={"error"}
          onSubmitted={() =>
            window.open("mailto:support@passiolife.com", "_blank")
          }
        />
      </Container>
    </NavHeader>
  );
};

export default Dashboard;
