import Button from "components/atoms/Button";
import Container from "components/atoms/Container";

interface MessageBlockProps {
  errorCode?: string;
  headline: string;
  content: string;
  onClick: () => void;
}

const MessageBlock = ({
  errorCode,
  headline,
  content,
  onClick,
}: MessageBlockProps) => {
  return (
    <Container>
      {errorCode && (
        <div className="self-stretch text-center text-white text-sm font-normal m-2">
          {errorCode}
        </div>
      )}
      <div className="self-stretch text-center text-white text-5xl font-extrabold inter-bold m-2">
        {headline}
      </div>
      <div className="self-stretch text-center text-white text-base font-normal m-2">
        {content}
      </div>
      <div className="self-stretch text-center text-white text-base font-normal m-12">
        <div className="Text text-indigo-600 text-base font-medium">
          <Button
            onClick={onClick}
            className="text-sm font-semibold leading-7 text-indigo-600"
            outlined
          >
            Go back Home
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default MessageBlock;
