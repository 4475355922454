import Card from "components/atoms/Card";

const PriceTableVRShowroom = () => {
  return (
    <Card borderRequired className="h-full">
      <span className="block font-bold text-sm mt-[-10px]">Flat Rate</span>
      <hr className="bg-passio-gray1 mt-1"></hr>

      <div className="w-full h-full items-center justify-center flex flex-col">
        <div className="flex flex-col items-start gap-5 ml-auto mr-auto pt-5 self-stretch">
          <div className="flex items-end gap-1 self-stretch mb-16">
            <div className="text-gray-900 font-inter text-4xl font-extrabold leading-[60px]">
              $3,000
            </div>
            <div className="text-gray-500 font-inter text-2xl font-medium leading-10">
              /mo
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PriceTableVRShowroom;
