import { Dispatch, SetStateAction } from "react";

export const CREDIT_REFILL_PRICE_DISPLAY = "$2.50";

export type UserContextType = {
  email: string;
  roles: string[];
  customerid: string;
  isOrgOwner: boolean;
  setUserRefreshToggle: Dispatch<SetStateAction<boolean>>;
  userRefreshToggle: boolean;
};

export interface CustomerStatusType {
  hasOrg: boolean;
  hasPaymentMethod: boolean;
}

export interface TierType {
  cap: number;
  unitPrice: number;
  flatPrice: number;
  batchSize?: number;
}

export interface UsageInfoType {
  users: number | null;
  userQuota: number | null;
  units: number | null;
  unitQuota: number | null;
}

export interface UsageHistoryReportForPeriod {
  startDate: string; // ISO 8601 string format (e.g., "2024-07-04T10:59:00Z")
  endDate: string; // ISO 8601 string format
  invoiceLinks: string[]; // Array of invoice link URLs
  usageCSV: string; // CSV content as a string
}

export interface ProductDataType {
  isSubscribed: boolean;
  trialEnd: Date | null;
  billingDate: Date;
  licenseKey: string | null;
  usageInfo: UsageInfoType | null;
  tiers: TierType[];
}

export interface SubscriptionDetailType {
  licenseKey: string;
  productArtifact: {
    version: number;
    cdnUrl: string;
  };
}

export interface CustomerAccessLevelType {
  customerId: string;
  isOwner: boolean;
}

export interface CustomerUserDataType {
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  picture: string;
  profession: any;
  user_roles: any;
}

export interface CustomerUserDataResponseType {
  entities: CustomerUserDataType[];
  page: number;
  lastPage: number;
  pageSize: number;
}
