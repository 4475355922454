import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Fragment, useContext, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

import SubscriptionModal, { SubscriptionModalType } from "../SubscriptionModal";

import UserContext from "context/UserContext";

import Modal from "components/atoms/Modal";
import Spinner from "components/atoms/Spinner";
import SubscriptionCard from "components/molecules/SubscriptionCard";

import { error, success } from "utils";
import { REMODEL_AI_SLUG, uniqueEventId } from "utils/products";
import { CustomerStatusType, UsageInfoType } from "utils/types";

interface RemodelAITierType {
  isSubscribed: boolean | null;
  planTitle: string | null;
  usageInfo: UsageInfoType | null;
}

const ManageSubscriptionRemodelAI = () => {
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  const [unauthorized, setUnauthorized] = useState(false);

  const [customerStatus, setCustomerStatus] = useState<CustomerStatusType>({
    hasOrg: false,
    hasPaymentMethod: false,
  });
  const [productState, setProductState] = useState<RemodelAITierType>({
    isSubscribed: null,
    planTitle: null,
    usageInfo: null,
  });

  const [subscriptionModal, setSubscriptionModal] =
    useState<SubscriptionModalType>({
      isOpen: false,
      planSlug: null,
      shouldUpdate: false,
    });

  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState(false);

  const setAllData = (data: any) => {
    setCustomerStatus((prev) => ({
      ...prev,
      hasOrg: data.hasOrg,
      hasPaymentMethod: data.hasPaymentMethod,
    }));
    setProductState((prev) => ({
      ...prev,
      isSubscribed: data.isSubscribed,
      planTitle: data.planTitle,
      usageInfo: data.usageInfo,
    }));
  };

  const userContext = useContext(UserContext);

  const getProduct = async () =>
    await axios.get(`/accounts/products/${productQuery}`);

  const { isLoading, isFetching, isPaused, refetch } = useQuery(
    ["product"],
    getProduct,
    {
      enabled: !!productQuery && !!userContext,
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setAllData(res?.data);
      },
    }
  );

  const updateSubscription = useMutation(
    async (planTitle: string) => {
      const data = { planTitle };
      return await axios.put(
        `/accounts/products/remodel-ai/subscription`,
        data
      );
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: (res: AxiosResponse) => {
        success("Subscription updated successfully");
        setAllData(res?.data);
        // google event
        ReactGA.event({
          action: "subscriptionupdated",
          category: `${REMODEL_AI_SLUG}_${productState.planTitle?.toLowerCase()}}`,
          label: uniqueEventId(),
        });
      },
    }
  );

  const unsubscribe = useMutation(
    async () =>
      await axios.delete(`/accounts/products/remodel-ai/subscription`),
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: () => {
        success("Unsubscribed successfully");
        setUnsubscribeModalIsOpen(false);

        // google event
        ReactGA.event({
          action: "subscriptioncancelled",
          category: `${REMODEL_AI_SLUG}_${productState.planTitle?.toLowerCase()}`,
          label: uniqueEventId(),
        });

        // Reset the product state
        setProductState((prev) => ({
          ...prev,
          isSubscribed: false,
          planTitle: null,
          usageInfo: null,
        }));
      },
    }
  );

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  function closeSubscriptionModal() {
    // Refetch the data when we close the modal, because it may have been changed
    refetch();
    setSubscriptionModal({
      isOpen: false,
      planSlug: null,
      shouldUpdate: false,
    });
  }

  const buttonTextForPlan = (
    plan: string,
    currentPlan: string,
    isSubscribed: boolean
  ): string => {
    switch (plan) {
      case "low": {
        if (!isSubscribed) {
          return "Subscribe";
        }
        switch (currentPlan) {
          case "mid":
            return "Downgrade to Starter";
          case "high":
            return "Downgrade to Starter";
        }
        break;
      }
      case "mid": {
        if (!isSubscribed) {
          return "Subscribe";
        }
        switch (currentPlan) {
          case "low":
            return "Upgrade to Growth";
          case "high":
            return "Downgrade to Growth";
        }
        break;
      }
    }
    return "";
  };

  const buttonOnClickForPlan = (plan: string, isSubscribed: boolean) => {
    // If we're not subscribed, we need to open the subscription modal.
    if (!isSubscribed) {
      return () =>
        setSubscriptionModal({
          isOpen: true,
          planSlug: `${REMODEL_AI_SLUG}-${plan}`,
          shouldUpdate: false,
        });
    }

    // If we're already subscribed, just update the subscription.
    return () => updateSubscription.mutate(plan);
  };

  return (
    <Fragment>
      <div>
        <div className="flex justify-between items-center mb-6">
          <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
            Remodel AI Subscription
          </h2>
        </div>
        {isFetching || isLoading || isPaused || updateSubscription.isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-wrap place-content-evenly h-full">
              <>
                <SubscriptionCard
                  name="Starter"
                  price={
                    <>
                      <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
                        $500
                      </div>
                      <div className="text-gray-500 font-inter text-2xl font-medium leading-8">
                        /mo
                      </div>
                    </>
                  }
                  counterInput={<></>}
                  description="Tailored to new or small-scale applications"
                  bullets={[
                    "500 MAU's per month included",
                    "$0.50 per additional MAU per month",
                    "Detailed documentation",
                  ]}
                  isCurrent={productState.planTitle === "low"}
                  buttonText={buttonTextForPlan(
                    "low",
                    productState?.planTitle || "",
                    productState?.isSubscribed || false
                  )}
                  buttonOnClick={buttonOnClickForPlan(
                    "low",
                    productState?.isSubscribed || false
                  )}
                  hideButton={productState.planTitle === "mid"}
                  isCurrentButtonText="Product Details"
                  isCurrentButtonOnClick={() =>
                    navigate(`/details?product=${productQuery}`)
                  }
                  disabled={
                    isLoading ||
                    isFetching ||
                    isPaused ||
                    updateSubscription.isLoading ||
                    !userContext?.isOrgOwner
                  }
                  wide={false}
                  showCancelButton={productState.planTitle === "low"}
                  cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
                />
                <SubscriptionCard
                  name="Growth"
                  description="Tailored existing applications with a growing user base"
                  price={
                    <>
                      <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
                        $5,000
                      </div>
                      <div className="text-gray-500 font-inter text-2xl font-medium leading-8">
                        /mo
                      </div>
                    </>
                  }
                  bullets={[
                    "10,000 MAU's per month included",
                    "$0.25 per additional MAU per month",
                    "Detailed documentation",
                  ]}
                  isCurrent={productState.planTitle === "mid"}
                  buttonText={buttonTextForPlan(
                    "mid",
                    productState?.planTitle || "",
                    productState?.isSubscribed || false
                  )}
                  // Plan titles are lowcase
                  buttonOnClick={buttonOnClickForPlan(
                    "mid",
                    productState?.isSubscribed || false
                  )}
                  hideButton={productState.planTitle === "high"}
                  isCurrentButtonText="Product Details"
                  isCurrentButtonOnClick={() =>
                    navigate(`/details?product=${productQuery}`)
                  }
                  disabled={
                    isLoading ||
                    isFetching ||
                    isPaused ||
                    updateSubscription.isLoading ||
                    !userContext?.isOrgOwner
                  }
                  wide={false}
                  counterInput={<></>}
                  showCancelButton={productState.planTitle === "mid"}
                  cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
                />
                <SubscriptionCard
                  name="Enterprise"
                  description="Contact us with your specific use cases."
                  price={
                    <>
                      <div className="text-gray-900 font-inter text-2xl font-bold leading-[60px]">
                        Talk with sales for pricing
                      </div>
                    </>
                  }
                  counterInput={<></>}
                  bullets={[
                    "Live technical assistance",
                    "Prioritized feature requests",
                    "Lets chat so we can meet your needs!",
                  ]}
                  isCurrent={false}
                  buttonText="Talk with sales"
                  buttonOnClick={() =>
                    window.open(
                      `${process.env.REACT_APP_MARKETING_BASE}/contact`,
                      "_blank"
                    )
                  }
                  isCurrentButtonText="Product Details"
                  isCurrentButtonOnClick={() =>
                    navigate(`/details?product=${productQuery}`)
                  }
                  disabled={
                    isLoading ||
                    isFetching ||
                    isPaused ||
                    updateSubscription.isLoading ||
                    !userContext?.isOrgOwner
                  }
                  wide={false}
                  showCancelButton={false}
                  cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
                />
              </>
            </div>
          </>
        )}
      </div>
      {subscriptionModal.isOpen && subscriptionModal.planSlug && (
        <SubscriptionModal
          isOpen={subscriptionModal.isOpen}
          closeModal={closeSubscriptionModal}
          closeBtn
          planSlug={subscriptionModal.planSlug}
          shouldCreateOrg={!customerStatus.hasOrg}
          shouldUpdate={subscriptionModal.shouldUpdate}
          shouldCapturePayment={!customerStatus.hasPaymentMethod}
        />
      )}
      <Modal
        isOpen={unsubscribeModalIsOpen}
        setOpen={(e) => setUnsubscribeModalIsOpen(e)}
        title={"Unsubscribe from Remodel AI"}
        disabled={unsubscribe.isLoading}
        isLoading={unsubscribe.isLoading}
        description={`Are you sure you want to unsubscribe? Your subscription will be cancelled immediately. This action cannot be undone.`}
        submitButtonName={"Unsubscribe"}
        buttonType={"warning"}
        type={"error"}
        onSubmitted={() => unsubscribe.mutate()}
      />
    </Fragment>
  );
};

export default ManageSubscriptionRemodelAI;
