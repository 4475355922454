import Spinner from "components/atoms/Spinner";

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-screen w-screen z-50 bg-gradient-to-r from-passio-primary to-passio-blue">
      <Spinner />
    </div>
  );
};
export default Loading;
