import { Navigate, useNavigate } from "react-router-dom";

import Breadcrumb from "components/atoms/Breadcrumb";
import Button from "components/atoms/Button";
import Card from "components/atoms/Card";
import Container from "components/atoms/Container";
import DetailsMobileAI from "components/organisms/Details/DetailsMobileAI";
import DetailsNutritionAI from "components/organisms/Details/DetailsNutritionAI";
import DetailsNutritionAPI from "components/organisms/Details/DetailsNutritionAPI";
import DetailsNutritionAdvisor from "components/organisms/Details/DetailsNutritionAdvisor";
import DetailsPassioHub from "components/organisms/Details/DetailsPassioHub";
import DetailsRemodelAI from "components/organisms/Details/DetailsRemodelAI";
import DetailsVRShowroom from "components/organisms/Details/DetailsVRShowroom";
import NavHeader from "components/organisms/NavHeader";

import {
  MOBILE_AI_SLUG,
  NUTRITION_ADVISOR_SLUG,
  NUTRITION_AI_SLUG,
  NUTRITION_API_SLUG,
  PASSIO_HUB_SLUG,
  REMODEL_AI_SLUG,
  VR_SHOWROOM_SLUG,
  isValidProduct,
} from "utils/products";

const Details = () => {
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  if (!isValidProduct(productQuery)) {
    return <Navigate to="/not-found" />;
  }

  const pageList = [
    {
      label: "All Products",
      path: "/",
    },
    {
      label: "Manage Subscription",
      path: `/manage-subscription?product=${productQuery}`,
    },
    {
      label: "Product Details",
    },
  ];

  if (!productQuery) {
    return <Navigate to="/" />;
  }

  return (
    <NavHeader>
      <Container>
        <Breadcrumb pageList={pageList} />
        {productQuery === NUTRITION_AI_SLUG && <DetailsNutritionAI />}
        {productQuery === NUTRITION_API_SLUG && <DetailsNutritionAPI />}
        {productQuery === NUTRITION_ADVISOR_SLUG && <DetailsNutritionAdvisor />}
        {productQuery === REMODEL_AI_SLUG && <DetailsRemodelAI />}
        {productQuery === VR_SHOWROOM_SLUG && <DetailsVRShowroom />}
        {productQuery === MOBILE_AI_SLUG && <DetailsMobileAI />}
        {productQuery === PASSIO_HUB_SLUG && <DetailsPassioHub />}
        <Card className="p-4 sm:p-6 mt-8">
          <div className="flex items-center">
            <Button
              text="Manage Your Subscription"
              type="submit"
              className="h-10"
              onClick={() => {
                navigate(`/manage-subscription?product=${productQuery}`);
              }}
            />
          </div>
        </Card>
      </Container>
    </NavHeader>
  );
};

export default Details;
