import {
  ArrowTopRightOnSquareIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import {
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router-dom";

import UserContext from "context/UserContext";

import Badge from "components/atoms/Badge";
import Card from "components/atoms/Card";
import PriceTableVRShowroom from "components/molecules/PriceTables/PriceTableVRShowroom";
import SlackSignUp from "components/molecules/SlackSignUp";

import { error } from "utils";
import { ProductDataType } from "utils/types";

const DetailsVRShowroom = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.toLocaleString("default", { month: "long" });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");

  const [unauthorized, setUnauthorized] = useState(false);

  const [isCopied, setIsCopied] = useState(false);
  const [productState, setProductState] = useState<ProductDataType>({
    isSubscribed: false,
    trialEnd: null,
    billingDate: new Date(),
    licenseKey: null,
    usageInfo: null,
    tiers: [],
  });

  const userContext = useContext(UserContext);

  const getProduct = async () =>
    await axios.get(`/accounts/products/${productQuery}`);

  const { isLoading, isFetching, isPaused } = useQuery(
    ["product"],
    getProduct,
    {
      enabled: !!productQuery && !!userContext,
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setProductState((prev) => ({
          ...prev,
          isSubscribed: res.data?.isSubscribed,
          trialEnd: res.data?.trialEnd ? new Date(res.data?.trialEnd) : null,
          billingDate: new Date(res.data?.billingDate),
          licenseKey: res.data?.licenseKey,
          usageInfo: res.data?.usageInfo,
          tiers: res.data?.tiers || [],
        }));
      },
    }
  );

  async function copyTextToClipboard(text: string) {
    return await navigator.clipboard.writeText(text);
  }

  const handleCopyClick = () => {
    copyTextToClipboard(productState?.licenseKey ?? "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  // Google Adds Event Tracking
  useEffect(() => {
    // Function to get URL parameter
    const getQueryParam = (param: string) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };
    const encodedData = getQueryParam("event");
    if (encodedData) {
      const decodedString = atob(encodedData);
      try {
        const obj = JSON.parse(decodedString);
        ReactGA.event(obj);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, []);

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  if (!isFetching && !isLoading && !isPaused && !productState.isSubscribed) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
          VR Showroom Product Details
        </h2>
      </div>
      <div className="grid lg:grid-cols-2 lg:auto-rows-fr gap-8">
        <Card className="sm:p-6">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <p className="font-medium">Billing Info</p>
              <span className="text-sm text-gray-500">
                in {currentMonth} {currentYear}
              </span>
            </div>
            {!isFetching && !isLoading ? (
              productState.trialEnd ? (
                <Badge isWarning>
                  <ClockIcon
                    className="h-5 w-5 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Free Trial Ends on{" "}
                  {productState.trialEnd.toLocaleDateString()}
                </Badge>
              ) : (
                <Badge>
                  <ClockIcon
                    className="h-5 w-5 flex-shrink-0 mr-2"
                    aria-hidden="true"
                  />
                  Billed Next on {productState.billingDate.toLocaleDateString()}
                </Badge>
              )
            ) : (
              <></>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4 h-full">
            <div className="col-span-2">
              <PriceTableVRShowroom />
            </div>
          </div>
        </Card>
        <Card className="sm:p-6">
          <div className="flex flex-col">
            <p className="font-medium">Assets</p>
            <span className="text-sm text-gray-500">Links and license key</span>
          </div>

          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">
              VR Showroom Links
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <ArrowTopRightOnSquareIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 text-gray-600 line-through truncate">
                      {/* <a
                        href={"#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      > */}
                      VR Showroom Documentation
                    </span>
                    <span className="text-passio-gray2 text-sm pl-12">
                      Coming Soon!
                    </span>
                    {/* </a> */}
                  </div>
                </li>
              </ul>
            </dd>
          </div>

          <div className="mt-auto">
            <dt className="text-sm font-medium text-gray-500">License Key</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <KeyIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 w-0 flex-1 truncate">
                      {productState?.licenseKey ?? ""}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    {isCopied ? (
                      <ClipboardDocumentCheckIcon
                        className="h-5 w-5 flex-shrink-0 text-green-700"
                        aria-hidden="true"
                      />
                    ) : (
                      <DocumentDuplicateIcon
                        className="h-5 w-5 flex-shrink-0 text-passio-indigo cursor-pointer"
                        aria-hidden="true"
                        onClick={handleCopyClick}
                      />
                    )}
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </Card>
        <Card className="sm:p-6">
          <div>
            <p className="font-medium">Support & Documentation</p>
            <span className="text-sm text-gray-500">
              Links for additional support
            </span>
          </div>

          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Documentation</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio.gitbook.io/vr-showroom"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio.gitbook.io/vr-showroom
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Email Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="mailto:support@passiolife.com"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                support@passiolife.com
              </a>
            </dd>
          </div>
          <div className="mt-4">
            <dt className="text-sm font-medium text-gray-500">Slack Support</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <a
                href="https://passio-community.slack.com/channels/help"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                https://passio-community.slack.com/channels/help
              </a>
            </dd>
          </div>
        </Card>
        <SlackSignUp />
      </div>
    </>
  );
};

export default DetailsVRShowroom;
