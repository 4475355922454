import Card from "components/atoms/Card";

const PriceTableMobileFree = () => {
  return (
    <Card borderRequired className="h-full">
      <div className="mb-4">
        <span className="block font-bold text-sm">Price</span>
        <span className="text-passio-indigo font-bold text-lg lg:text-xl xl:text-3xl">
          Free
        </span>
      </div>

      <div className="mb-4">
        <span className="block font-bold text-sm mb-1">Users</span>
        <span className="text-passio-indigo text-base lg:text-lg">
          3 Users Total
        </span>
      </div>

      <div className="mb-4">
        <span className="block font-bold text-sm mb-1">Images</span>
        <span className="text-passio-indigo text-base lg:text-lg">
          1,000 Images Total
        </span>
      </div>
    </Card>
  );
};

export default PriceTableMobileFree;
